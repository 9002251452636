import { useSelector } from "react-redux";

import TabForm from "./TabForm";
import {
  getTab2FormData,
  saveTab2FormData,
} from "../../../actions/OpkFormsActions/tab2FormActions";
import { Tab2FormFields } from "../OpkForms/FormSettings";
import { tab2ValidationSchema } from "../../../utils/validationSchemas";

const Tab2Form = () => {
  const loading = useSelector((state) => state.tab2FormReducer.loading);
  const { tab2_objects, unsavedRows } = useSelector(
    (state) => state.tab2FormReducer.Tab2Data,
  );

  return (
    <TabForm
      getFormData={getTab2FormData}
      saveFormData={saveTab2FormData}
      formFields={Tab2FormFields}
      loading={loading}
      formData={tab2_objects}
      unsavedRows={unsavedRows}
      validationSchema={tab2ValidationSchema}
    />
  );
};

export default Tab2Form;
