import { GET_ERRORS, GET_FAQ, GET_FAQ_SET_LOADING } from "actions/types.js";
import { apiClient } from "api/api";

export const getFAQ = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_FAQ_SET_LOADING,
    });

    const response = await apiClient.get(`/instrukcja/faq`);

    dispatch({
      payload: response.data,
      type: GET_FAQ,
    });
  } catch (err) {
    if (err.response.data) {
      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };
      dispatch({
        payload: errors,
        type: GET_ERRORS,
      });
    }
  }
};
