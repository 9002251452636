import { SET_LOADING_REQUEST_STATUS } from "../actions/types";

const initialState = {
  alreadyLoaded: null,
  requestType: null,
  totalSize: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING_REQUEST_STATUS:
      return {
        alreadyLoaded: action.payload.alreadyLoaded,
        requestType: action.payload.requestType,
        totalSize: action.payload.totalSize,
      };
    default:
      return state;
  }
};

export default reducer;
