import { GET_FAQ, GET_FAQ_SET_LOADING } from "actions/types.js";

const initialState = {
  categories: [],
  faq: [],
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FAQ:
      return {
        ...state,
        categories: action.payload.categories,
        faq: action.payload.faq,
        loading: false,
      };
    case GET_FAQ_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default reducer;
