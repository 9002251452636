import { useEffect, useRef, useMemo } from "react";

import { FieldArray, FormikProvider, useFormik } from "formik";
import {
  Button,
  Form,
  Table,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { faEraser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const reduceArray = (array, id, value) => {
  return array.reduce((accumulator, currentValue) => {
    Object.keys(currentValue).forEach((key) => {
      if (!Array.isArray(currentValue[key])) return;

      currentValue[key].forEach((object) => {
        accumulator[object[id]] = object[value];
      });
    });

    return accumulator;
  }, {});
};

const sortObject = (object, array) => {
  if (typeof object !== "object" || object === null) return;
  const sortedObject = {};

  array.forEach((key) => {
    sortedObject[key] = object[key];
  });

  if ("del" in sortedObject) {
    sortedObject.del = false;
  }

  return sortedObject;
};

const GeneratedForm = ({
  dataSubmit: submitData,
  formFields: fieldsData,
  hasIndexes,
  opkData: incomingData,
  unsavedRows = [],
  validationSchema,
  isSendingBlocked = false,
  openModal = () => {},
  closeModal = () => {},
  handleDisabeledSideBarButton = () => {},
}) => {
  const inputRef = useRef(null);

  const initialFieldValues = reduceArray(fieldsData, "fieldId", "dafault");
  const fieldTypes = reduceArray(fieldsData, "fieldId", "fieldType");
  const fieldsOrder = Object.keys(initialFieldValues);
  const sortedData = incomingData?.map((fields) => {
    return sortObject(fields, fieldsOrder);
  });
  const initialValues = {
    data: sortedData?.length ? sortedData : [initialFieldValues],
  };

  const fieldLabelsWithSize = useMemo(() => {
    const tableLabels = fieldsData.flatMap((item) =>
      item.fieldData
        .filter((field) => field.fieldType !== "hidden")
        .map((field) => ({
          label: field.fieldLabel,
          size: item.colWidth,
        })),
    );

    if (hasIndexes) {
      tableLabels.unshift({
        label: "Lp.",
        size: 5,
      });
    }

    return tableLabels;
  }, [fieldsData]);

  const extendData = (data) => {
    return data.map((row) => {
      const copiedRow = { ...row };
      const incomingRow = incomingData.find((incomingRow) => {
        return incomingRow.id === copiedRow.id;
      });

      if (incomingRow) {
        Object.keys(incomingRow).forEach((key) => {
          if (!Object.keys(copiedRow).includes(key)) {
            copiedRow[key] = incomingRow[key];
          }
        });
      }

      return copiedRow;
    });
  };

  const handleSubmit = ({ data }) => {
    const extendedData = extendData(data);
    const filledRows = extendedData.filter((row) => {
      return Object.values(row).some((value) => Boolean(value));
    });

    const rowsToDelete = filledRows.filter((row) => row.del);

    if (rowsToDelete.length !== 0) {
      openModal({
        title: "Czy na pewno chcesz usunąć następujące OPK?",
        itemsList: formik.values.data
          .filter((row) => row.del)
          .map(
            (row) => `ID: ${row.id} | Numer konta OPK: ${row.nr_konta ?? "-"}`,
          ),
        children: (
          <>
            <Button onClick={closeModal} variant="secondary">
              Anuluj
            </Button>
            <Button onClick={handleConfirmDelete} variant="danger">
              Potwierdź
            </Button>
          </>
        ),
      });
    } else {
      submitData(filledRows);
    }
  };

  const changeNumberOfRows = (push, pop) => {
    const value = parseInt(inputRef.current?.value, 10) || 0;

    if (value > 0) {
      for (let i = 0; i < value; i++) {
        push(initialFieldValues);
      }
    } else if (value < 0) {
      const numberOfSavedRows = sortedData.length;
      const numberOfRows = formik.values.data.length;
      const possibleToRemove = numberOfRows - numberOfSavedRows;
      const numberOfRowsToRemove = Math.min(possibleToRemove, Math.abs(value));
      for (let i = 0; i < numberOfRowsToRemove; i++) {
        pop();
      }
    }
    inputRef.current.value = 0;
  };

  const checkAll = (key) => {
    const values = formik.values.data
      .filter((row) => row["id"] || key !== "del")
      .map((row) => row[key]);

    formik.setValues({
      ...formik.values,
      data: formik.values.data.map((row) => ({
        ...row,
        [key]: (row["id"] || key !== "del") && !values.every((value) => value),
      })),
    });
  };

  const handleCheckIfChecked = (key) => {
    let checkedData = formik.values.data;
    if (key === "del") {
      checkedData = checkedData.filter((row) => row["id"]);
    }
    return checkedData.length
      ? checkedData.every((row) => row[key] === true)
      : false;
  };

  const handleDecimalChange = ({ target }) => {
    const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(target.value);
    const newValue = match[1] + match[2];

    setTimeout(() => {
      formik.setFieldValue(target.name, newValue);
    });
  };

  const handlePaste = (event) => {
    const [_, pasteRow, pasteColumn] = event.target.name.split(".");

    event.clipboardData.items[
      event.clipboardData.types.indexOf("text/plain")
    ].getAsString((text) => {
      const copiedRows = text
        .replace(/\r/g, "")
        .split("\n")
        .map((row) => row.split("\t"));

      formik.setValues({
        ...formik.values,
        data: formik.values.data.map((row, rowIndex) => {
          if (rowIndex >= pasteRow && rowIndex < pasteRow + copiedRows.length) {
            copiedRows[rowIndex - pasteRow]?.forEach((field, fieldIndex) => {
              const fields = [...fieldsOrder];
              fields.shift();
              const fieldId = fields[fields.indexOf(pasteColumn) + fieldIndex];
              const fieldType = fieldTypes[fieldId];
              if (
                fieldId &&
                fieldType !== "checkbox" &&
                fieldType !== "alert"
              ) {
                row[fieldId] = field;

                const event = {
                  target: {
                    name: `data.${rowIndex}.${fieldId}`,
                    value: field,
                  },
                };

                if (fieldType === "decimal") handleDecimalChange(event);
              }
            });
          }

          return row;
        }),
      });
    });
  };

  const handleConfirmDelete = () => {
    submitData(extendData(formik.values.data));
    closeModal();
  };

  const handleRemoveValuesFromRow = (rowIndex) => () => {
    formik.setFieldValue(`data[${rowIndex}]`, initialFieldValues);
  };

  const getField = (row, rowIndex, field, fieldType, error) => {
    switch (fieldType) {
      case "alert":
        return (
          row[field] && (
            <ul
              className="box-error mb-0"
              dangerouslySetInnerHTML={{
                __html: row[field],
              }}
            />
          )
        );

      case "checkbox":
        if (field === "del" && !row.id) {
          return (
            <OverlayTrigger
              placement="left"
              delay={{ show: 0, hide: 0 }}
              overlay={<Tooltip id="clear-row-tooltip">Wyczyść wiersz</Tooltip>}
            >
              <Button
                variant="outline-secondary"
                onClick={handleRemoveValuesFromRow(rowIndex)}
              >
                <FontAwesomeIcon icon={faEraser} />
              </Button>
            </OverlayTrigger>
          );
        } else {
          return (
            <Form.Check
              checked={row[field]}
              name={`data.${rowIndex}.${field}`}
              onChange={formik.handleChange}
              type="checkbox"
            />
          );
        }

      case "decimal":
        return (
          <Form.Control
            name={`data.${rowIndex}.${field}`}
            onChange={(event) => handleDecimalChange(event)}
            onPaste={(event) => handlePaste(event)}
            style={{ borderColor: error ? "#f5593d" : "" }}
            value={formik.values.data[rowIndex][field] ?? ""}
          />
        );

      case "hidden":
        return <></>;

      case "text":
        if (field === "nazwa_opk" && row.nazwa_opk) {
          return (
            <OverlayTrigger
              placement="right"
              delay={{ show: 150, hide: 400 }}
              overlay={<Tooltip id="opk-name-tooltip">{row.nazwa_opk}</Tooltip>}
            >
              <Form.Control
                type="text"
                name={`data.${rowIndex}.${field}`}
                onChange={formik.handleChange}
                onPaste={(event) => handlePaste(event)}
                value={formik.values.data[rowIndex][field] ?? ""}
              />
            </OverlayTrigger>
          );
        }

      default:
        return (
          <Form.Control
            name={`data.${rowIndex}.${field}`}
            onChange={formik.handleChange}
            onPaste={(event) => handlePaste(event)}
            style={{ borderColor: error ? "#f5593d" : "" }}
            value={formik.values.data[rowIndex][field] ?? ""}
          />
        );
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validateOnMount: true,
    validationSchema,
  });

  useEffect(() => {
    formik.setValues(initialValues);
  }, [incomingData]);

  const errors = Array.from(
    new Set(
      (formik.errors.data || []).flatMap((currentValue) =>
        typeof currentValue === "object" && currentValue !== null
          ? Object.values(currentValue)
          : [],
      ),
    ),
  );

  useEffect(() => {
    handleDisabeledSideBarButton(errors.length > 0);
  }, [errors]);

  return (
    <div className="generated-form-main-container">
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          {errors.length !== 0 && (
            <>
              {isSendingBlocked && (
                <span>
                  Aby odblokować możliwość przesłania danych do AOTMiT popraw
                  poniższe błędy:
                </span>
              )}
              <ul className="box-error">
                {errors.map((error, index) => {
                  return (
                    <li key={index} className="box-error__item">
                      {error}
                    </li>
                  );
                })}
              </ul>
            </>
          )}
          <FieldArray name="data">
            {({ pop, push }) => (
              <div className="table-wrapper">
                <Table>
                  <colgroup>
                    {fieldLabelsWithSize.map((col, index) => (
                      <col
                        key={index}
                        style={{ width: col.size ? `${col.size}%` : "auto" }}
                      ></col>
                    ))}
                  </colgroup>
                  <thead className="text-center table-head">
                    <tr>
                      <th colSpan={fieldLabelsWithSize.length}>
                        <div>
                          <Button
                            className="w-100 mb-2"
                            onClick={() => handleSubmit(formik.values)}
                            variant="success"
                            size="lg"
                          >
                            Zapisz
                          </Button>
                          <div className="d-flex align-items-center">
                            <span className="w-100">
                              Liczba pól OPK:{" "}
                              <strong>{formik.values.data.length}</strong>
                            </span>
                            <div className="d-flex w-100 gap-2">
                              <InputGroup>
                                <Form.Control
                                  type="number"
                                  aria-describedby="add-opk-fields"
                                  ref={inputRef}
                                  defaultValue="0"
                                />
                              </InputGroup>
                              <Button
                                className="w-100"
                                onClick={() => changeNumberOfRows(push, pop)}
                                variant="light"
                              >
                                Dodaj / usuń pola
                              </Button>
                            </div>
                          </div>
                        </div>
                      </th>
                    </tr>
                    <tr>
                      {fieldLabelsWithSize.map((col, index) => {
                        return <th key={index}>{col.label}</th>;
                      })}
                    </tr>
                    <tr>
                      {hasIndexes && <th />}
                      {Object.entries(fieldTypes).map(([key, value], index) => {
                        return (
                          fieldTypes[key] !== "hidden" && (
                            <th key={index}>
                              {value === "checkbox" && (
                                <Form.Check
                                  checked={handleCheckIfChecked(key)}
                                  onChange={() => checkAll(key)}
                                  type="checkbox"
                                />
                              )}
                            </th>
                          )
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {formik.values.data.map((row, index) => {
                      return (
                        <tr key={index}>
                          {hasIndexes && <td>{index + 1}.</td>}
                          {Object.keys(row).map((key) => {
                            return (
                              fieldTypes[key] !== "hidden" && (
                                <td
                                  key={key}
                                  className={
                                    unsavedRows.includes(row.id)
                                      ? "unsaved-row"
                                      : ""
                                  }
                                >
                                  {getField(
                                    row,
                                    index,
                                    key,
                                    fieldTypes[key],
                                    formik.errors.data?.[index]?.[key],
                                  )}
                                </td>
                              )
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            )}
          </FieldArray>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default GeneratedForm;
