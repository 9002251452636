import { apiClient } from "api/api";
import { saveAs } from "file-saver";

import {
  GET_ALL_EXCEL_FILES_WITH_NEW_USERS,
  GET_ALL_PROCEEDINGS,
  GET_ALL_PROCEEDINGS_SET_LOADING,
  GET_ERRORS,
  POST_EXCEL_FILE_WITH_NEW_USERS,
  POST_EXCEL_SET_LOADING,
} from "actions/types";
import { getNewUsersSummaryRequest } from "../types";
import { setRequestStatus } from "../utilActions";

export const getAllProceedings =
  (params = {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_PROCEEDINGS_SET_LOADING,
      });

      const queryString = new URLSearchParams(params).toString();
      const url = queryString
        ? `/get_proceedings/?${queryString}`
        : `/get_proceedings/`;

      const response = await apiClient.get(url);

      dispatch({
        payload: response.data,
        type: GET_ALL_PROCEEDINGS,
      });
    } catch (err) {
      if (err.response?.data) {
        const errors = {
          msg: err.response.data,
          status: err.response.status,
        };
        dispatch({
          payload: errors,
          type: GET_ERRORS,
        });
      } else {
        console.error("Error occurred while fetching proceedings", err);
      }
    }
  };

export const getAllExcelFilesWithNewUsers =
  (procedureID) => async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_PROCEEDINGS_SET_LOADING,
      });

      const response = await apiClient.get(
        `/new_accounts/?postepowanie_id=${procedureID}`,
      );

      dispatch({
        payload: response.data,
        type: GET_ALL_EXCEL_FILES_WITH_NEW_USERS,
      });
    } catch (err) {
      if (err.response.data) {
        const errors = {
          msg: err.response.data,
          status: err.response.status,
        };
        dispatch({
          payload: errors,
          type: GET_ERRORS,
        });
      } else {
        console.log({ authErr: err });
      }
    }
  };

export const postExeclFileWithNewUsers =
  (xlsxFile, procedureID) => async (dispatch) => {
    dispatch({ type: POST_EXCEL_SET_LOADING });
    // const json = JSON.stringify(xlsxFile);
    // const fileBlob = new Blob([json], {
    //   type: "application/json",
    // });
    const data = new FormData();
    data.append("file", xlsxFile);
    data.append("postepowanie_id", procedureID);
    try {
      const response = await apiClient.post(`/new_accounts/`, data, {
        useSwitchUserToken: true,
      });
      dispatch({
        payload: response.data,
        type: POST_EXCEL_FILE_WITH_NEW_USERS,
      });
    } catch (err) {
      if (err.response.hasOwnProperty("data"))
        console.log(err.response, err.response.data);
      else console.log(err.response);
      dispatch({
        payload: err.response,
        type: GET_ERRORS,
      });
    }
  };

export const getExcelsSummary = (procedureID) => async (dispatch) => {
  try {
    dispatch(setRequestStatus(0, 100, getNewUsersSummaryRequest));
    const config = { useSwitchUserToken: true };
    let partialSize = 0;
    let diffrence = 0;
    // Funkcja przy każdym pobranym kawałku raportu sprawdza jaki jest on duży, jeżeli jest większy niż 10% aktualizuje stan.
    config["onDownloadProgress"] = (progressEvent) => {
      const fileSize = progressEvent.srcElement.getResponseHeader("file-size");
      const loaded = progressEvent.loaded;
      diffrence = loaded - partialSize;
      let progress = Math.round((loaded / fileSize) * 100);
      if (partialSize >= 0.1 * fileSize) {
        dispatch(setRequestStatus(progress, 100, getNewUsersSummaryRequest));
        partialSize = partialSize - diffrence;
      } else {
        partialSize = partialSize + diffrence;
      }
    };
    const response = await apiClient.get(
      `/create_summary/?postepowanie_id=${procedureID}`,
    );
    dispatch(setRequestStatus(null, null, null));
    const byte_chars = [...atob(response.data)];
    const byte_numbers = byte_chars.map((ele, i) => ele.charCodeAt(0));
    const byte_array = new Uint8Array(byte_numbers);
    let blob = new Blob([byte_array], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "ListaNowychKont.xlsx");
  } catch (err) {
    dispatch(setRequestStatus(null, null, null));
    console.log(err);
  }
};

export const getExcelsTemplate = (procedureID) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "arraybuffer",
    };
    const response = await apiClient.get(
      `/new_accounts_template/?id=${procedureID}`,
      config,
    );
    let blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "Szablon do nowych kont.xlsx");
  } catch (err) {
    try {
      const decoder = new TextDecoder("utf-8");
      const message = decoder.decode(err.response.data);
      const parsedMessage = JSON.parse(message);
      dispatch({
        payload: parsedMessage,
        type: GET_ERRORS,
      });
    } catch (decodeError) {
      console.error("Error decoding message:", decodeError);
    }
  }
};
