import { apiClient } from "api/api";

import {
  CHECK_DATA,
  CHECK_DATA_SET_LOADING,
  CHECK_DATA_LOADING_OFF,
  GET_ERRORS,
  GET_SUCCESS,
} from "../types";

import { getOpkData } from "../opkListActions";

export const opkCheckData = (procedureID) => async (dispatch) => {
  try {
    dispatch({ type: CHECK_DATA_SET_LOADING });
    const response = await apiClient.get(`/fk/${procedureID}/check_data`, {
      useSwitchUserToken: true,
    });

    dispatch({
      payload: response.data,
      type: CHECK_DATA,
    });

    dispatch({
      payload: {
        msg: response.data.message,
        status: response.status,
      },
      type: GET_SUCCESS,
    });

    dispatch(getOpkData(procedureID));
  } catch (err) {
    dispatch({ type: CHECK_DATA_LOADING_OFF });
    if (!err.response.data) {
      console.error({
        err: err,
        resp: err.response,
      });
    } else {
      const errors = {
        msg: err.response.statusText,
        status: err.response.status,
      };
      dispatch({
        payload: errors,
        type: GET_ERRORS,
      });
    }
  }
};
