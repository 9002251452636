import { apiClient } from "api/api";

import {
  GET_ERRORS,
  GET_OPK_USER_INFO,
  GET_OPK_USER_PROFILE,
  OPK_USER_INFO_SET_LOADING,
  OPK_USER_PROFILE_SET_LOADING,
} from "./types";

export const getOpkUserProfile = () => async (dispatch) => {
  try {
    dispatch({ type: OPK_USER_PROFILE_SET_LOADING });

    const response = await apiClient.get(`/fk/user_profile`, {
      useSwitchUserToken: true,
    });

    dispatch({
      payload: response.data,
      type: GET_OPK_USER_PROFILE,
    });
  } catch (err) {
    const errors = {
      msg: err.response.data,
      status: err.response.status,
    };
    dispatch({
      payload: errors,
      type: GET_ERRORS,
    });
  }
};

export const getUserInfo = (userID) => async (dispatch) => {
  try {
    dispatch({ type: OPK_USER_INFO_SET_LOADING });

    const response = await apiClient.get(`/fk/user_info/${userID}`);

    dispatch({
      payload: response.data,
      type: GET_OPK_USER_INFO,
    });
  } catch (err) {
    const errors = {
      msg: err.response.data,
      status: err.response.status,
    };
    dispatch({
      payload: errors,
      type: GET_ERRORS,
    });
  }
};
