// TODO: provide correct dateTime values
const ComplianceWithAct = () => {
  return (
    <>
      <h2>Status pod względem zgodności z ustawą</h2>
      <p>Strona internetowa jest <b>zgodna</b> z ustawą o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych.</p>
      <h3>Przygotowanie deklaracji w sprawie dostępności</h3>
        <ul>
          <li>Deklarację sporządzono dnia: <time dateTime="2" id="a11y-data-sporzadzenie"></time></li>
          <li>Deklaracja została ostatnio poddana przeglądowi i aktualizacji dnia: <time dateTime="2" id="a11y-data-deklaracja-przeglad"></time></li>
        </ul>
        <p>Deklarację sporządzono na podstawie samooceny.</p>
    </>
  )
}

export default ComplianceWithAct