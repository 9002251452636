import { useSelector } from "react-redux";

import TabForm from "./TabForm";
import {
  getTab3FormData,
  saveTab3FormData,
} from "../../../actions/OpkFormsActions/tab3FormActions";
import { Tab3FormFields } from "../OpkForms/FormSettings";
import { tab3ValidationSchema } from "../../../utils/validationSchemas";

const Tab3Form = () => {
  const loading = useSelector((state) => state.tab3FormReducer.loading);
  const { tab3_objects, unsavedRows } = useSelector(
    (state) => state.tab3FormReducer.Tab3Data,
  );

  return (
    <TabForm
      getFormData={getTab3FormData}
      saveFormData={saveTab3FormData}
      formFields={Tab3FormFields}
      loading={loading}
      formData={tab3_objects}
      unsavedRows={unsavedRows}
      validationSchema={tab3ValidationSchema}
    />
  );
};

export default Tab3Form;
