// import * as serviceWorker from './serviceWorker';

import "bootstrap/dist/css/bootstrap.css";

import "./assets/scss/main.scss";

import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Toaster, toast, ToastBar } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import store from "./store.js";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
      <Toaster
        position="top-right"
        toastOptions={{
          duration: 5000,
          removeDelay: 500,
          style: { cursor: "pointer" },
        }}
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <div onClick={() => toast.dismiss(t.id)} className="d-flex">
                {icon}
                <h5>{message}</h5>
              </div>
            )}
          </ToastBar>
        )}
      </Toaster>
    </Provider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
