import { useSelector } from "react-redux";

import Select from "react-select";

const ProceedingsSelect = ({ selectValue, onChangeFn }) => {
  const proceedingsOptions = useSelector(
    (state) => state.participationRegisterReducer.all_proceedings,
  );
  const loading = useSelector(
    (state) => state.participationRegisterReducer.loading,
  );

  const setOptions = (list) => {
    const newlist = list.map((object) => ({
      label: `${object.postepowanie_nr} ${object.typ_danych} - ${object.postepowanie_rok}`,
      value: object.id,
    }));
    return newlist;
  };

  return (
    <>
      <Select
        className="react-select mt-2"
        isDisabled={loading}
        onChange={(value) => onChangeFn(value)}
        options={setOptions(proceedingsOptions)}
        placeholder={
          loading
            ? "Trwa ładowanie listy, proszę czekać..."
            : "Wybierz Postępowanie"
        }
        value={selectValue}
      />
    </>
  );
};

export default ProceedingsSelect;
