import {
  GET_CONTROLLER_DATA,
  VIEW_CONTROLLER_SET_LOADING,
} from "../../actions/types.js";

const initialState = {
  controllerData: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTROLLER_DATA:
      return {
        ...state,
        controllerData: action.payload,
        loading: false,
      };
    case VIEW_CONTROLLER_SET_LOADING:
      return {
        controllerData: {},
        loading: true,
      };
    default:
      return state;
  }
};

export default reducer;
