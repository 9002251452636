import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";

import { loginUser } from "../../actions/auth";
import SkipLinks from "../../components/SkipLinks/SkipLinks";
import logo from "../../assets/img/aotm.png";

const Login = () => {
  const [formData, setFormData] = useState({ password: "", username: "" });
  const location = useLocation();

  const dispatch = useDispatch();

  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated,
  );

  const handleFieldChange = (e) => {
    const fieldValue = e.target.value;
    const fieldIndex = e.target.id;

    setFormData((prevState) => {
      const prevLoginValues = { ...prevState };
      prevLoginValues[fieldIndex] = fieldValue;
      return prevLoginValues;
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loginUser(formData));
  };

  if (isAuthenticated) {
    const { from } = location.state || { from: "/" };
    return <Navigate to={from} />;
  }

  const skipLinks = [
    {
      id: "#username",
      desc: "Przejdź do formularza logowania.",
    },
  ];

  return (
    <>
      <SkipLinks skipLinks={skipLinks} />
      <h4 className="text-center fw-bold mt-5">
        Dane kosztowe wersja {process.env.REACT_APP_VERSION}
      </h4>
      <div className="flex-center">
        <Form onSubmit={(e) => handleSubmit(e)}>
          <Card className="login-card">
            <Card.Body className="p-2">
              <Container>
                <img alt="AOTMiT logo" className="login-img" src={logo} />
                <div className="mt-5 text-center">
                  Zaloguj się w Aplikacji Dane kosztowe
                </div>
                <Form.Control
                  aria-label="Login"
                  className="my-2"
                  id="username"
                  onChange={(e) => handleFieldChange(e)}
                  placeholder="Login"
                  type="text"
                  value={formData["username"]}
                />
                <Form.Control
                  aria-label="Hasło"
                  id="password"
                  onChange={(e) => handleFieldChange(e)}
                  placeholder="Hasło"
                  type="password"
                  value={formData.password}
                />
              </Container>
            </Card.Body>
            <Card.Footer>
              <Button className="w-100" type="submit">
                Zaloguj
              </Button>

              <Nav.Link
                href="/registration/forgot-password"
                className="text-muted text-center mt-2"
              >
                Zapomniałeś hasła?
              </Nav.Link>
            </Card.Footer>
          </Card>
        </Form>
      </div>
    </>
  );
};

export default Login;
