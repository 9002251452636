import React from "react";

import "./BaseLayout.scss";
import { Footer } from "../components/Footer/footer.js";
import Header from "../components/Header/Header.js";

const BaseLayout = (props) => {
  return (
    <div className="custom-layout">
      <Header />
      {props.children}
      <Footer />
    </div>
  );
};

export { BaseLayout };
