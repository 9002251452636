import { Modal } from "react-bootstrap";

import logo from "../../assets/img/aotm_50px.png";

const CustomModal = ({ show, onHide, modalContent }) => {
  return (
    <Modal centered onHide={onHide} show={show}>
      <Modal.Header closeButton>
        <div className="d-flex align-items-center">
          <img
            alt="AOTMiT logo"
            className="d-inline-block align-top"
            height="50"
            src={logo}
            width="50"
          />
          <h4 className="m-1">Uwaga!</h4>
        </div>
      </Modal.Header>
      <Modal.Body>
        {modalContent.title && (
          <div className="mb-3 text-center">
            <strong>{modalContent.title}</strong>
          </div>
        )}
        {modalContent.message && (
          <div className="mb-3 text-center">{modalContent.message}</div>
        )}
        {modalContent.itemsList?.length > 0 && (
          <ul className="mb-0">
            {modalContent.itemsList.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-evenly">
        {modalContent.children}
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModal;
