import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector } from "react-redux";
const GenerateSpecyficDataForm = ({
  uploadedFilesLabels,
  handleFileInput,
  validateFileType,
}) => {
  const specyficDataFormFields = useSelector(
    (state) => state.sd_FormReducer.specyficDataFormFields,
  );
  const fieldsNames = Object.keys(specyficDataFormFields).filter(
    (key) => specyficDataFormFields[key],
  );

  return (
    <>
      {fieldsNames.map((element, el_idx) => {
        return (
          <Form.Group as={Row} className="pb-2 my-2 border-bottom" key={el_idx}>
            <Form.Label column sm={2} htmlFor={element}>
              Plik {element}
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                // TODO: fix this, also this label...
                // accept={validateFileType(element)}
                // custom
                data-browse="Wybierz plik"
                id={element}
                label={
                  uploadedFilesLabels[element]
                    ? uploadedFilesLabels[element]
                    : "nie wybrano pliku"
                }
                onChange={(event) => handleFileInput(event)}
                type="file"
              />
            </Col>
          </Form.Group>
        );
      })}
    </>
  );
};
export default GenerateSpecyficDataForm;
