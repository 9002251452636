import { GET_ERRORS, GET_SUCCESS, CLEAR_ALERTS } from "../actions/types";
const initialState = {
  alertType: null,
  msg: {},
  status: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ERRORS:
      const errorMessage =
        action.payload.msg?.error ||
        action.payload.msg ||
        action.payload.message ||
        action.payload.data?.message ||
        action.payload.data ||
        action.payload.detail ||
        action.payload.error ||
        action.payload.errors ||
        action.payload.blad;

      return {
        alertType: "error",
        msg:
          typeof errorMessage === "string"
            ? errorMessage
            : "Wystąpił błąd. Spróbuj ponownie później.",
        status: action.payload.status,
      };
    case GET_SUCCESS:
      return {
        alertType: "success",
        msg: action.payload.msg,
        status: action.payload.status,
      };
    case CLEAR_ALERTS:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
