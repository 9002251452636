import {
  CHECK_DATA,
  CHECK_DATA_SET_LOADING,
  CHECK_DATA_LOADING_OFF,
} from "../../../actions/types.js";

const initialState = {
  data_checked: [],
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_DATA:
      return {
        ...state,
        data_checked: action.payload,
        loading: false,
      };
    case CHECK_DATA_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CHECK_DATA_LOADING_OFF:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export default reducer;
