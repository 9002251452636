import React from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

const OpkListComponent = ({ opkID, opklist, procedureID }) => {
  const hasNotMedicalOpk = opklist.some((opk) => opk.opk_niemedyczny === true);
  const OpkRender = (title, isNotMedical, id) => {
    return (
      <>
        <div className="border-box">
          <div className="border-box-title">{title}</div>
          <div className="border-box-content" id={id}>
            {opklist
              .filter((opk) => opk.opk_niemedyczny === isNotMedical)
              .map((opk) => (
                <Link
                  key={opk.id}
                  to={
                    isNotMedical
                      ? `/fk/${procedureID}/opk/${opk.id}/tab4form`
                      : `/fk/${procedureID}/opk/${opk.id}/opkform`
                  }
                  tabIndex="-1"
                >
                  <Button
                    className={`m-1 btn-bg-white ${opk.tabs_with_errors.length ? "btn-danger-accessible" : "btn-info-accessible"} ${opk.id === parseInt(opkID) && "active"}`}
                    size="sm"
                  >
                    {opk.nr_konta}
                  </Button>
                </Link>
              ))}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {OpkRender("OPK medyczne (pełne dane FK)", false, "opkmedical")}
      {!!hasNotMedicalOpk &&
        OpkRender("OPK niepełne dane FK (Tab4)", true, "opknotmedical")}
    </>
  );
};

export default OpkListComponent;
