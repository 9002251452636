import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Navigate } from "react-router-dom";

import { getSpecificDataFormFields } from "actions/SpecificDataActions/SD_FormActions";
import { getSpecificDataViewControllerData } from "actions/SpecificDataActions/SpecificDataViewsControllerActions";
import { Loading } from "../../components/Loading/loading";

const SpecificDataViewController = () => {
  const { procedureID } = useParams();

  const dispatch = useDispatch();

  const specificDataControllerLoading = useSelector(
    (state) =>
      state.specificDataViewControllerReducer.specificDataControllerLoading,
  );

  useEffect(() => {
    dispatch(getSpecificDataViewControllerData(procedureID));
    dispatch(getSpecificDataFormFields(procedureID));
  }, [dispatch, procedureID]);

  if (specificDataControllerLoading) {
    return (
      <Loading variant="success">
        <span className="m-2">Dane są pobierane z serwera...</span>
      </Loading>
    );
  } else {
    return (
      <Navigate to={`/daneszcz/${procedureID}/specific_data_form`} replace />
    );
  }
};

export default SpecificDataViewController;
