import React, { Fragment, useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  addNotes,
  delNotes,
  getFiles,
  getNotes,
} from "../../../actions/StaffUserViewsActions/NotesAndFilesActions";

const NotesAndFilesComponent = () => {
  const [formData, setFormData] = useState("");

  const dispatch = useDispatch();
  const { procedureID } = useParams();

  const files = useSelector((state) => state.filesReducer.files);
  const files_loading = useSelector((state) => state.filesReducer.loading);
  const isSwitchedUser = useSelector(
    (state) => state.switchUserReducer.isSwitchedUser,
  );
  const notes = useSelector((state) => state.notesReducer.notes);
  const notes_loading = useSelector((state) => state.notesReducer.loading);
  const switchedUser = useSelector(
    (state) => state.switchUserReducer.switchedUser,
  );

  useEffect(() => {
    if (isSwitchedUser) {
      dispatch(getNotes(procedureID, switchedUser.id.toString()));
      dispatch(getFiles(procedureID));
    }
  }, [dispatch, isSwitchedUser, procedureID, switchedUser]);

  const handleFieldChange = (e) => {
    setFormData(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const dataToSubmit = {
      tresc: formData,
    };
    setFormData("");

    dispatch(addNotes(procedureID, switchedUser.id.toString(), dataToSubmit));
  };

  const handleDelete = (event) => {
    const delNoteID = event.target.id.split("-")[1];
    dispatch(
      delNotes(procedureID, switchedUser.id.toString(), parseInt(delNoteID)),
    );
  };

  return (
    <div className="full-container--lm notes-box my-4">
      <Row>
        <Col>
          <Accordion className="notes-box-content">
            <Accordion.Item eventKey="1">
              <Accordion.Header as="div">Notatki</Accordion.Header>
              <Accordion.Body>
                <Form onSubmit={(e) => handleSubmit(e)}>
                  <>
                    {notes_loading
                      ? "loading..."
                      : notes.map((note, idx) => {
                          return (
                            <Fragment key={idx}>
                              <div className="box-messages">
                                <div className="message-user">
                                  {note.from_user}
                                </div>
                                <div className="message-content">
                                  {note.tresc}
                                </div>
                                <Button
                                  className="message-delete"
                                  id={`del-${note.id}`}
                                  onClick={(e) => handleDelete(e)}
                                  size="sm"
                                  variant="outline-danger"
                                >
                                  &times;
                                </Button>
                              </div>
                            </Fragment>
                          );
                        })}
                  </>
                  <Form.Control
                    as="textarea"
                    className="my-2"
                    onChange={(e) => handleFieldChange(e)}
                    rows={3}
                    value={formData}
                  />
                  <Button
                    className="btn-bg-white"
                    size="sm"
                    type="submit"
                    variant="outline-success"
                  >
                    Zapisz notatkę
                  </Button>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        <Col>
          <Accordion className="notes-box-content">
            <Accordion.Item eventKey="1">
              <Accordion.Header as="div">Pliki</Accordion.Header>
              <Accordion.Body>
                <ListGroup className="swd-info" variant="flush">
                  {files_loading
                    ? "Loading Files..."
                    : files.map((file, file_id) => {
                        const fileName = file.plik.split("/").pop();
                        const fileDateAll = file.data.split("T");
                        const fileDate = fileDateAll[0];
                        const fileTime = fileDateAll[1].split(".")[0];
                        return (
                          <a href={file.plik} key={file_id}>
                            <ListGroup.Item>
                              {`${fileName} dodany ${fileDate} o ${fileTime}`}
                            </ListGroup.Item>
                          </a>
                        );
                      })}
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </div>
  );
};

export default NotesAndFilesComponent;
