import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, ProgressBar, Table } from "react-bootstrap";

import {
  getReport,
  getSecyficDataFiles,
  updateSpecyficDataFiles,
} from "../../actions/SpecificDataActions/SD_FormActions";

const SDFilesTable = () => {
  const dispatch = useDispatch();
  const { procedureID } = useParams();
  const [reportKey, setReportKey] = useState(null);

  const alreadyLoaded = useSelector(
    (state) => state.utilityReducer.alreadyLoaded,
  );
  const specyficDataFiles = useSelector(
    (state) => state.sd_FormReducer.specyficDataFiles,
  );
  const specyficDataloading = useSelector(
    (state) => state.sd_FormReducer.specyficDataloading,
  );
  const totalSize = useSelector((state) => state.utilityReducer.totalSize);

  useEffect(() => {
    dispatch(getSecyficDataFiles(procedureID));
  }, [procedureID]);

  useEffect(() => {
    if (totalSize === null) {
      setReportKey(null);
    }
  }, [totalSize]);

  useEffect(() => {
    const pendingDataFiles = specyficDataFiles.results?.some(
      (file) => file.czy_raport === "Oczekuje",
    );

    if (pendingDataFiles) {
      let interval = setInterval(() => {
        dispatch(updateSpecyficDataFiles(procedureID));
      }, 20000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [dispatch, procedureID, specyficDataFiles]);

  const get_raport = (id, file, key) => {
    setReportKey(key);
    dispatch(getReport(id, file, procedureID));
  };

  return (
    <>
      {!specyficDataloading && (
        <Table responsive striped>
          <thead>
            <tr>
              <th className="text-center">Nazwa Pliku</th>
              <th className="text-center">Data przeslania</th>
              <th className="text-center">Typ pliku</th>
              <th className="text-right">Sprawdzony plik</th>
            </tr>
          </thead>
          <tbody className="font-small">
            {!specyficDataloading &&
              specyficDataFiles.results &&
              specyficDataFiles.results.map((file, file_idx) => {
                return (
                  <tr key={file_idx}>
                    <td className="text-center">
                      <a href={file.plik2}>{file.nazwa}</a>
                    </td>
                    <td className="text-center">
                      {new Date(file.data_wgrania).toLocaleString()}
                    </td>
                    <td className="text-center">{file.typ_pliku}</td>
                    <td className="text-right">
                      <Button
                        className="btn-aotmit mb-0"
                        disabled={
                          file.czy_raport &&
                          (file.czy_raport !== "Raport gotowy" ||
                            (file.czy_raport === "Raport gotowy" &&
                              reportKey === file_idx &&
                              totalSize)) &&
                          true
                        }
                        onClick={(e) =>
                          get_raport(
                            file.raport_id,
                            file.wynik_raport,
                            file_idx,
                          )
                        }
                        size="sm"
                        variant="outline-secondary"
                      >
                        {reportKey === file_idx && totalSize
                          ? "Trwa pobieranie raportu"
                          : file.czy_raport}
                        {reportKey === file_idx && totalSize && (
                          <ProgressBar now={alreadyLoaded} />
                        )}
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default SDFilesTable;
