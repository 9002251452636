import { apiClient } from "api/api";

import {
  ANALYTICS_DATA_LOADING_OFF,
  ANALYTICS_DATA_SET_LOADING,
  GET_ANALYTICS_DATA,
  GET_ERRORS,
  GET_SUCCESS,
  SAVE_ANALYTICS_DATA,
} from "../types";
import { SUCCESS_INFO } from "utils/toastMessages";

export const getAnalyticsData = (procedureID) => async (dispatch) => {
  try {
    dispatch({ type: ANALYTICS_DATA_SET_LOADING });

    const response = await apiClient.get(
      `/analytics/${procedureID}/analytic_api_view/`,
      { useSwitchUserToken: true },
    );
    dispatch({
      payload: response.data,
      type: GET_ANALYTICS_DATA,
    });
  } catch (err) {
    if (err.response) {
      console.log("Analytics", err.response.data);
      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };

      dispatch({
        payload: errors,
        type: GET_ERRORS,
      });
    } else {
      console.log({ err });
    }
  }
};

export const saveAnalyticsFormData =
  (procedureID, submitedFormData) => async (dispatch) => {
    dispatch({ type: ANALYTICS_DATA_SET_LOADING });
    try {
      const response = await apiClient.post(
        `/analytics/${procedureID}/analytic_api_view/`,
        submitedFormData,
        { useSwitchUserToken: true },
      );
      dispatch({
        payload: {
          msg: SUCCESS_INFO,
          status: response.status,
        },
        type: GET_SUCCESS,
      });

      dispatch({
        payload: response.data,
        type: SAVE_ANALYTICS_DATA,
      });
    } catch (err) {
      dispatch({ type: ANALYTICS_DATA_LOADING_OFF });
      if (err.response) {
        const errors = {
          msg: err.response.data,
          status: err.response.status,
        };

        dispatch({
          payload: errors,
          type: GET_ERRORS,
        });
      } else {
        console.log({ err });
      }
    }
  };
