import { Card, Col, Row } from "react-bootstrap";

import SpinnerWithText from "../../components/Loading/SpinnerWithText";

const HealthcareProviderCard = ({
  dataLoading,
  healthcareProvider,
  statusFor,
}) => {
  const showStatus = () => {
    switch (statusFor) {
      case "opk": {
        const verifications = healthcareProvider?.verifications_opk_list || [];
        if (verifications.length === 0) {
          return "SWD nie wysłał prośby o weryfikację";
        }

        const lastElement = verifications[verifications.length - 1];
        switch (lastElement.czy_otwarte) {
          case true:
            return "SWD wysłał prośbę o weryfikację";
          case false:
            return `Dane zostały zaakceptowane, zatwierdzono listę OPK (${lastElement.zatwierdzil})`;
          case null:
            return "Otwarte do edycji";
          default:
            return "Status nieznany";
        }
      }

      case "participation":
        return healthcareProvider?.verifications?.czy_otwarte_display || "-";

      default:
        return "-";
    }
  };
  return (
    <>
      <Card>
        <Card.Body>
          {dataLoading ? (
            <SpinnerWithText />
          ) : healthcareProvider?.user?.profile[0] === undefined ? (
            <>
              <Row className="mb-2">
                <Col sm={3}>Nazwa SWD:</Col>
                <Col sm={9}>Dane SWD nie zostały uzupełnione</Col>
              </Row>
              <Row>
                <Col sm={3}>Status weryfikacji:</Col>
                <Col sm={9}>{showStatus()}</Col>
              </Row>
            </>
          ) : (
            <>
              <Row className="mb-2">
                <Col sm={3}>Nazwa SWD:</Col>
                <Col sm={9}>{healthcareProvider.user.profile[0].swd_nazwa}</Col>
              </Row>
              <Row className="mb-2">
                <Col sm={3}>Adres:</Col>
                <Col
                  sm={9}
                >{`${healthcareProvider.user.profile[0].swd_ulica} ${healthcareProvider.user.profile[0].swd_kodpocztowy} ${healthcareProvider.user.profile[0].swd_miejscowosc}`}</Col>
              </Row>
              <Row className="mb-2">
                <Col sm={3}>Kod SWD:</Col>
                <Col sm={9}>{healthcareProvider.user.profile[0].swd_kod}</Col>
              </Row>
              <Row>
                <Col sm={3}>Status weryfikacji:</Col>
                <Col sm={9}>{showStatus()}</Col>
              </Row>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default HealthcareProviderCard;
