import { Navigate, Outlet } from "react-router-dom";

import { Loading } from "../components/Loading/loading";

const ProtectedRoute = ({children, isAllowed, isLoading, redirectPath = "/registration/login" }) => {
  if (!localStorage.getItem("auth_token")) {
    return <Navigate replace to={redirectPath} />;
  }

  if (isLoading || isLoading === null) {
    return (
      <Loading variant="info">
        <span className="m-2">Wczytywanie danych...</span>
      </Loading>
    );
  }

  if (!isAllowed) {
    return <Navigate replace to={redirectPath} />;
  }

  return children || <Outlet />;
};

export default ProtectedRoute;
