import { faCog, faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { apiClient } from "api/api";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getOpkUserProfile } from "../../actions/OpkUserProfileActions";
import { Loading } from "../../components/Loading/loading";
import { userValidationSchema } from "../../utils/validationSchemas";
import OpkColumnForm from "./OpkForms/OpkColumnForm";
import SkipLinks from "../../components/SkipLinks/SkipLinks";
import BreadcrumbContainer from "components/Breadcrumbs/BreadcrumbContainer";
import { UserProfileFormFields } from "../../utils/formFields";
import { SUCCESS_INFO } from "utils/toastMessages";
import {
  OPK_USER_PROFILE_SET_LOADING,
  OPK_USER_PROFILE_SET_FULFILLED,
} from "actions/types";

const UserProfileInfo = ({ opk_User, saveData }) => {
  if (opk_User.profile.length > 0) {
    return (
      <OpkColumnForm
        dataSubmit={saveData}
        formFields={UserProfileFormFields}
        opk_object={opk_User.profile[0]}
        validationSchema={userValidationSchema}
      />
    );
  } else {
    return (
      <>
        <Card className="text-center mb-5">
          <Card.Header className={"text-warning"}>
            <FontAwesomeIcon icon={faInfo} size="2x" />
          </Card.Header>
          <Card.Body>Profil tego użytkownika nie został utworzony</Card.Body>
        </Card>
      </>
    );
  }
};

const OpkUserProfile = () => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [showToast, setShowToast] = useState({ message: "", toggleShow: "" });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.opkUserProfileReducer.loading);
  const opk_User = useSelector((state) => state.opkUserProfileReducer.opk_User);

  const handleSideBarToggler = () => {
    setShowSidebar((prevState) => !prevState);
  };

  useEffect(() => {
    dispatch(getOpkUserProfile());
  }, [dispatch]);

  const saveData = async (submitedFormData) => {
    try {
      const ogID = opk_User.profile[0].id;

      dispatch({ type: OPK_USER_PROFILE_SET_LOADING });
      await apiClient.put(`fk/og/${ogID}/`, submitedFormData);
      dispatch({ type: OPK_USER_PROFILE_SET_FULFILLED });

      setShowToast({
        message: SUCCESS_INFO,
        toggleShow: "show bg-success",
      });
    } catch {
      setShowToast({
        message: "Dane nie zostały zapisane, spróbuj ponownie później.",
        toggleShow: "show bg-warning",
      });
    }
  };

  const skipLinks = [
    {
      id: "#sidemenu",
      desc: "Przejdź do menu bocznego.",
    },
    {
      id: "#profile",
      desc: "Przejdź do podstawowych danych profilu użytkownika.",
    },
    {
      id: "#main",
      desc: "Przejdź do formularza profilu użytkownika.",
    },
  ];

  return (
    <>
      <SkipLinks skipLinks={skipLinks} />
      <div className="full-container--lm">
        <div
          className="fixedPlugin"
          tabIndex="0"
          onClick={handleSideBarToggler}
        >
          <FontAwesomeIcon icon={faCog} size="2x" />
        </div>

        {showSidebar && (
          <div className="dropdownMenuProfile">
            <Button
              className="btn-aotmit w-100"
              onClick={() => navigate(-1)}
              size="sm"
              type="button"
              variant="outline-success"
              id="sidemenu"
            >
              Powrót
            </Button>
          </div>
        )}
        <Row>
          <BreadcrumbContainer />
          <Col className="position-relative">
            <Card id="profile">
              <Card.Title className="ps-3">Profil Użytkownika</Card.Title>
              <ul>
                <li>
                  Użytkownik: <p>{opk_User.username}</p>
                </li>
                <li>
                  e-mail: <p>{opk_User.email}</p>
                </li>
              </ul>
            </Card>
            <main id="main">
              {loading ? (
                <Loading variant="danger">
                  <span className="m-2">
                    Wczytywanie danych. Proszę czekać...
                  </span>
                </Loading>
              ) : (
                opk_User.profile && (
                  <UserProfileInfo opk_User={opk_User} saveData={saveData} />
                )
              )}
            </main>

            <div className="toast-container position-absolute top-50 end-0 p-5">
              <div
                aria-atomic="true"
                aria-live="assertive"
                className={`toast ${showToast.toggleShow}`}
                role="alert"
              >
                <div className="toast-header m-1">
                  <strong className="me-auto p-2">{showToast.message}</strong>
                  <button
                    aria-label="Close"
                    className="btn-close"
                    data-bs-dismiss="toast"
                    onClick={() =>
                      setShowToast({ message: "", toggleShow: "" })
                    }
                    type="button"
                  ></button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default OpkUserProfile;
