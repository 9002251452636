import { useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useFormik } from "formik";

import { initialValues, validationSchema } from "./ResetPasswordFormData";
import RegistrationBox from "./RegistrationBox";
import PasswordFormControl from "components/Forms/PasswordFormControl";
import { setNewPassword } from "actions/auth";
import InfoModal from "components/Modal/InfoModal";
import PasswordStrengthBar from "components/Forms/PasswordStrengthBar";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uidb64, token } = useParams();
  const { pathname: url } = useLocation();

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);

      try {
        const formData = {
          uidb64,
          refresh_token: token,
          new_password: values.newPassword,
          confirm_password: values.repeatPassword,
        };
        const result = await dispatch(setNewPassword(formData, url));
        resetForm();
        setModalContent(result.message);
      } catch (error) {
        setModalContent(
          error.error || "Wystąpił błąd. Spróbuj ponownie później",
        );
      } finally {
        setLoading(false);
        setShowModal(true);
      }
    },
    validationSchema,
  });

  const handleCloseModal = () => {
    setModalContent("");
    setShowModal(false);
    if (!formik.dirty) {
      navigate("/registration/login");
    }
  };

  return (
    <>
      <RegistrationBox
        formik={formik}
        loading={loading}
        title="Tworzenie nowego hasła"
      >
        <PasswordFormControl
          formik={formik}
          fieldName={"newPassword"}
          placeholderText={"Wpisz nowe hasło"}
        />
        <PasswordStrengthBar formik={formik} />

        <PasswordFormControl
          formik={formik}
          fieldName={"repeatPassword"}
          placeholderText={"Powtórz hasło"}
        />
      </RegistrationBox>

      <InfoModal
        handleClose={handleCloseModal}
        modalBtnText={!formik.dirty ? "Przejdź do strony logowania" : "Powrót"}
        modalBodyText={modalContent}
        modalTitle="Reset hasła"
        show={showModal}
        size="md"
      />
    </>
  );
};
export default ResetPassword;
