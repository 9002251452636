import {
  GET_SWITCHED_USER_INFO,
  SWITCHED_USER_INFO_SET_LOADING,
} from "../../../actions/types.js";

const initialState = {
  switchedUserInfo: {},
  switchedUserInfoLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SWITCHED_USER_INFO:
      return {
        ...state,
        loading: false,
        switchedUserInfo: action.payload,
      };
    case SWITCHED_USER_INFO_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default reducer;
