import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import BreadcrumbContainer from "components/Breadcrumbs/BreadcrumbContainer";

const Sitemap = () => {
  const { isAuthenticated } = useSelector((state) => state.authReducer);

  return (
    <div className="full-container mt-5">
      <BreadcrumbContainer />
      <div className="m-5">
        <h1>Mapa strony:</h1>
        <ul>
          <li>
            {isAuthenticated ? (
              <Link className="nav-link my-2" to="/">
                Strona główna
              </Link>
            ) : (
              <Link className="nav-link my-2" to="/registration/login">
                Strona logowania
              </Link>
            )}
          </li>
          {!isAuthenticated && (
            <li>
              <Link
                className="nav-link my-2"
                to="/registration/forgot-password"
              >
                Odzyskiwanie hasła
              </Link>
            </li>
          )}
          <li>
            <Link className="nav-link my-2" to="/instrukcja/materialy">
              Materiały
            </Link>
          </li>
          <li>
            <Link className="nav-link my-2" to="/instrukcja/FAQ">
              FAQ
            </Link>
          </li>
          <li>
            <Link className="nav-link my-2" to="/wcag">
              Dostępność cyfrowa
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sitemap;
