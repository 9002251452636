import { Spinner } from "react-bootstrap";

const SpinnerWithText = ({text = "Trwa wczytywanie danych. Proszę czekać.", variant = "info"}) => {
  return (
    <>
      <div className="container-items-centered">
        <Spinner animation="border" variant={variant} />
      </div>
      <div className="text-center mt-3">
        <p>{text}</p>
      </div>
    </>
  );
};

export default SpinnerWithText;
