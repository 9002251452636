import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Button, Col, Form, Row } from "react-bootstrap";

import { getProceedingUsers } from "../../actions/PanelDashboardActions/RestoreForImprovmentActions";
import { getAllProceedings } from "../../actions/PanelDashboardActions/ParticipationRegisterActions";
import { fetchSelectedUserData } from "../../actions/PanelDashboardActions/RestoreForImprovmentActions";
import {
  acceptParticipation,
  rejectParticipation,
  restoreParticipation,
} from "../../actions/PanelDashboardActions/VerifyParticipationActions";
import ProceedingsSelect from "../../components/Selects/ProceedingsSelect";
import HealthcareProviderSelect from "../../components/Selects/HealthcareProviderSelect";
import HealthcareProviderCard from "../../components/Cards/HealthcareProviderCard";

const VerificationOfParticipation = () => {
  const dispatch = useDispatch();
  const [proceeding, setProceeding] = useState(null);
  const [healthcareProvider, setHealthcareProvider] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);

  useEffect(() => {
    dispatch(getAllProceedings());
  }, [dispatch]);

  const isActionDisabled = !proceeding?.value || !healthcareProvider?.user?.id;

  const handleProceedingChange = useCallback(
    (option) => {
      setProceeding(option);
      setHealthcareProvider(null);
      dispatch(getProceedingUsers(option.value));
    },
    [dispatch],
  );

  const handleHealthcareProviderChange = useCallback(
    async (option) => {
      if (!option?.value) {
        setHealthcareProvider(null);
        return;
      }

      setLoading(true);
      try {
        const data = await dispatch(
          fetchSelectedUserData(proceeding.value, option.value),
        );
        setHealthcareProvider(data);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, proceeding],
  );

  const handleAction = useCallback(
    async (action) => {
      if (isActionDisabled) return;
      setLoading(true);

      await dispatch(
        action(proceeding.value, healthcareProvider.user.id, sendEmail),
      );

      const updatedData = await dispatch(
        fetchSelectedUserData(proceeding.value, healthcareProvider.user.id),
      );

      setHealthcareProvider(updatedData);
      setLoading(false);
    },
    [dispatch, proceeding, healthcareProvider, isActionDisabled],
  );

  return (
    <>
      <div className="head-container">
        <h3>Weryfikacje</h3>
        <p>Status weryfikacji danych.</p>
      </div>
      <main className="main-container">
        <div className="mx-2 mb-4 d-flex gap-2">
          <Button
            onClick={() => handleAction(acceptParticipation)}
            disabled={isActionDisabled}
            variant="outline-success"
          >
            Akceptacja
          </Button>
          <Button
            onClick={() => handleAction(restoreParticipation)}
            disabled={isActionDisabled}
            variant="outline-secondary"
          >
            Przywrócenie do poprawy
          </Button>
          <Button
            onClick={() => handleAction(rejectParticipation)}
            disabled={isActionDisabled}
            variant="outline-danger"
          >
            Odrzucenie
          </Button>
        </div>
        <Row className="mb-4">
          <Col md={4} sm={12}>
            <ProceedingsSelect
              selectValue={proceeding}
              onChangeFn={handleProceedingChange}
            />
            <HealthcareProviderSelect
              selectValue={healthcareProvider}
              onChangeFn={handleHealthcareProviderChange}
              isDisabled={!proceeding}
            />
            <div className="m-1">
              <Form.Group controlId="sendEmailCheckbox" className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Czy wysłać maila?"
                  checked={sendEmail}
                  onChange={(e) => setSendEmail(e.target.checked)}
                  disabled={isActionDisabled}
                />
              </Form.Group>
            </div>
          </Col>
          {(loading || healthcareProvider) && (
            <Col md={8} sm={12}>
              <HealthcareProviderCard
                dataLoading={loading}
                healthcareProvider={healthcareProvider}
                statusFor={"participation"}
              />
            </Col>
          )}
        </Row>
      </main>
    </>
  );
};

export default VerificationOfParticipation;
