import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  getOpkFormData,
  saveOpkFormData,
} from "../../../actions/OpkFormsActions/opkFormActions";
import { Loading } from "../../../components/Loading/loading";
import { OpkFormFields } from "../OpkForms/FormSettings";
import OpkColumnForm from "../OpkForms/OpkColumnForm";

const OpkForm = () => {
  const { opkID, procedureID } = useParams();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.opkFormReducer.loading);
  const opk_object = useSelector(
    (state) => state.opkFormReducer.opkData.opk_object,
  );

  useEffect(() => {
    dispatch(getOpkFormData(procedureID, opkID));
  }, [dispatch, procedureID, opkID]);

  const saveData = async (submitedFormData) => {
    dispatch(saveOpkFormData(procedureID, opkID, submitedFormData));
  };

  return (
    <>
      {loading ? (
        <Loading variant="danger">
          <span className="m-2">Wczytywanie danych. Proszę czekać...</span>
        </Loading>
      ) : (
        <OpkColumnForm
          dataSubmit={saveData}
          formFields={OpkFormFields}
          opk_object={opk_object}
        />
      )}
    </>
  );
};

export default OpkForm;
