import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, ListGroup, ProgressBar } from "react-bootstrap";
import { toast } from "react-hot-toast";

import {
  getSpecificDataFormFields,
  uploadSecyficDataFiles,
} from "../../actions/SpecificDataActions/SD_FormActions";
import { postSpecificDataRequest } from "../../actions/types";
import { Loading } from "../../components/Loading/loading";
import SkipLinks from "../../components/SkipLinks/SkipLinks";
import BreadcrumbContainer from "components/Breadcrumbs/BreadcrumbContainer";
import GenerateSpecyficDataForm from "./GenerateSpecyficDataForm";
import SDFilesTable from "./SDFilesTable";
import SDSidebarButtons from "./SDSidebarButtons";
import { areFieldsFilled } from "../../utils/validators";
import { REQUIRED_GENERAL_FIELDS as REQUIRED_FIELDS } from "../../utils/constants";

const SpecificDataForm = () => {
  const [uploadedFilesLabels, setUploadedFilesLabels] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState(new FormData());
  const [showSidebar, setShowSidebar] = useState(true);
  const [isToastShown, setIsToastShown] = useState(false);

  const { procedureID } = useParams();
  const dispatch = useDispatch();

  const alreadyLoaded = useSelector(
    (state) => state.utilityReducer.alreadyLoaded,
  );
  const requestType = useSelector((state) => state.utilityReducer.requestType);
  const specyficDataloading = useSelector(
    (state) => state.sd_FormReducer.specyficDataloading,
  );
  const totalSize = useSelector((state) => state.utilityReducer.totalSize);
  const opk_User = useSelector((state) => state.opkUserProfileReducer.opk_User);

  useEffect(() => {
    const profile = opk_User.profile?.[0];

    if (
      profile &&
      !areFieldsFilled(profile, REQUIRED_FIELDS) &&
      !isToastShown
    ) {
      toast.error("Uzupełnij informacje w zakładce dane ogólne!", {
        id: "fill-user-data-dszcz",
      });
      setIsToastShown(true);
    }
  }, [opk_User]);

  useEffect(() => {
    dispatch(getSpecificDataFormFields(procedureID));
  }, [dispatch, procedureID]);

  const handleSideBarToggler = () => {
    setShowSidebar((prevState) => !prevState);
  };

  const handleFileInput = (event) => {
    const newID = event.target.id;
    const newFile = event.target.files[0];

    setUploadedFiles((prevState) => {
      const newState = prevState;
      if (newState.get(newID)) {
        newState.set(newID, newFile);
      } else {
        newState.append(newID, newFile);
      }

      return newState;
    });

    setUploadedFilesLabels((prevState) => {
      const newState = {
        ...prevState,
      };

      newState[`${newID}`] = newFile.name;
      return newState;
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(uploadSecyficDataFiles(procedureID, uploadedFiles));
    setUploadedFiles(new FormData());
    setUploadedFilesLabels({});
  };

  const validateFileType = (element) => {
    if (element !== "OM" && element !== "CP") {
      return ".csv";
    }
  };
  const skipLinks = [
    {
      id: "#sidemenu",
      desc: "Przejdź do menu bocznego.",
    },
    {
      id: "#main",
      desc: "Przejdź do danych szczegółowych.",
    },
  ];

  return (
    <>
      <SkipLinks skipLinks={skipLinks} />
      <div className="fixedPlugin" tabIndex="0" onClick={handleSideBarToggler}>
        <FontAwesomeIcon icon={faCog} size="2x" />
      </div>

      <div className="full-container--lm">
        {showSidebar && <SDSidebarButtons procedureID={procedureID} />}
        <div className="m-2">
          <BreadcrumbContainer />
          <div className="mt-4">
            <h2>Dane szczegółowe</h2>
            <p>Wgraj pliki do weryfikacji</p>
          </div>

          <main className="mb-5 flex-container" id="main">
            <ListGroup className="list-item font-small mb-4" variant="light">
              <Form onSubmit={(e) => handleSubmit(e)}>
                <>
                  {specyficDataloading ? (
                    <>
                      <Loading variant="info">
                        <span className="m-2">
                          Informacja o postępowaniach jest pobierana z
                          serwera...
                        </span>
                      </Loading>
                      <>
                        {requestType === postSpecificDataRequest &&
                          totalSize && <ProgressBar now={alreadyLoaded} />}
                      </>
                    </>
                  ) : (
                    <>
                      <GenerateSpecyficDataForm
                        handleFileInput={handleFileInput}
                        uploadedFilesLabels={uploadedFilesLabels}
                        validateFileType={validateFileType}
                      />
                      <Button
                        className="my-3"
                        type="submit"
                        variant="outline-success"
                      >
                        Zapisz
                      </Button>
                    </>
                  )}
                </>
              </Form>
            </ListGroup>
            <SDFilesTable />
          </main>
        </div>
      </div>
    </>
  );
};

export default SpecificDataForm;
