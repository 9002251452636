// TODO:
// provide correct url
// provide correct dateTime values
const Declaration = () => {
  return (
    <>
      <h1>Deklaracja dostępności</h1>
      <p id="a11y-wstep">
        <strong><span id="a11y-podmiot">Agencja Oceny Technologii Medycznych i Taryfikacji (AOTMiT){' '}</span></strong>
        zobowiązuje się zapewnić dostępność swojej strony internetowej zgodnie z przepisami ustawy z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych.
        Oświadczenie w sprawie dostępności ma zastosowanie do strony internetowej{' '}
        <a href="#" id="a11y-url"><strong>adres strony</strong></a>.
      </p>
      <ul>
        <li>Data publikacji strony internetowej: <time dateTime="" id="a11y-data-publikacja"></time></li>
        <li>Data ostatniej istotnej aktualizacji: <time dateTime="" id="a11y-data-aktualizacja"></time></li>
      </ul>
    </>
  )
}

export default Declaration