import {
  SWITCH_USER_FAIL,
  SWITCH_USER_LOADED,
  SWITCH_USER_LOGOUT_SUCCESS,
  SWITCH_USER_SUCCESS,
} from "../actions/types";

const initialState = {
  isSwitchedUser: false,
  loading: null,
  switchedUser: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SWITCH_USER_FAIL:
    case SWITCH_USER_LOGOUT_SUCCESS:
      return {
        ...state,
        isSwitchedUser: false,
        loading: false,
        switchedUser: null,
      };
    case SWITCH_USER_LOADED:
      return {
        ...state,
        isSwitchedUser: true,
        loading: false,
        switchedUser: action.payload,
      };
    case SWITCH_USER_SUCCESS:
      return {
        ...state,
        isSwitchedUser: true,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
