import { apiClient } from "api/api";

import {
  GET_ERRORS,
  GET_FILES,
  GET_FILES_SET_LOADING,
  GET_NOTES,
  GET_NOTES_SET_LOADING,
  GET_SUCCESS,
  NOTES_LOADING_OFF,
  SAVE_NOTES,
} from "../types";
import { SUCCESS_INFO } from "utils/toastMessages";

export const getNotes = (procedureID, userID) => async (dispatch) => {
  try {
    dispatch({
      type: GET_NOTES_SET_LOADING,
    });

    const response = await apiClient.get(
      `/fk/${procedureID}/${userID}/get_notes`,
    );

    dispatch({
      payload: response.data,
      type: GET_NOTES,
    });
  } catch (err) {
    dispatch({ type: NOTES_LOADING_OFF });
    if (err.response.data) {
      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };
      dispatch({
        payload: errors,
        type: GET_ERRORS,
      });
    } else {
      console.log({ authErr: err });
    }
  }
};

export const addNotes =
  (procedureID, userID, submitedFormData) => async (dispatch) => {
    dispatch({ type: GET_NOTES_SET_LOADING });
    try {
      const response = await apiClient.post(
        `/fk/${procedureID}/${userID}/get_notes`,
        submitedFormData,
      );

      dispatch({
        payload: {
          msg: SUCCESS_INFO,
          status: response.status,
        },
        type: GET_SUCCESS,
      });

      dispatch({
        payload: response.data,
        type: SAVE_NOTES,
      });
    } catch (err) {
      if (err.response.data) {
        const errors = {
          msg: err.response.data,
          status: err.response.status,
        };
        dispatch({
          payload: errors,
          type: GET_ERRORS,
        });
      } else {
        console.log(err.response);
      }
    }
  };

export const delNotes =
  (procedureID, userID, delNoteID) => async (dispatch) => {
    dispatch({ type: GET_NOTES_SET_LOADING });

    try {
      const response = await apiClient.delete(
        `/fk/${procedureID}/${userID}/del_note/${delNoteID}`,
      );

      dispatch({
        payload: {
          msg: `Notatka o ID: ${delNoteID} została usunięta`,
          status: response.status,
        },
        type: GET_SUCCESS,
      });

      dispatch(getNotes(procedureID, userID));
    } catch (err) {
      if (err.response.data) {
        const errors = {
          msg: err.response.data,
          status: err.response.status,
        };
        dispatch({
          payload: errors,
          type: GET_ERRORS,
        });
      } else {
        console.log({ authErr: err });
      }
    }
  };

export const getFiles = (procedureID) => async (dispatch) => {
  try {
    dispatch({
      type: GET_FILES_SET_LOADING,
    });

    const response = await apiClient.get(
      `/fk/${procedureID}/get_files_history`,
      { useSwitchUserToken: true },
    );

    dispatch({
      payload: response.data,
      type: GET_FILES,
    });
  } catch (err) {
    if (err.response.data) {
      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };
      dispatch({
        payload: errors,
        type: GET_ERRORS,
      });
    } else {
      console.log({ authErr: err });
    }
  }
};
