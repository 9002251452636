import { apiClient } from "api/api";

import {
  CONFIRM_SD_DATA,
  CONFIRM_SD_DATA_SET_LOADING,
  GET_ERRORS,
  GET_SUCCESS,
} from "../types";

export const confirmSDData = (procedureID) => async (dispatch) => {
  try {
    dispatch({ type: CONFIRM_SD_DATA_SET_LOADING });

    const response = await apiClient.get(
      `/daneszcz/${procedureID}/confirm_specific_data`,
      { useSwitchUserToken: true },
    );
    dispatch({
      payload: response.status,
      type: CONFIRM_SD_DATA,
    });

    dispatch({
      payload: {
        msg: "Dane zostały przesłane do AOTMiT",
        status: response.status,
      },
      type: GET_SUCCESS,
    });
  } catch (err) {
    console.log(err.response, err.response.data);

    const errors = {
      msg: err.response.data,
      status: err.response.status,
    };
    dispatch({
      payload: errors,
      type: GET_ERRORS,
    });
  }
};
