import { apiClient } from "api/api";
import { saveAs } from "file-saver";

import { getReportRequest, postSpecificDataRequest } from "../types";
import {
  GET_ERRORS,
  GET_SD_FILES,
  GET_SD_FORM_FIELDS,
  GET_SUCCESS,
  UPLOAD_SD_FIELDS_SET_LOADING,
  UPLOAD_SD_FILES,
  UPLOAD_SD_FILES_FAIL,
  UPLOAD_SD_FILES_SET_LOADING,
} from "../types.js";
import { setRequestStatus } from "../utilActions";
import { SUCCESS_INFO } from "utils/toastMessages";

// get personalized excel
export const getSpecificDataFormFields = (procedureID) => async (dispatch) => {
  try {
    dispatch({ type: UPLOAD_SD_FIELDS_SET_LOADING });

    const response = await apiClient.get(
      `/daneszcz/${procedureID}/get_fields`,
      { useSwitchUserToken: true },
    );
    dispatch({
      payload: response.data,
      type: GET_SD_FORM_FIELDS,
    });
  } catch (err) {
    if (err.response) {
      console.log("SD_Error", err.response.data);
      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };

      dispatch({
        payload: errors,
        type: GET_ERRORS,
      });
    } else {
      console.log({ err });
    }
  }
};

// get specyfic data xlsx
export const getSecyficDataFiles = (procedureID) => async (dispatch) => {
  dispatch({ type: UPLOAD_SD_FILES_SET_LOADING });
  try {
    const response = await apiClient.get(
      `/daneszcz/${procedureID}/upload_specific_data`,
      { useSwitchUserToken: true },
    );
    dispatch({
      payload: response.data,
      type: GET_SD_FILES,
    });
  } catch (err) {
    if (err.response) {
      const errors = {
        msg: err.response.statusText,
        status: err.response.status,
      };

      dispatch({
        payload: errors,
        type: GET_ERRORS,
      });
      dispatch({
        type: UPLOAD_SD_FILES_FAIL,
      });
    } else {
      console.log({ err });
    }
  }
};

export const updateSpecyficDataFiles = (procedureID) => async (dispatch) => {
  try {
    const response = await apiClient.get(
      `/daneszcz/${procedureID}/upload_specific_data`,
      { useSwitchUserToken: true },
    );

    if (
      !response.data.results?.some((file) => file.czy_raport === "Oczekuje")
    ) {
      dispatch({
        payload: response.data,
        type: GET_SD_FILES,
      });
    }
  } catch (err) {
    if (err.response) {
      const errors = {
        msg: err.response.statusText,
        status: err.response.status,
      };

      dispatch({
        payload: errors,
        type: GET_ERRORS,
      });
      dispatch({
        type: UPLOAD_SD_FILES_FAIL,
      });
    } else {
      console.log({ err });
    }
  }
};

// upload specyfic data xlsx
export const uploadSecyficDataFiles =
  (procedureID, specyficDataFiles) => async (dispatch) => {
    dispatch({ type: UPLOAD_SD_FILES_SET_LOADING });
    dispatch(setRequestStatus(0, 100, postSpecificDataRequest));
    const config = { useSwitchUserToken: true };
    let partialSize = 0;
    let diffrence = 0;
    // Funkcja przy każdym pobranym kawałku raportu sprawdza jaki jest on duży, jeżeli jest większy niż 10% aktualizuje stan.
    config["onUploadProgress"] = (progressEvent) => {
      const fileSize = progressEvent.total;
      const loaded = progressEvent.loaded;
      diffrence = loaded - partialSize;
      let progress = Math.round((loaded / fileSize) * 100);
      if (partialSize >= 0.1 * fileSize) {
        dispatch(setRequestStatus(progress, 100, postSpecificDataRequest));
        partialSize = partialSize - diffrence;
      } else {
        partialSize = partialSize + diffrence;
      }
    };
    try {
      const response = await apiClient.post(
        `/daneszcz/${procedureID}/upload_specific_data`,
        specyficDataFiles,
        config,
      );
      dispatch({
        payload: {
          msg: SUCCESS_INFO,
          status: response.status,
        },
        type: GET_SUCCESS,
      });
      dispatch(setRequestStatus(null, null, postSpecificDataRequest));
      dispatch({
        payload: response.data,
        type: UPLOAD_SD_FILES,
      });
    } catch (err) {
      dispatch(setRequestStatus(null, null, postSpecificDataRequest));
      if (err.response) {
        console.log(err.response.data);
        const errors = {
          msg: err.response.data,
          status: err.response.status,
        };

        dispatch({
          payload: errors,
          type: GET_ERRORS,
        });
        dispatch({
          type: UPLOAD_SD_FILES_FAIL,
        });
      } else {
        console.log({ err });
      }
    }
  };

export const getReport = (id, path, procedureID) => async (dispatch) => {
  try {
    dispatch(setRequestStatus(0, 100, getReportRequest));
    const config = { useSwitchUserToken: true };
    let partialSize = 0;
    let diffrence = 0;
    // Funkcja przy każdym pobranym kawałku raportu sprawdza jaki jest on duży, jeżeli jest większy niż 10% aktualizuje stan.
    config["onDownloadProgress"] = (progressEvent) => {
      const fileSize = progressEvent.srcElement.getResponseHeader("file-size");
      const loaded = progressEvent.loaded;
      diffrence = loaded - partialSize;
      let progress = Math.round((loaded / fileSize) * 100);
      if (partialSize >= 0.1 * fileSize) {
        dispatch(setRequestStatus(progress, 100, getReportRequest));
        partialSize = partialSize - diffrence;
      } else {
        partialSize = partialSize + diffrence;
      }
    };
    const response = await apiClient.get(
      `/daneszcz/${procedureID}/report?report_id=${id}&path=${path}`,
      config,
    );
    dispatch(setRequestStatus(null, null, null));
    const byte_chars = [...atob(response.data)];
    const byte_numbers = byte_chars.map((ele, i) => ele.charCodeAt(0));
    const byte_array = new Uint8Array(byte_numbers);
    let blob = new Blob([byte_array], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "raport.xlsx");
  } catch {
    dispatch(setRequestStatus(null, null, null));
  }
};
//   };
//
//   const response = await axios.post(`/daneszcz/${procedureID}/wynik`, {"report_id":id,"path":path},configTokenSwitche));
//   let blob = new Blob([response.data], {
//     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//   });
//   saveAs(blob, "Szablon do nowych kont.xlsx");
// // };
