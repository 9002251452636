import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useParams } from "react-router-dom";

import { Card, Badge, Button, Col, Row } from "react-bootstrap";

import BreadcrumbContainer from "components/Breadcrumbs/BreadcrumbContainer";
import { Loading } from "components/Loading/loading";
import { getFAQ } from "actions/PanelInstructionsActions/ProceedingFAQActions";

const ProceedingFAQ = () => {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const breadcrumbLabelRef = useRef("");
  const categories = useSelector((state) => state.faqReducer.categories || []);

  useEffect(() => {
    if (categories && categories.length === 0) {
      dispatch(getFAQ());
    }
  }, [dispatch]);

  return (
    <>
      <BreadcrumbContainer breadcrumbLabel={breadcrumbLabelRef.current} />
      {categories.length === 0 ? (
        <Loading variant="info">
          <span className="m-2">Dane są pobierane z serwera...</span>
        </Loading>
      ) : (
        <Card>
          <Card.Body className="m-1 p-2">
            <Row>
              {categories
                .filter((el) => el.count > 0)
                .map((category, category_idx) => (
                  <Col key={category_idx} lg={3} md={4} sm={12}>
                    <Link
                      className="nav-link p-0"
                      to={`/instrukcja/FAQ/${category.slug}`}
                      aria-label={`Przejdź do kategorii FAQ: ${category.nazwa}`}
                      onClick={() => {
                        breadcrumbLabelRef.current = category.nazwa;
                      }}
                      tabIndex="-1"
                    >
                      <Button
                        className="mt-1 w-100"
                        variant={
                          category.slug === slug
                            ? "secondary"
                            : "outline-secondary"
                        }
                      >
                        <Row>
                          <Col sm={9}>
                            <h6>{category.nazwa}</h6>
                          </Col>
                          <Col sm={3}>
                            <Badge className="p-1" bg="success">
                              {category.count}
                            </Badge>
                          </Col>
                        </Row>
                      </Button>
                    </Link>
                  </Col>
                ))}
            </Row>
          </Card.Body>
        </Card>
      )}
      <main>
        <Outlet />
      </main>
    </>
  );
};

export default ProceedingFAQ;
