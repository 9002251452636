import {
  ADD_OPKS_ACCEPTANCE,
  CONFIRM_OPKS,
  GET_OPKS_ACCEPTANCE,
  OPKS_ACCEPTANCE_LOADING_OFF,
  OPKS_ACCEPTANCE_SET_LOADING,
} from "../../../actions/types.js";

const initialState = {
  error: null,
  loading: false,
  opk_accepted: false,
  opks: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_OPKS_ACCEPTANCE:
      return {
        ...state,
        loading: false,
        opks: action.payload.data,
      };
    case CONFIRM_OPKS:
      let opk_accepted_status = false;
      if (action.payload === 200) {
        opk_accepted_status = true;
      }
      return {
        ...state,
        loading: false,
        opk_accepted: opk_accepted_status,
      };
    case GET_OPKS_ACCEPTANCE:
      return {
        ...state,
        loading: false,
        opks: action.payload,
      };
    case OPKS_ACCEPTANCE_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case OPKS_ACCEPTANCE_LOADING_OFF:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export default reducer;
