export const AnalyticsFormFields = [
  {
    colSize: null,
    fieldData: [
      { dafault: null, fieldId: "id", fieldLabel: "id", fieldType: "hidden" },
    ],
  },
  {
    colSize: 2,
    fieldData: [
      {
        dafault: "",

        fieldcolSize: 6,
        fieldId: "billing_period_year",
        fieldLabel: "Okres rozliczeniowy - rok",
        fieldType: "number",
      },
      {
        dafault: "",

        fieldcolSize: 6,
        fieldId: "billing_period_month",
        fieldLabel: "Okres rozliczeniowy - miesiąc",
        fieldType: "number",
      },
    ],
  },
  {
    colSize: 9,
    fieldData: [
      {
        dafault: "",

        fieldcolSize: 3,
        fieldId: "account_number_5",
        fieldLabel: "Numer konta zespołu 5",
        fieldType: "text",
      },
      {
        dafault: "",

        fieldcolSize: 3,
        fieldId: "account_name_5",
        fieldLabel: "Nazwa konta zespołu 5",
        fieldType: "text",
      },
      {
        dafault: "",

        fieldcolSize: 3,
        fieldId: "account_number_4",
        fieldLabel: "Numer konta zespołu 4",
        fieldType: "text",
      },
      {
        dafault: "",

        fieldcolSize: 3,
        fieldId: "account_name_4",
        fieldLabel: "Nazwa konta zespołu 4",
        fieldType: "text",
      },
    ],
  },
  {
    colSize: 1,
    fieldData: [
      {
        dafault: "",
        fieldId: "cost_value",
        fieldLabel: "Wartość kosztu w zł",
        fieldType: "decimal",
      },
    ],
  },
];

export const CostCalculationFormFields = [
  {
    colSize: null,
    fieldData: [
      { dafault: null, fieldId: "id", fieldLabel: "id", fieldType: "hidden" },
    ],
  },
  {
    colSize: 1,
    fieldData: [
      {
        dafault: "",

        fieldcolSize: 6,
        fieldId: "billing_period_year",
        fieldLabel: "Typ Świadczenia",
        fieldType: "text",
      },
      {
        dafault: "",

        fieldcolSize: 6,
        fieldId: "billing_period_month",
        fieldLabel: "ID świadczenia",
        fieldType: "number",
      },
    ],
  },
  {
    colSize: 3,
    fieldData: [
      {
        dafault: "",

        fieldcolSize: 3,
        fieldId: "ilosc_prod",
        fieldLabel: "ID pozycji rozliczeniowej",
        fieldType: "number",
      },
      {
        dafault: "",

        fieldcolSize: 3,
        fieldId: "ilosc_prod",
        fieldLabel: "ID kontaktu",
        fieldType: "number",
      },
      {
        dafault: "",

        fieldcolSize: 3,
        fieldId: "ilosc_prod",
        fieldLabel: "ID instalacji świadczeniodawcy",
        fieldType: "text",
      },
      {
        dafault: "",

        fieldcolSize: 3,
        fieldId: "ilosc_prod",
        fieldLabel: "ID epizodu",
        fieldType: "number",
      },
    ],
  },
  {
    colSize: 5,
    fieldData: [
      {
        dafault: "",

        fieldcolSize: 2,
        fieldId: "ilosc_prod",
        fieldLabel: "Poczatek okresu",
        fieldType: "number",
      },
      {
        dafault: "",

        fieldcolSize: 2,
        fieldId: "ilosc_prod",
        fieldLabel: "Koniec okresu",
        fieldType: "number",
      },
      {
        dafault: "",

        fieldcolSize: 2,
        fieldId: "ilosc_prod",
        fieldLabel: "Kod zakresu",
        fieldType: "text",
      },
      {
        dafault: "",

        fieldcolSize: 2,
        fieldId: "ilosc_prod",
        fieldLabel: "Kod produktu sprawozdawanego",
        fieldType: "number",
      },
      {
        dafault: "",

        fieldcolSize: 2,
        fieldId: "ilosc_prod",
        fieldLabel: "Kod procedury",
        fieldType: "number",
      },
      {
        dafault: "",

        fieldcolSize: 2,
        fieldId: "ilosc_prod",
        fieldLabel: "Kod rozpoznania głównego",
        fieldType: "number",
      },
    ],
  },
  {
    colSize: 2,
    fieldData: [
      {
        dafault: "",

        fieldcolSize: 4,
        fieldId: "ilosc_prod",
        fieldLabel: "ID pacjenta sprawozdany do NFZ ",
        fieldType: "number",
      },
      {
        dafault: "",

        fieldcolSize: 4,
        fieldId: "ilosc_prod",
        fieldLabel: "Data rozpoczęcia realizacji danego produktu / świadczenia",
        fieldType: "text",
      },
      {
        dafault: "",

        fieldcolSize: 4,
        fieldId: "ilosc_prod",
        fieldLabel: "Data zakończenia realizacji danego produktu / świadczenia",
        fieldType: "number",
      },
    ],
  },
  {
    colSize: 1,
    fieldData: [
      {
        dafault: "",

        fieldcolSize: 6,
        fieldId: "billing_period_year",
        fieldLabel: "Numer rekordu ",
        fieldType: "text",
      },
      {
        dafault: "",

        fieldcolSize: 6,
        fieldId: "billing_period_year",
        fieldLabel: "uwagi",
        fieldType: "text",
      },
    ],
  },
];

export const UserProfileFormFields = [
  {
    fieldId: "swd_kod",
    fieldLabel: "Kod świadczeniodawcy:",
    fieldType: "text-sub-title-not-changeable",
    initialValue: "",
  },
  {
    fieldId: "swd_nazwa",
    fieldLabel: "Nazwa świadczeniodawcy:",
    fieldType: "text-sub-title-not-changeable",
    initialValue: "",
  },
  {
    fieldId: "swd_ulica",
    fieldLabel: "Ulica:",
    fieldType: "text-sub-title-not-changeable",
    initialValue: "",
  },
  {
    fieldId: "swd_miejscowosc",
    fieldLabel: "Miejscowość:",
    fieldType: "text-sub-title-not-changeable",
    initialValue: "",
  },
  {
    fieldId: "swd_kodpocztowy",
    fieldLabel: "Kod pocztowy:",
    fieldType: "text-sub-title-not-changeable",
    initialValue: "",
  },
  {
    // fieldId: "id",
    fieldLabel: "Dane osoby do kontaktu:",
    fieldType: "header",
  },
  {
    fieldId: "kontakt_imie",
    fieldLabel: "Imię:",
    fieldType: "text-sub-title",
    initialValue: "",
  },
  {
    fieldId: "kontakt_nazwisko",
    fieldLabel: "Nazwisko:",
    fieldType: "text-sub-title",
    initialValue: "",
  },
  {
    fieldId: "kontakt_email",
    fieldLabel: "Adres e-mail:",
    fieldType: "email-input",
    initialValue: "",
  },
  {
    fieldId: "kontakt_telefon",
    fieldLabel: "Telefon:",
    fieldType: "phone-number-input",
    initialValue: "",
  },
  {
    // fieldId: "id",
    fieldLabel: "Informacje dodatkowe:",
    fieldType: "header",
  },
  {
    fieldId: "ow_nfz",
    fieldLabel: "Numer oddziału wojewódzkiego NFZ:",
    fieldType: "text-sub-title-not-changeable",
    initialValue: "",
  },
  {
    fieldId: "wielkosc",
    fieldLabel: "Wielkość miejscowości:",
    fieldType: "city-size-select",
    initialValue: "",
  },
  {
    fieldId: "forma_dzialalnosci",
    fieldLabel: "Forma działalności:",
    fieldType: "activity-form-select",
    initialValue: "",
  },
  {
    fieldId: "dzialalnosc_dydakt",
    fieldLabel:
      "Czy szpital prowadzi działalność dydaktyczną w zakresie kształcenia pielęgniarek, położnych lub lekarzy?",
    fieldType: "didactic-activity-select",
    initialValue: false,
  },
  {
    fieldId: "ilosc_lozek",
    fieldLabel: "Ilość łóżek w podmiocie:",
    fieldType: "simple-title",
  },
  {
    fieldId: "uwagi",
    fieldLabel: "Uwagi:",
    fieldType: "comments",
    initialValue: "",
  },
];
