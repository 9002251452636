import { apiClient } from "api/api";

import {
  GET_ERRORS,
  GET_SUCCESS,
  GET_TAB1_FORM_DATA,
  SAVE_TAB1_FORM_DATA,
  TAB1_FORM_DATA_SET_LOADING,
  TAB1_LOADING_OFF,
} from "../types";
import { SUCCESS_AND_CHECK_ERRORS_INFO } from "utils/toastMessages";

export const getTab1FormData = (procedureID, opkID) => async (dispatch) => {
  try {
    dispatch({ type: TAB1_FORM_DATA_SET_LOADING });

    const response = await apiClient.get(
      `/fk/${procedureID}/opk/${opkID}/tab1edit/`,
      { useSwitchUserToken: true },
    );
    dispatch({
      payload: response.data,
      type: GET_TAB1_FORM_DATA,
    });
  } catch (err) {
    if (err.response) {
      console.log("tab1", err.response.data);
      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };
      dispatch({
        payload: errors,
        type: GET_ERRORS,
      });
    } else {
      console.log({ err });
    }
  }
};

export const saveTab1FormData =
  (procedureID, opkID, submitedFormData) => async (dispatch) => {
    dispatch({ type: TAB1_FORM_DATA_SET_LOADING });
    try {
      const response = await apiClient.patch(
        `/fk/${procedureID}/opk/${opkID}/tab1edit/`,
        submitedFormData,
        { useSwitchUserToken: true },
      );
      dispatch({
        payload: {
          msg: SUCCESS_AND_CHECK_ERRORS_INFO,
          status: response.status,
        },
        type: GET_SUCCESS,
      });

      dispatch({
        payload: response.data,
        type: SAVE_TAB1_FORM_DATA,
      });
    } catch (err) {
      console.log(err.response, err.response.data);
      dispatch({ type: TAB1_LOADING_OFF });
      dispatch({
        payload: err.response.data,
        type: GET_ERRORS,
      });
    }
  };
