import { getMaterials } from "actions/PanelInstructionsActions/MaterialsActions";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ListGroup } from "react-bootstrap";

const SlagSelectedFiles = () => {
  const dispatch = useDispatch();
  const { slug } = useParams();

  const material_list = useSelector(
    (state) => state.materialsReducer.material_list,
  );

  useEffect(() => {
    dispatch(getMaterials(slug));
  }, [dispatch, slug]);

  return (
    <ListGroup flush>
      {material_list.map((file_item, file_idx) => (
        <ListGroup.Item key={file_idx}>
          <a
            className={"text-muted"}
            href={file_item.plik}
          >{`${file_item.nazwa} - ${file_item.opis}`}</a>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

export default SlagSelectedFiles;
