import {
  GET_ERRORS,
  GET_PARTICIPATION_USERS,
  GET_PARTICIPATION_USERS_SET_LOADING,
  GET_SUCCESS,
} from "actions/types.js";
import { apiClient } from "api/api";

export const getProceedingUsers = (procedureID) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PARTICIPATION_USERS_SET_LOADING,
    });

    const response = await apiClient.get(
      `/restore-for-improvement/${procedureID}/users/`,
    );

    const mappedProceedingUserOptions = response.data.map((object) => ({
      label: object.username,
      value: object.id,
    }));

    dispatch({
      payload: mappedProceedingUserOptions,
      type: GET_PARTICIPATION_USERS,
    });
  } catch (err) {
    if (err.response.data) {
      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };
      dispatch({
        payload: errors,
        type: GET_ERRORS,
      });
    } else {
      console.log({ authErr: err });
    }
  }
};

export const restoreForImprovment =
  (procedureID, userID) => async (dispatch) => {
    try {
      const response = await apiClient.post(
        `/restore-for-improvement/${procedureID}/update_participation/`,
        { userID },
      );

      const info = {
        msg: response.data.detail,
        status: response.status,
      };

      dispatch({
        payload: info,
        type: GET_SUCCESS,
      });
    } catch (err) {
      if (err.response.data) {
        const errors = {
          msg: err.response.data,
          status: err.response.status,
        };
        dispatch({
          payload: errors,
          type: GET_ERRORS,
        });
      } else {
        console.log({ authErr: err });
      }
    }
  };

export const fetchSelectedUserData =
  (procedureID, userID) => async (dispatch) => {
    try {
      const response = await apiClient.get(
        `/restore-for-improvement/${procedureID}/details/?user_id=${userID}`,
      );

      return response.data;
    } catch (err) {
      if (err.response.data) {
        const errors = {
          msg: err.response.data,
          status: err.response.status,
        };
        dispatch({
          payload: errors,
          type: GET_ERRORS,
        });
      } else {
        console.error("Fetching selected user data error: ", err);
      }
    }
  };
