import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { switchUser } from "../../actions/switchUserActions";
import logo from "../../assets/img/aotm.png";

const SwitchUser = () => {
  const [formData, setFormData] = useState({ username: "" });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleFieldChange = (e) => {
    const fieldValue = e.target.value;
    const fieldIndex = e.target.id;

    setFormData((prevState) => {
      const prevLoginValues = { ...prevState };
      prevLoginValues[fieldIndex] = fieldValue;
      return prevLoginValues;
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(switchUser(formData));
    navigate("/");
  };

  return (
    <div className="flex-center">
      <Form onSubmit={(e) => handleSubmit(e)}>
        <Card className="login-card">
          <Card.Body className="p-2">
            <Container>
              <img alt="AOTMiT logo" className="login-img" src={logo} />
              <div className="mt-5 text-center">Przełącz Użytkownika</div>
              <Form.Control
                aria-label="Login"
                className="my-2"
                id="username"
                onChange={(e) => handleFieldChange(e)}
                placeholder="Login"
                type="text"
                value={formData["username"]}
              />
            </Container>
          </Card.Body>
          <Card.Footer>
            <Button type="submit" className="w-100">
              Przełącz
            </Button>
          </Card.Footer>
        </Card>
      </Form>
    </div>
  );
};

export default SwitchUser;
