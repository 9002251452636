import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { loadUser } from "../actions/auth";
import { loadSwitchedUser } from "../actions/switchUserActions";

const useInitializeUser = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, loading } = useSelector(
    (state) => state.authReducer,
  );

  useEffect(() => {
    if (!isAuthenticated && !loading) {
      if (localStorage.getItem("auth_token")) {
        dispatch(loadUser());
        if (localStorage.getItem("switch_user_token")) {
          dispatch(loadSwitchedUser());
        }
      }
    }
  }, [dispatch, isAuthenticated, loading]);
};

export default useInitializeUser;
