import { apiClient } from "api/api";

import {
  GET_ERRORS,
  SWITCH_USER_FAIL,
  SWITCH_USER_LOADED,
  SWITCH_USER_LOGOUT_SUCCESS,
  SWITCH_USER_SUCCESS,
  CLEAR_PROCEEDINGS,
  CLEAR_OPK_USER_INFO,
  LANDING_SET_LOADING,
  VIEW_CONTROLLER_SET_LOADING,
  OPK_LIST_DATA_SET_LOADING,
  CLEAR_SPECIFIC_DATA_VIEW_CONTROLLER_DATA,
  CLEAR_SPECIFIC_DATA_FILES_AND_FIELDS,
} from "../actions/types";

const clearProceedingsData = (dispatch) => {
  dispatch({ type: CLEAR_PROCEEDINGS });
  dispatch({ type: VIEW_CONTROLLER_SET_LOADING });
  dispatch({ type: OPK_LIST_DATA_SET_LOADING });
  dispatch({ type: CLEAR_SPECIFIC_DATA_VIEW_CONTROLLER_DATA });
  dispatch({ type: CLEAR_SPECIFIC_DATA_FILES_AND_FIELDS });
};

export const switchUser = (formData) => async (dispatch) => {
  dispatch({ type: LANDING_SET_LOADING });

  try {
    const response = await apiClient.post("/auth/switch_user/", formData);
    localStorage.setItem("switch_user_token", response.data.access);
    localStorage.setItem("switch_user_refresh", response.data.refresh);
    dispatch({
      payload: response.data,
      type: SWITCH_USER_SUCCESS,
    });
    clearProceedingsData(dispatch);
    dispatch(loadSwitchedUser());
  } catch (err) {
    const errors = {
      msg: err.response.data,
      status: err.response.status,
    };
    dispatch({
      payload: errors,
      type: GET_ERRORS,
    });

    dispatch({
      type: SWITCH_USER_FAIL,
    });
  }
};

export const loadSwitchedUser = () => async (dispatch) => {
  if (localStorage.getItem("switch_user_token")) {
    try {
      const response = await apiClient.get(`/auth/users/me/`, {
        useSwitchUserToken: true,
      });

      dispatch({
        payload: response.data,
        type: SWITCH_USER_LOADED,
      });
    } catch (err) {
      if (err.response.statusText === "Unauthorized") {
        console.log("switchuserLoad: err ", err.response.data);

        if (err.response.data.detail === "Niepoprawny token.") {
          dispatch({
            type: SWITCH_USER_LOGOUT_SUCCESS,
          });
        }
      } else if (err.response) {
        const errors = {
          msg: err.response.data,
          status: err.response.status,
        };
        console.log(errors, err.response);
        dispatch({
          payload: errors,
          type: GET_ERRORS,
        });
      } else {
        console.log({ switchUserAuth: err });
      }
    }
  }
};

export const logoutSwichedUser = () => async (dispatch) => {
  try {
    const refresh = localStorage.getItem("switch_user_refresh");
    await apiClient.post("/auth/logout/", {
      refresh,
      useSwitchUserToken: true,
    });
  } finally {
    localStorage.removeItem("switch_user_token");
    localStorage.removeItem("switch_user_refresh");
    dispatch({ type: SWITCH_USER_LOGOUT_SUCCESS });
    dispatch({ type: CLEAR_OPK_USER_INFO });
    clearProceedingsData(dispatch);
  }
};
