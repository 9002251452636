import {
  GET_OPK_USER_INFO,
  GET_OPK_USER_PROFILE,
  OPK_USER_INFO_SET_LOADING,
  OPK_USER_PROFILE_SET_LOADING,
  OPK_USER_PROFILE_SET_FULFILLED,
} from "../../actions/types.js";

const initialState = {
  loading: false,
  opk_User: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_OPK_USER_INFO:
      return {
        ...state,
        loading: false,
        opk_User: action.payload,
      };
    case GET_OPK_USER_PROFILE:
      return {
        ...state,
        loading: false,
        opk_User: action.payload,
      };
    case OPK_USER_INFO_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case OPK_USER_PROFILE_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case OPK_USER_PROFILE_SET_FULFILLED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
