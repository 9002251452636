import {
  ADD_OPKS_NEW_LIST,
  CONFIRM_OPKS_SWD,
  GET_OPKS_NEW_LIST,
  OPKS_NEW_LIST_SET_LOADING,
  OPKS_NEW_LIST_LOADING_OFF,
} from "../../actions/types.js";

const initialState = {
  loading: false,
  opk_accepted: [],
  opk_confirmed_swd: false,
  opk_rejected: [],
  opk_sent_for_improvement: [],
  opks: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_OPKS_NEW_LIST:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case CONFIRM_OPKS_SWD:
      let opk_confirmed_status = false;
      if (action.payload === 200) {
        opk_confirmed_status = true;
      }
      return {
        ...state,
        loading: false,
        opk_confirmed_swd: opk_confirmed_status,
      };
    case GET_OPKS_NEW_LIST:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case OPKS_NEW_LIST_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case OPKS_NEW_LIST_LOADING_OFF:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
