import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

import { CLEAR_ALERTS } from "../actions/types";

const useHandleAlerts = () => {
  const dispatch = useDispatch();
  const alerts = useSelector((state) => state.alertsReducer);

  useEffect(() => {
    if (!alerts) return;

    if (alerts.alertType === "success") {
      toast.success(alerts.msg, {
        id: alerts.msg.toLowerCase().replace(/[ .]/g, ""),
      });
    } else if (alerts.alertType === "error") {
      toast.error(alerts.msg, {
        id: alerts.msg.toLowerCase().replace(/[ .]/g, ""),
      });
    }

    dispatch({ type: CLEAR_ALERTS });
  }, [alerts, dispatch]);
};

export default useHandleAlerts;
