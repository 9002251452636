import * as yup from "yup";

const noValuesInRow = (obj) => {
  return !Object.values(obj).some((value) => Boolean(value));
};

const isAccountNumberUnique = (value, allValues) => {
  if (value) {
    const accountNumbers = allValues.map((item) => item.nr_konta);
    const count = accountNumbers.filter((number) => number === value).length;
    return count === 1;
  }
  return true;
};

export const opkValidationSchema = yup.object({
  data: yup.array().of(
    yup.object({
      id: yup.number().nullable(),
      nr_konta: yup
        .string()
        .test(
          "is-required",
          "Numer konta OPK jest wymagany.",
          function (value) {
            return value || noValuesInRow(this.parent);
          },
        )
        .test(
          "is-unique",
          "Powtarzający się numer konta OPK (więcej niż 1 OPK z tym samym numerem).",
          function (value) {
            return isAccountNumberUnique(value, this.options.context.data);
          },
        )
        .nullable(),
      nazwa_opk: yup
        .string()
        .test("is-required", "Nazwa OPK jest wymagana.", function (value) {
          return value || noValuesInRow(this.parent);
        })
        .nullable(),
      kod_resortowy: yup
        .string()
        .when(["opk_niemedyczny", "srk_code"], {
          is: (opk_niemedyczny, srk_code) =>
            !opk_niemedyczny &&
            srk_code &&
            ![520, 525, 527, 529, 535, 545, 550].includes(
              Number(srk_code.trim()),
            ),
          then: (field) =>
            field.required("Kod resortowy dla OPKów medycznych jest wymagany."),
        })
        .nullable(),
      rok: yup
        .string()
        .matches(/^\d{4}$/, "Rok (RRRR) jest nieprawidłowy.")
        .test("is-required", "Rok (RRRR) jest wymagany.", function (value) {
          return value || noValuesInRow(this.parent);
        })
        .nullable(),
      srk_code: yup
        .string()
        .test(
          "is-required",
          "Kod funkcji SRK jest wymagany.",
          function (value) {
            return value || noValuesInRow(this.parent);
          },
        )
        .nullable(),
      overall_costs: yup
        .string()
        .matches(
          /^[0-9\s,\.]*$/,
          "Koszty całkowite OPK są nieprawidłowe / ujemne.",
        )
        .test(
          "is-required",
          "Koszty całkowite OPK są wymagane.",
          function (value) {
            return value || noValuesInRow(this.parent);
          },
        )
        .nullable(),
      number_of_months: yup
        .string()
        .matches(
          /^(0?[0-9]|1[0-2])$/,
          "Liczba miesięcy funkcjonowania OPK (0-12) jest nieprawidłowa.",
        )
        .test(
          "is-required",
          "Liczba miesięcy funkcjonowania OPK (0-12) jest wymagana.",
          function (value) {
            return value || noValuesInRow(this.parent);
          },
        )
        .nullable(),
      opk_niemedyczny: yup.boolean().nullable(),
    }),
  ),
});

export const tab2ValidationSchema = yup.object({
  data: yup.array().of(
    yup.object({
      kod_zakresu: yup.string(),
      kod_produktu: yup.string(),
      ilosc_prod: yup
        .string()
        .matches(/^\d+$/, "Liczba produktów jednostkowych jest nieprawidłowa."),
    }),
  ),
});

export const tab3ValidationSchema = yup.object({
  data: yup.array().of(
    yup.object({
      nazwa_sr_trw: yup.string(),
      kat_sr_trw: yup.string(),
      zamortyzowany: yup.string(),
      najem_dzierz: yup.string(),
      rok_nabycia: yup
        .string()
        .matches(/^\d{4}$/, "Rok (RRRR) jest nieprawidłowy."),
      wart_pocz: yup
        .string()
        .matches(
          /^\d+(\.\d+)?$/,
          "Wartość początkowa środka trwałego i WNiP jest nieprawidłowa.",
        ),
      ilosc_sztuk: yup
        .string()
        .matches(
          /^\d+(\.\d+)?$/,
          "Ilość sztuk środków trwałych jest nieprawidłowa.",
        ),
      miesiace_funk: yup
        .string()
        .matches(
          /^\d+$/,
          "Liczba miesięcy funkcjonowania danego środka trwałego i WNIP w okresie sprawozdawczym jest nieprawidłowa.",
        ),
      roczny_koszt_utrz: yup
        .string()
        .matches(
          /^\d+(\.\d+)?$/,
          "Koszt utrzymania środka trwałego i WNIP w okresie sprawozdawczym jest nieprawidłowy.",
        ),
      roczny_koszt_amort: yup
        .string()
        .matches(
          /^\d+(\.\d+)?$/,
          "Koszt amortyzacji środka trwałego i WNIP za okres sprawozdawczy jest nieprawidłowy.",
        ),
      wysokosc_dotacji: yup
        .string()
        .matches(/^\d+(\.\d+)?$/, "Wysokość dotacji jest nieprawidłowa."),
    }),
  ),
});

export const analyticsValidationSchema = yup.object({
  data: yup.array().of(
    yup.object({
      nr_konta: yup.string(),
      nazwa_konta: yup.string(),
      koszt_roczny: yup
        .string()
        .matches(
          /^\d+(\.\d+)?$/,
          "Koszt w okresie sprawozdawczym jest nieprawidłowy.",
        ),
    }),
  ),
});

export const userValidationSchema = yup.object().shape({
  kontakt_imie: yup.string().required("Imię jest wymagane"),
  kontakt_nazwisko: yup.string().required("Nazwisko jest wymagane"),
  kontakt_email: yup
    .string()
    .required("Adres e-mail jest wymagany")
    .email("Niepoprawny adres e-mail"),
  kontakt_telefon: yup
    .string()
    .trim()
    .required("Numer telefonu jest wymagany")
    .matches(
      /^(?:\+)?(?:(?:wew\.)|[0-9\s\-\(\)])+$/i,
      "Podany numer telefonu jest niepoprawny",
    ),
  wielkosc: yup.string().required("Wielkość miejscowości jest wymagana"),
  forma_dzialalnosci: yup.string().required("Forma działalności jest wymagana"),
  ilosc_lozek: yup.number().required("Ilość łóżek jest wymagana"),
  uwagi: yup.string(),
});
