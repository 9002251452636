import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Navigate } from "react-router-dom";

import { Button } from "react-bootstrap";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getControllerData } from "../../actions/opkViewsControllerActions";
import { Loading } from "../../components/Loading/loading";
import CardInfo from "components/Cards/CardInfo";

const OpkViewController = () => {
  const { procedureID } = useParams();
  const dispatch = useDispatch();

  const controllerData = useSelector(
    (state) => state.opkViewControllerReducer.controllerData,
  );
  const form_fields = useSelector(
    (state) => state.opkFormFieldsReducer.form_fields,
  );
  const loading = useSelector(
    (state) => state.opkViewControllerReducer.loading,
  );
  const isSwitchedUser = useSelector(
    (state) => state.switchUserReducer.isSwitchedUser,
  );

  useEffect(() => {
    dispatch(getControllerData(procedureID));
  }, [dispatch, procedureID]);

  const renderInfoCard = (title, description, icon = false) => (
    <CardInfo
      aotmitLogo
      bodyTitle={title}
      description={description}
      footerActions={
        <Button
          className="btn-round"
          href="/"
          variant="outline-success"
          size="sm"
        >
          Strona główna
        </Button>
      }
      footerInfo={
        icon && (
          <FontAwesomeIcon
            className="text-success"
            icon={faCheckCircle}
            size="2x"
          />
        )
      }
      imageHeader={require("../../assets/img/zdjecie_05.jpg")}
    />
  );

  const content_controller = (controllerData, form_fields) => {
    if (Number(procedureID) === Number(controllerData?.proceeding?.id)) {
      if (controllerData.opk_list_veryfication_status === true) {
        if (
          form_fields.declaration_require === true &&
          controllerData.get_statement === false
        ) {
          return renderInfoCard(
            "Dziękujemy za wypełnienie listy OPK.",
            "Uprzejmie prosimy o odesłanie oświadczenia podmiotu. Po otrzymaniu oświadczenia będą Państwo mogli przekazać dane.",
          );
        } else {
          return renderInfoCard(
            "Lista OPK została przesłana do Agencji z prośbą o weryfikację.",
            "Po zakończeniu weryfikacji zostanie wysłana do Państwa wiadomość z prośba o ponowne zalogowanie się na stronie.",
          );
        }
      } else if (controllerData.opk_list_veryfication_status === false) {
        if (
          form_fields.declaration_require === true &&
          controllerData.get_statement === false
        ) {
          return renderInfoCard(
            "Dziękujemy za wypełnienie listy OPK.",
            "Uprzejmie prosimy o odesłanie oświadczenia podmiotu. Po otrzymaniu oświadczenia będą Państwo mogli przekazać dane.",
          );
        } else {
          return <Navigate to={`/fk/${procedureID}/opk`} replace />;
        }
      } else if (controllerData.opk_list_veryfication_status === null) {
        return <Navigate to={`/fk/${procedureID}/opk_swd`} replace />;
      }
    }
  };

  if (loading) {
    return (
      <Loading variant="success">
        <span className="m-2">Dane są pobierane z serwera...</span>
      </Loading>
    );
  } else {
    if (!isSwitchedUser && controllerData.verification_status === 1) {
      return renderInfoCard(
        "Dane postępowania zostały przesłane do weryfikacji.",
        "Czasowo możliwość przeglądania strony została wyłączona, zostaną Państwo poinformowani drogą mailową o zmianie statusu weryfikacji.",
      );
    } else {
      return <>{content_controller(controllerData, form_fields)}</>;
    }
  }
};

export default OpkViewController;
