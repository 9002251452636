const Complaints = () => {
  return (
    <>
      <h3>Skargi i odwołania</h3>
      <p>Na niedotrzymanie tych terminów oraz na odmowę realizacji żądania można złożyć skargę do organu nadzorującego pocztą lub drogą elektroniczną na adres:</p>
      <ul>
        <li>Organ nadzorujący: 
          <a 
            href="https://www.rpo.gov.pl/content/jak-zglosic-sie-do-rzecznika-praw-obywatelskich"
            target="_blank"
            rel="noopener"
          >
            Rzecznik Praw Obywatelskich
          </a>
        </li>
        <li>Adres: Biuro Rzecznika Praw Obywatelskich al. Solidarności 77, 00-090 Warszawa</li>
        <li>E-mail: <a href="mailto:biurorzecznika@brpo.gov.pl">biurorzecznika@brpo.gov.pl</a></li>
        <li>Telefon: +48 22 55 17 700</li>
      </ul>
    </>
  )
}

export default Complaints