import { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import { saveAs } from "file-saver";
import { Button, Form, ProgressBar, Table } from "react-bootstrap";

import {
  getAllExcelFilesWithNewUsers,
  getAllProceedings,
  getExcelsSummary,
  getExcelsTemplate,
  postExeclFileWithNewUsers,
} from "../../actions/PanelDashboardActions/ParticipationRegisterActions";
import { getNewUsersSummaryRequest } from "../../actions/types";
import ProceedingsSelect from "../../components/Selects/ProceedingsSelect";
import { Loading } from "../../components/Loading/loading";
import SpinnerWithText from "../../components/Loading/SpinnerWithText";

const ParticipationRegister = () => {
  const [selectedProceeding, setSelectedProceeding] = useState(null);
  const fileInputRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      selectedFile: null,
    },
    onSubmit: (values, { setFieldValue }) => {
      dispatch(
        postExeclFileWithNewUsers(
          values.selectedFile,
          selectedProceeding.value,
        ),
      );

      setFieldValue("selectedFile", null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    },
  });

  const dispatch = useDispatch();

  const { alreadyLoaded, requestType, totalSize } = useSelector(
    (state) => state.utilityReducer,
  );
  const { excel_file_list, loading_post_excel, loading } = useSelector(
    (state) => state.participationRegisterReducer,
  );

  useEffect(() => {
    dispatch(getAllProceedings());
  }, [dispatch]);

  const downloadFile = (file, filename) => {
    const byte_chars = [...atob(file)];
    const byte_numbers = byte_chars.map((ele, i) => ele.charCodeAt(0));
    const byte_array = new Uint8Array(byte_numbers);
    let blob = new Blob([byte_array], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, filename);
  };

  const handleChangeChoosenProceeding = (value) => {
    setSelectedProceeding(value);
    dispatch(getAllExcelFilesWithNewUsers(value.value));
  };

  return (
    <>
      <div className="head-xl-container">
        <h3>Dodawanie nowych kont oraz uczestnictwa</h3>
        <p className="mb-0">
          Widok służy do dodawania uczestnict w wybranym postępowaniu, za pomocą
          importu pliku
        </p>
      </div>

      <main className="main-xl-container">
        <>
          <ProceedingsSelect
            selectValue={selectedProceeding}
            onChangeFn={handleChangeChoosenProceeding}
          />
          <Form.Text>
            Wybierz postępowanie aby wgrać plik z danymi świadczeniodawców w nim
            uczestniczących
          </Form.Text>
          {selectedProceeding && (
            <>
              <Form className="mt-3">
                Wybierz plik do importu
                <div className="d-flex">
                  <Form.Control
                    id="exampleFile"
                    label={
                      formik.values.selectedFile &&
                      formik.values.selectedFile.name
                        ? formik.values.selectedFile.name
                        : ""
                    }
                    name="file"
                    onChange={(e) =>
                      formik.setFieldValue("selectedFile", e.target.files[0])
                    }
                    ref={fileInputRef}
                    type="file"
                  />
                  {formik.values.selectedFile && (
                    <Button
                      className="px-2 ms-2 text-nowrap"
                      onClick={(event) => {
                        event.preventDefault();
                        formik.handleSubmit();
                      }}
                      size="sm"
                      type="button"
                      variant="info"
                    >
                      <i className="fa fa-upload" /> Prześlij plik
                    </Button>
                  )}
                </div>
              </Form>
              <div className="mt-3 justify-content-end">
                <Button
                  onClick={() => {
                    dispatch(getExcelsSummary(selectedProceeding.value));
                  }}
                  variant="secondary"
                >
                  {requestType === getNewUsersSummaryRequest && totalSize
                    ? "Trwa pobieranie Podsumowanie"
                    : "Pobierz Podsumowanie"}
                  {requestType === getNewUsersSummaryRequest && totalSize && (
                    <ProgressBar now={alreadyLoaded} />
                  )}
                </Button>
                <Button
                  className="ms-2"
                  onClick={() => {
                    dispatch(getExcelsTemplate(selectedProceeding.value));
                  }}
                  variant="secondary"
                >
                  Szablon pliku
                </Button>
              </div>
            </>
          )}
        </>

        {loading_post_excel && (
          <Loading variant="info" fullScreen={true}>
            <span className="m-2">
              <p>Trwa przesyłanie pliku. Proszę czekać...</p>
            </span>
          </Loading>
        )}
        <Table className="my-5" responsive>
          <thead>
            <tr>
              <th className="text-center">#</th>
              <th>Nazwa pliku</th>
              <th>Czy import się powiódł?</th>
              <th>Liczba dodanych użytkowników</th>
              <th>Użytkownicy z ponownie nadanym dostępem</th>
              <th>Liczba użytkowników których nie udało się zaimportować</th>
              <th>Błąd importu</th>
              <th className="text-right">Actions</th>
            </tr>
          </thead>
          {selectedProceeding && (
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={8}>
                    <SpinnerWithText />
                  </td>
                </tr>
              ) : excel_file_list.length === 0 ? (
                <tr>
                  <td colSpan={8} className="text-center text-muted">
                    Żadne pliki nie zostały jeszcze przesłane.
                  </td>
                </tr>
              ) : (
                excel_file_list.map((excelFile, row_nr) => (
                  <tr key={row_nr}>
                    <td className="text-center">{row_nr + 1}</td>
                    <td>{excelFile.filename}</td>
                    <td>
                      {excelFile.bledy === "" ? (
                        <i className="fas fa-check-circle" />
                      ) : (
                        <i className="fas fa-times-circle" />
                      )}
                    </td>
                    <td>{excelFile.liczba_nowych_userow}</td>
                    <td>{excelFile.liczba_ponownie_ndanych_dostepow}</td>
                    <td className="text-danger">{excelFile.liczba_bledow}</td>
                    <td>{excelFile.bledy}</td>
                    <td className=" td-actions text-right">
                      <Button
                        className="px-2"
                        onClick={() => {
                          downloadFile(excelFile.excel, excelFile.filename);
                        }}
                        size="sm"
                        type="button"
                        variant="info"
                      >
                        <i className="fa fa-user" /> Pobierz plik
                      </Button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          )}
        </Table>
      </main>
    </>
  );
};

export default ParticipationRegister;
