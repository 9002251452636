import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Navigate } from "react-router-dom";
import {
  faCog,
  faInfoCircle,
  faExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  Button,
  Card,
  Col,
  Collapse,
  ListGroup,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";

import {
  addOpksNewList,
  confirmOpkSwd,
  getOpksNewList,
} from "../../actions/opkNewListActions";
import { Loading } from "../../components/Loading/loading";
import { SmalModal } from "../../components/Modal/SmalModal";
import { OpkNewListFormFields } from "./OpkForms/FormSettings";
import GeneratedForm from "components/GeneratedForm/GeneratedForm";
import BreadcrumbContainer from "components/Breadcrumbs/BreadcrumbContainer";
import SwitchUserMenu from "./StaffUserViews/switchUserMenu";
import { opkValidationSchema } from "utils/validationSchemas";
import useModal from "hooks/useModal";
import CustomModal from "components/Modal/CustomModal";

const OpkNewList = () => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [sideBarBtn, setSideBarBtn] = useState(true);
  const [collapses, setCollapses] = useState([]);
  const { isOpen, modalContent, openModal, closeModal } = useModal();

  const { procedureID } = useParams();
  const dispatch = useDispatch();

  const isSwitchedUser = useSelector(
    (state) => state.switchUserReducer.isSwitchedUser,
  );
  const {
    loading,
    opk_accepted,
    opk_confirmed_swd,
    opk_rejected,
    opk_sent_for_improvement,
  } = useSelector((state) => state.opkNewList);

  useEffect(() => {
    dispatch(getOpksNewList(procedureID));
  }, [dispatch, procedureID]);

  const changeCollapse = (collapse) => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter((prop) => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };

  const handleSideBarToggler = () => setShowSidebar((prevState) => !prevState);

  const handleModalShow = () => setShowModal((prevState) => !prevState);

  const handleAccept = () => {
    handleModalShow();
    dispatch(confirmOpkSwd(procedureID));
  };

  const handleDisabeledSideBarButton = useCallback(
    (containsErrors = false) => {
      setSideBarBtn(!opk_sent_for_improvement.length || containsErrors);
    },
    [opk_sent_for_improvement],
  );

  const saveData = (submitedFormData) => {
    submitedFormData.forEach((row) => {
      row.overall_costs = String(row.overall_costs)
        .replace(/\s/g, "")
        .replace(",", ".");
    });

    dispatch(addOpksNewList(procedureID, submitedFormData)).then((response) => {
      if (response?.status === 200) {
        const savedOpks = response?.data?.opk_sent_for_improvement || [];
        const validationErrorsExist = savedOpks.some((item) => item.bledy);

        openModal({
          title: "Dane zostały zapisane.",
          message:
            validationErrorsExist &&
            "W wyniku walidacji wykryto dodatkowe błędy, które zostały wyświetlone w kolumnie 'BŁĘDY'. Prosimy o weryfikację.",
          children:
            !validationErrorsExist && savedOpks.length ? (
              <>
                <Button variant="outline-success" onClick={handleModalShow}>
                  Prześlij do AOTMiT
                </Button>
                <Button variant="danger" onClick={closeModal}>
                  Anuluj
                </Button>
              </>
            ) : null,
        });
      }
    });
  };

  const show_list_opk = (opk_list_to_show) => {
    if (opk_list_to_show.length) {
      return (
        <>
          <ListGroup variant="flush">
            {opk_list_to_show.map((opk_item, item_idx) => (
              <ListGroup.Item
                className="padding-small font-small"
                key={item_idx}
              >
                <Row>
                  <Col xs={2}>{opk_item.nr_konta}</Col>
                  <Col xs={4}>{opk_item.nazwa_opk}</Col>
                  <Col>{opk_item.kod_resortowy}</Col>
                  <Col>{opk_item.rok}</Col>
                  <Col className="text-danger text-center">
                    {opk_item.uwagi && (
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 0, hide: 0 }}
                        overlay={
                          <Tooltip id={`opk-comments-${opk_item.id}`}>
                            {opk_item.uwagi}
                          </Tooltip>
                        }
                      >
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          id={`opk-comments-${opk_item.id}`}
                        />
                      </OverlayTrigger>
                    )}
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </>
      );
    }
  };

  if (opk_confirmed_swd) return <Navigate to={`/fk/${procedureID}`} />;

  return (
    <>
      <div className="fixedPlugin" tabIndex="0" onClick={handleSideBarToggler}>
        <FontAwesomeIcon icon={faCog} size="2x" />
      </div>
      <Row>
        {showSidebar && (
          <Col className="p-0" xl={1} xs={2}>
            <div className="dropdownMenu">
              <Button
                variant="outline-success"
                disabled={sideBarBtn}
                onClick={handleModalShow}
                className="w-100"
              >
                Prześlij do AOTMiT
              </Button>
              {isSwitchedUser && <SwitchUserMenu procedureID={procedureID} />}
            </div>
          </Col>
        )}
        <Col>
          <div className="head-container">
            <BreadcrumbContainer />
            <div className="border-box">
              <div className="border-box-title text-warning">
                UWAGA <FontAwesomeIcon icon={faExclamation} size="1x" />
              </div>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <strong>LISTA OPK Instrukcja</strong>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ListGroup numbered variant="flush">
                      <ListGroup.Item>
                        Należy wybrać liczbę OPK w polu obok przycisku
                        "Dodaj/usuń pola".
                      </ListGroup.Item>
                      <ListGroup.Item>
                        Formularz pozwala na wklejenie listy OPK z Excela - w
                        tym celu należy stanąć w pierwszym górnym wierszu i
                        wkleić wcześniej zaznaczoną całą listę OPK, ze
                        wszystkimi 7 kolumnami.
                      </ListGroup.Item>
                      <ListGroup.Item>
                        Po wpisaniu/wklejeniu pełnej listy OPK należy poprawić
                        ewentualne błędy – wyświetlą się ponad tabelą.
                      </ListGroup.Item>
                      <ListGroup.Item>
                        Po ewentualnym poprawieniu błędów należy kliknąć
                        przycisk <strong>ZAPISZ</strong>, który znajduje się u
                        góry formularza - zostanie uruchomione szczegółowe
                        sprawdzenie danych.
                      </ListGroup.Item>
                      <ListGroup.Item>
                        Po ukończeniu sprawdzania danych pojawi się okienko z
                        informacją o wyniku walidacji. Jeżeli w wyniku walidacji
                        stwierdzone zostaną błędy, zostaną one wyświetlone w
                        kolumnie <strong>BŁĘDY</strong> w określonym OPK.
                      </ListGroup.Item>
                      <ListGroup.Item>
                        Jeśli lista OPK jest poprawna, należy wybrać przycisk{" "}
                        <strong>PRZEŚLIJ DO AOTMiT</strong>, w celu jej
                        weryfikacji przez pracowników AOTMiT.
                      </ListGroup.Item>
                      <ListGroup.Item>
                        Prosimy o cierpliwość w oczekiwaniu na akceptację listy
                        OPK przez pracownika AOTMiT. Dopiero po zatwierdzeniu
                        listy OPK, otrzymacie Państwo wiadomość mailową wraz z
                        instrukcją pobrania i wgrania pliku FK.
                      </ListGroup.Item>
                    </ListGroup>
                    <span>
                      <strong>
                        Uwaga! W przypadku, gdy dla tego samego numeru regon
                        funkcjonuje więcej niż 1 kod oddziałowy świadczeniodawcy
                        nadawany przez OW NFZ (np. kontrakty w kilku oddziałach
                        NFZ), prosimy o kontakt mailowy na adres
                        dane-kosztowe@aotm.gov.pl.
                      </strong>
                    </span>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            {/* Accepted OPK */}
            {!loading && opk_accepted.length > 0 && (
              <>
                <Card className="no-transition mt-3 mb-0">
                  <Card.Header
                    aria-expanded={collapses.includes(1)}
                    className="card-collapse padding-small"
                    id="rejected-opk"
                    onClick={(e) => {
                      e.preventDefault();
                      changeCollapse(1);
                    }}
                    role="tab"
                  >
                    <h6 className="mb-0 panel-title text-aotmit">
                      Zaakceptowane konta OPK
                    </h6>
                  </Card.Header>
                  <Collapse in={collapses.includes(1)}>
                    <Card.Body>{show_list_opk(opk_accepted)}</Card.Body>
                  </Collapse>
                </Card>
              </>
            )}
            {/* Rejected OPK */}
            {!loading && opk_rejected.length > 0 && (
              <>
                <Card className="no-transition mb-1">
                  <Card.Header
                    aria-expanded={collapses.includes(2)}
                    className="card-collapse padding-small"
                    id="rejected-opk"
                    onClick={(e) => {
                      e.preventDefault();
                      changeCollapse(2);
                    }}
                    role="tab"
                  >
                    <h6 className="mb-0 panel-title text-aotmit">
                      Odrzucone konta OPK
                    </h6>
                  </Card.Header>
                  <Collapse in={collapses.includes(2)}>
                    <Card.Body>
                      {show_list_opk(opk_rejected, "Odrzucone konta OPK")}
                    </Card.Body>
                  </Collapse>
                </Card>
              </>
            )}
          </div>
          <main className="main-container">
            {loading ? (
              <Loading variant="danger">
                <span className="m-2">
                  Wczytywanie danych. Proszę czekać...
                </span>
              </Loading>
            ) : (
              <GeneratedForm
                dataSubmit={saveData}
                formFields={OpkNewListFormFields}
                hasIndexes={true}
                opkData={opk_sent_for_improvement}
                validationSchema={opkValidationSchema}
                isSendingBlocked={true}
                openModal={openModal}
                closeModal={closeModal}
                handleDisabeledSideBarButton={handleDisabeledSideBarButton}
              />
            )}
          </main>
        </Col>
      </Row>
      <CustomModal
        show={isOpen}
        onHide={closeModal}
        modalContent={modalContent}
      />
      <SmalModal
        handleAccept={handleAccept}
        handleClose={handleModalShow}
        modalBodyText={
          <>
            <p className="text-center">
              Czy na pewno chcesz przesłać dane do AOTMiT?
            </p>
            <p className="text-center">
              <i>Upewnij się, że zapisałeś dane formularza.</i>
            </p>
          </>
        }
        show={showModal}
      />
    </>
  );
};

export default OpkNewList;
