import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { Button } from "react-bootstrap";

import { confirmDataSwd } from "../../../actions/OpkFormsActions/confirmDataActions";
import {
  downloadLastUploadedFkFile,
  getpersonalizedexcel,
} from "../../../actions/OpkFormsActions/getPersonalizedExcelActions";
import { opkCheckData } from "../../../actions/OpkFormsActions/opkCheckDataActions";
import { SmalModal } from "../../../components/Modal/SmalModal";
import { areFieldsFilled } from "utils/validators";
import { handleSaveProceedingDataToLS } from "utils/localStorageUtils";
import { REQUIRED_GENERAL_FIELDS as REQUIRED_FIELDS } from "../../../utils/constants";
import SwitchUserMenu from "../StaffUserViews/switchUserMenu";

const SideBarButtons = ({ procedureID }) => {
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const switchedUserInfo = useSelector(
    (state) => state.switchedUserInfoReducer.switchedUserInfo,
  );
  const opk_User = useSelector((state) => state.opkUserProfileReducer.opk_User);
  const isSwitchedUser = useSelector(
    (state) => state.switchUserReducer.isSwitchedUser,
  );

  const proceeding = useSelector(
    (state) => state.opkListReducer.data.proceeding,
  );

  const handleModalShow = () => setShowModal((prevState) => !prevState);

  const handleAccept = () => {
    handleModalShow();
    dispatch(confirmDataSwd(procedureID)).then(() => {
      navigate(`/fk/${procedureID}`);
    });
  };

  const checkOpkUserData = () => {
    const profile =
      Array.isArray(opk_User?.profile) && opk_User.profile.length > 0
        ? opk_User.profile[0]
        : null;

    return profile ? areFieldsFilled(profile, REQUIRED_FIELDS) : false;
  };

  return (
    <>
      <div className="dropdownMenu" id="sidemenu">
        <Link to="/fk/user_profile" tabIndex="-1">
          <Button
            className="btn-aotmit w-100"
            variant={
              checkOpkUserData() ? "outline-secondary" : "outline-danger"
            }
            size="sm"
            onClick={handleSaveProceedingDataToLS(
              procedureID,
              proceeding?.typ_danych,
              proceeding?.postepowanie_nr,
            )}
          >
            Dane ogólne
          </Button>
        </Link>

        <Button
          onClick={() => dispatch(opkCheckData(procedureID))}
          className="btn-aotmit w-100"
          variant="outline-secondary"
          size="sm"
        >
          Sprawdź dane
        </Button>

        <Link to={`/fk/${procedureID}/upload_xlsx`} tabIndex="-1">
          <Button
            className="btn-aotmit w-100"
            variant="outline-secondary"
            size="sm"
          >
            Import Excel
          </Button>
        </Link>

        <Button
          onClick={() => dispatch(getpersonalizedexcel(procedureID))}
          className="btn-aotmit w-100"
          variant="outline-secondary"
          size="sm"
        >
          Pobierz wzór FK
        </Button>

        <Button
          onClick={() => dispatch(downloadLastUploadedFkFile(procedureID))}
          className="btn-aotmit w-100"
          variant="outline-secondary"
          size="sm"
        >
          Pobierz plik z ostatnio uzupełnionymi danymi FK
        </Button>

        <Button
          color="success"
          disabled={
            switchedUserInfo.verifications &&
            switchedUserInfo.verifications.czy_otwarte === 1
          }
          onClick={() => handleModalShow()}
          className="mt-2 w-100"
          variant="outline-success"
          size="sm"
        >
          Prześlij do AOTMiT
        </Button>

        {isSwitchedUser && <SwitchUserMenu procedureID={procedureID} />}
      </div>
      <SmalModal
        handleAccept={handleAccept}
        handleClose={handleModalShow}
        modalBodyText={
          <p className="text-center">
            Czy na pewno chcesz przesłać dane do AOTMiT?
          </p>
        }
        show={showModal}
      />
    </>
  );
};

export default SideBarButtons;
