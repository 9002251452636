import { saveAs } from "file-saver";

import { apiClient } from "api/api";

import {
  ADD_OPKS_ACCEPTANCE,
  CONFIRM_OPKS,
  GET_ERRORS,
  GET_OPKS_ACCEPTANCE,
  GET_SUCCESS,
  OPKS_ACCEPTANCE_LOADING_OFF,
  OPKS_ACCEPTANCE_SET_LOADING,
} from "../types";
import { SUCCESS_INFO, PARTIAL_CONTETNT_INFO } from "../../utils/toastMessages";
import {
  isPartialContent,
  handleErrorsForModalContent,
} from "../../utils/helpers";

export const getOpks = (procedureID, userID) => async (dispatch) => {
  try {
    dispatch({ type: OPKS_ACCEPTANCE_SET_LOADING });

    const response = await apiClient.get(
      `/fk/${procedureID}/${userID}/akceptacja_opk`,
    );

    dispatch({
      payload: response.data,
      type: GET_OPKS_ACCEPTANCE,
    });
  } catch (err) {
    const errors = {
      msg: err.response.data,
      status: err.response.status,
    };
    dispatch({
      payload: errors,
      type: GET_ERRORS,
    });
  }
};

export const addOpks =
  (procedureID, userID, submitedFormData) => async (dispatch) => {
    dispatch({ type: OPKS_ACCEPTANCE_SET_LOADING });
    try {
      const response = await apiClient.post(
        `/fk/${procedureID}/${userID}/akceptacja_opk`,
        submitedFormData,
      );
      const partialContent = isPartialContent(response.status);

      dispatch({
        payload: {
          msg: partialContent ? PARTIAL_CONTETNT_INFO : SUCCESS_INFO,
          status: response.status,
        },
        type: GET_SUCCESS,
      });

      dispatch({
        payload: response.data,
        type: ADD_OPKS_ACCEPTANCE,
      });

      if (partialContent) {
        return handleErrorsForModalContent(response.data);
      }
    } catch (err) {
      console.log(err.response, err.response.data);
      dispatch({
        payload: err.response,
        type: GET_ERRORS,
      });
    } finally {
      dispatch({ type: OPKS_ACCEPTANCE_LOADING_OFF });
    }
  };

export const confirmOpk = (procedureID, userID) => async (dispatch) => {
  try {
    dispatch({ type: OPKS_ACCEPTANCE_SET_LOADING });
    const response = await apiClient.get(
      `/fk/${procedureID}/${userID}/confirm_opks`,
    );
    dispatch({
      payload: response.status,
      type: CONFIRM_OPKS,
    });

    dispatch({
      payload: {
        msg: "Numery kont OPK zostały zatwierdzone",
        status: response.status,
      },
      type: GET_SUCCESS,
    });
  } catch (err) {
    console.log(err.response, err.response.data);

    const errors = {
      msg: err.response.data,
      status: err.response.status,
    };
    dispatch({
      payload: errors,
      type: GET_ERRORS,
    });
  }
};

export const getExcelOpkData = (procedureID, userID) => async (dispatch) => {
  try {
    const response = await apiClient.get(
      `/fk/${procedureID}/${userID}/get_opk_excel`,
      {
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "arraybuffer",
      },
    );
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const contentDisposition = response.headers.get("Content-Disposition");
    const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
    const fileName = fileNameMatch ? fileNameMatch[1] : "OPK_do_akceptacji";

    saveAs(blob, fileName);
    dispatch({
      type: GET_SUCCESS,
      payload: {
        msg: "Plik z listą OPK do akceptacji został poprawnie pobrany.",
        status: response.status,
      },
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: {
        msg: error.response?.data || "Wystąpił błąd podczas pobierania pliku.",
        status: error.response?.status || 500,
      },
    });
  }
};
