import {
  GET_TAB1_FORM_DATA,
  SAVE_TAB1_FORM_DATA,
  TAB1_FORM_DATA_SET_LOADING,
  TAB1_LOADING_OFF,
} from "../../../actions/types.js";

const initialState = {
  loading: true,
  Tab1Data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TAB1_FORM_DATA:
      return {
        ...state,
        loading: false,
        Tab1Data: action.payload,
      };
    case SAVE_TAB1_FORM_DATA:
      return {
        ...state,
        loading: false,
        Tab1Data: action.payload,
      };
    case TAB1_FORM_DATA_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case TAB1_LOADING_OFF:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
