import { useFormik } from "formik";
import { Button, Form } from "react-bootstrap";

const OpkColumnForm = ({
  dataSubmit,
  formFields,
  opk_object,
  validationSchema,
}) => {
  const formik = useFormik({
    initialValues: formFields.reduce(
      (accumulator, { fieldId: id, initialValue }) => {
        if (id) accumulator[id] = opk_object[id] || initialValue;
        return accumulator;
      },
      {},
    ),
    validateOnMount: true,
    validationSchema,
  });

  const handleFieldChange = (event) => {
    const { name, type, value } = event.target;
    if (type === "number" && value === "") formik.setFieldValue(name, 0);
  };

  const errors = Object.values(formik.errors);

  return (
    <div className="form-container">
      {(opk_object.bledy || errors.length !== 0) && (
        <ul
          className="box-error"
          dangerouslySetInnerHTML={{
            __html:
              opk_object.bledy +
              errors
                .map((error, index) => `<li key=${index}>${error}</li>`)
                .join(""),
          }}
        />
      )}
      <Form>
        <div className="list-group-container">
          <div className="btn-unscrollable">
            <Button
              className="w-100"
              onClick={() => dataSubmit(formik.values)}
              size="lg"
              type="button"
              variant="secondary"
            >
              Zapisz
            </Button>
          </div>

          <div className="list-scrollable">
            {formFields.map((fieldItem, idx) => {
              switch (fieldItem.fieldType) {
                case "activity-form-select":
                  return (
                    <div className="list-item-container" key={idx}>
                      <div className="list-sub-title">
                        {fieldItem.fieldLabel}
                      </div>
                      <Form.Select
                        aria-label={fieldItem.fieldLabel}
                        className="list-form"
                        name={fieldItem.fieldId}
                        onChange={(event) => {
                          formik.handleChange(event);
                          handleFieldChange(event);
                        }}
                        value={formik.values[fieldItem.fieldId]}
                      >
                        <option></option>
                        <option value={1}>Podmiot Publiczny</option>
                        <option value={2}>
                          Podmiot Niepubliczny, którego organem założycielskim
                          jest organ władzy centralnej, samorządowej lub wyższa
                          uczelnia
                        </option>
                        <option value={3}>
                          Podmiot Niepubliczny, którego organem założycielskim
                          jest osoba prawna lub osoba fizyczna
                        </option>
                      </Form.Select>
                    </div>
                  );
                case "city-size-select":
                  return (
                    <div className="list-item-container" key={idx}>
                      <div className="list-sub-title">
                        {fieldItem.fieldLabel}
                      </div>
                      <Form.Select
                        aria-label={fieldItem.fieldLabel}
                        className="list-form"
                        name={fieldItem.fieldId}
                        onChange={(event) => {
                          formik.handleChange(event);
                          handleFieldChange(event);
                        }}
                        value={formik.values[fieldItem.fieldId]}
                      >
                        <option></option>
                        <option value={1}>&lt; 40 tys.</option>
                        <option value={2}>40-100 tys.</option>
                        <option value={3}>&gt; 100 tys.</option>
                      </Form.Select>
                    </div>
                  );
                case "comments":
                  return (
                    <div className="list-item-container" key={idx}>
                      <div className="list-simple-title">
                        {fieldItem.fieldLabel}
                      </div>
                      <Form.Control
                        aria-label={fieldItem.fieldLabel}
                        as="textarea"
                        className="list-form"
                        name={fieldItem.fieldId}
                        onChange={(event) => {
                          formik.handleChange(event);
                          handleFieldChange(event);
                        }}
                        rows="4"
                        value={formik.values[fieldItem.fieldId]}
                      />
                    </div>
                  );
                case "didactic-activity-select":
                  return (
                    <div className="list-item-container" key={idx}>
                      <div className="list-sub-title">
                        {fieldItem.fieldLabel}
                      </div>
                      <Form.Select
                        aria-label={fieldItem.fieldLabel}
                        className="list-form"
                        name={fieldItem.fieldId}
                        onChange={(event) => {
                          formik.handleChange(event);
                          handleFieldChange(event);
                        }}
                        value={formik.values[fieldItem.fieldId]}
                      >
                        <option value={true}>Tak</option>
                        <option value={false}>Nie</option>
                      </Form.Select>
                    </div>
                  );

                case "email-input":
                  return (
                    <div className="list-item-container" key={idx}>
                      <div className="list-sub-title">
                        {fieldItem.fieldLabel}
                      </div>
                      <Form.Control
                        aria-label={fieldItem.fieldLabel}
                        className="list-form"
                        name={fieldItem.fieldId}
                        onChange={(event) => {
                          formik.handleChange(event);
                          handleFieldChange(event);
                        }}
                        type="email"
                        value={formik.values[fieldItem.fieldId]}
                      />
                    </div>
                  );
                case "header":
                  return (
                    <div className="list-item-container" key={idx}>
                      <h6 className="mt-2">{fieldItem.fieldLabel}</h6>
                    </div>
                  );
                case "phone-number-input":
                  return (
                    <div className="list-item-container" key={idx}>
                      <div className="list-sub-title">
                        {fieldItem.fieldLabel}
                      </div>
                      <Form.Control
                        aria-label={fieldItem.fieldLabel}
                        className="list-form"
                        name={fieldItem.fieldId}
                        onChange={(event) => {
                          formik.handleChange(event);
                          handleFieldChange(event);
                        }}
                        value={formik.values[fieldItem.fieldId]}
                      />
                    </div>
                  );
                case "simple-title":
                  return (
                    <div className="list-item-container" key={idx}>
                      <div className="list-simple-title">
                        {fieldItem.fieldLabel}
                      </div>
                      <Form.Control
                        aria-label={fieldItem.fieldLabel}
                        className="list-form"
                        name={fieldItem.fieldId}
                        onChange={(event) => {
                          formik.handleChange(event);
                          handleFieldChange(event);
                        }}
                        pattern="[+-]?\d+(?:[.,]\d+)?"
                        type="number"
                        value={formik.values[fieldItem.fieldId]}
                      />
                    </div>
                  );
                case "sub-title":
                  return (
                    <div className="list-item-container" key={idx}>
                      <div className="list-sub-title">
                        {fieldItem.fieldLabel}
                      </div>
                      <Form.Control
                        aria-label={fieldItem.fieldLabel}
                        className="list-form"
                        name={fieldItem.fieldId}
                        onChange={(event) => {
                          formik.handleChange(event);
                          handleFieldChange(event);
                        }}
                        pattern="[+-]?\d+(?:[.,]\d+)?"
                        type="number"
                        value={formik.values[fieldItem.fieldId]}
                      />
                    </div>
                  );
                case "text-sub-title":
                  return (
                    <div className="list-item-container" key={idx}>
                      <div className="list-sub-title">
                        {fieldItem.fieldLabel}
                      </div>
                      <Form.Control
                        aria-label={fieldItem.fieldLabel}
                        className="list-form"
                        name={fieldItem.fieldId}
                        onChange={(event) => {
                          formik.handleChange(event);
                          handleFieldChange(event);
                        }}
                        type="text"
                        value={formik.values[fieldItem.fieldId]}
                      />
                    </div>
                  );
                case "text-sub-title-not-changeable":
                  return (
                    <div className="list-item-container" key={idx}>
                      <div className="list-sub-title">
                        {fieldItem.fieldLabel}
                      </div>
                      <Form.Control
                        aria-label={fieldItem.fieldLabel}
                        className="list-form"
                        disabled
                        name={fieldItem.fieldId}
                        value={formik.values[fieldItem.fieldId]}
                      />
                    </div>
                  );
                case "text-title":
                  return (
                    <div className="list-item-container" key={idx}>
                      <div className="list-main-title">
                        {fieldItem.fieldLabel}
                      </div>
                      <Form.Control
                        aria-label={fieldItem.fieldLabel}
                        className="list-form"
                        name={fieldItem.fieldId}
                        onChange={(event) => {
                          formik.handleChange(event);
                          handleFieldChange(event);
                        }}
                        value={formik.values[fieldItem.fieldId]}
                      />
                    </div>
                  );
                case "title":
                  return (
                    <div className="list-item-container" key={idx}>
                      <div className="list-main-title">
                        {fieldItem.fieldLabel}
                      </div>

                      <Form.Control
                        aria-label={fieldItem.fieldLabel}
                        className="list-form"
                        name={fieldItem.fieldId}
                        onChange={(event) => {
                          formik.handleChange(event);
                          handleFieldChange(event);
                        }}
                        type="number"
                        value={formik.values[fieldItem.fieldId]}
                      />
                    </div>
                  );
                default:
                  return <>Coś poszło nie tak</>;
              }
            })}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default OpkColumnForm;
