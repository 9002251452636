const Contact = () => {
  return (
    <>
       <h2 id="a11y-kontakt">Informacje zwrotne i dane kontaktowe</h2>
        <ul>
          <li>Za rozpatrywanie uwag i wniosków odpowiada: <span id="a11y-osoba">AOTMiT</span>.</li>
          <li>E-mail: <span id="a11y-email"><a href="mailto:webmaster@aotm.gov.pl">webmaster@aotm.gov.pl</a></span></li>
          <li>Telefon: <span id="a11y-telefon">+48 22 101 46 00</span></li>
        </ul>
        <p id="a11y-procedura">Każdy ma prawo:</p>
        <ul>
          <li>zgłosić uwagi dotyczące dostępności cyfrowej strony lub jej elementu,</li>
          <li>zgłosić żądanie zapewnienia dostępności cyfrowej strony lub jej elementu,</li>
          <li>wnioskować o udostępnienie niedostępnej informacji w innej alternatywnej formie.</li>
        </ul>
        <p>Żądanie musi zawierać:</p>
        <ul>
          <li>dane kontaktowe osoby zgłaszającej,</li>
          <li>wskazanie strony lub elementu strony, której dotyczy żądanie,</li>
          <li>wskazanie dogodnej formy udostępnienia informacji, jeśli żądanie dotyczy udostępnienia w formie alternatywnej informacji niedostępnej.</li>
        </ul>
        <p>Rozpatrzenie zgłoszenia powinno nastąpić niezwłocznie, najpóźniej w ciągu 7 dni. Jeśli w tym terminie zapewnienie dostępności albo zapewnienie dostępu w alternatywnej formie nie jest możliwe, powinno nastąpić najdalej w ciągu 2 miesięcy od daty zgłoszenia.</p>
    </>
  )
}

export default Contact