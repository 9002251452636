import { configureStore } from "@reduxjs/toolkit"; // From Redux Toolkit
import { thunk } from "redux-thunk";

import rootReducer from "./reducers";

const store = configureStore({
  devTools: true, // Automatically enabled in development
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk), // Automatically includes redux-thunk
  reducer: rootReducer,
});

export default store;
