import {
  GET_ALL_EXCEL_FILES_WITH_NEW_USERS,
  GET_ALL_PROCEEDINGS,
  GET_ALL_PROCEEDINGS_SET_LOADING,
  POST_EXCEL_FILE_WITH_NEW_USERS,
  POST_EXCEL_SET_LOADING,
} from "actions/types.js";

const initialState = {
  all_proceedings: [],
  excel_file_list: [],
  loading: false,
  loading_post_excel: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_EXCEL_FILES_WITH_NEW_USERS:
      return {
        ...state,
        excel_file_list: action.payload,
        loading: false,
      };
    case GET_ALL_PROCEEDINGS:
      return {
        ...state,
        all_proceedings: action.payload,
        loading: false,
      };
    case GET_ALL_PROCEEDINGS_SET_LOADING:
      return {
        ...state,
        loading: true,
        excel_file_list: [],
      };
    case POST_EXCEL_FILE_WITH_NEW_USERS:
      return {
        ...state,
        excel_file_list: [action.payload, ...state.excel_file_list],
        loading_post_excel: false,
      };
    case POST_EXCEL_SET_LOADING:
      return {
        ...state,
        loading_post_excel: true,
      };
    default:
      return state;
  }
};
export default reducer;
