import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Button, Col, Row } from "react-bootstrap";

import { getAllProceedings } from "actions/PanelDashboardActions/ParticipationRegisterActions";
import { fetchSelectedUserData } from "actions/PanelDashboardActions/RestoreForImprovmentActions";
import {
  getProceedingUsers,
  restoreForImprovment,
} from "actions/PanelDashboardActions/RestoreForImprovmentActions";
import { SmalModal } from "components/Modal/SmalModal";
import ProceedingsSelect from "components/Selects/ProceedingsSelect";
import HealthcareProviderSelect from "components/Selects/HealthcareProviderSelect";
import HealthcareProviderCard from "components/Cards/HealthcareProviderCard";

const RestoreForImprovment = () => {
  const [selectedProceeding, setSelectedProceeding] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loadUserData, setLoadUserData] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  const handleModalToggler = () => setShowModal((prevState) => !prevState);
  const handleAccept = async () => {
    setLoadUserData(true);
    handleModalToggler();
    await dispatch(
      restoreForImprovment(selectedProceeding.value, selectedUser.user.id),
    );
    getUserData(selectedUser.user.id);
  };

  useEffect(() => {
    dispatch(getAllProceedings({ typ_danych: "fk" }));
  }, [dispatch]);

  useEffect(() => {
    if (selectedProceeding !== null) {
      setSelectedUser(null);
      dispatch(getProceedingUsers(selectedProceeding.value));
    }
  }, [dispatch, selectedProceeding]);

  const getUserData = async (userId) => {
    setLoadUserData(true);
    const data = await dispatch(
      fetchSelectedUserData(selectedProceeding.value, userId),
    );
    setSelectedUser(data);
    setLoadUserData(false);
  };

  const handleChangeSelectedUser = async (option) => {
    if (option.value) {
      getUserData(option.value);
    } else {
      setSelectedUser(null);
    }
  };

  return (
    <>
      <div className="head-container">
        <h3>Panel pomocniczy</h3>
        <p>
          Widok służy do przywracania świadczeniodawcy stanu do poprawy, po tym
          jak już raz OPKi zostały zaakceptowane. Zazwyczaj dzieje się to na
          prośbę świadczeniodawcy. Wpisz regon świadczeniodawcy, wybierz
          postepowanie i kliknij wyślij. Po wysłaniu WSZYSTKIE OPKi
          świadczeniodawcy zostaną usunięte z listy OPK (czyli usuną się
          wszystkie dane Tab1 Tab2 Tab3 Analityka itd...), a OPKI wrócą do stanu
          przed akceptacją listy, podobnie jak przy kliknięciu przycisku "do
          poprawy".
        </p>
      </div>
      <main className="main-container">
        <Row className="mb-4">
          <Col md={4} sm={12}>
            <ProceedingsSelect
              selectValue={selectedProceeding}
              onChangeFn={setSelectedProceeding}
            />
            <>
              <HealthcareProviderSelect
                selectValue={selectedUser}
                onChangeFn={handleChangeSelectedUser}
                isDisabled={!selectedProceeding}
              />
              {selectedUser?.user && (
                <Button
                  className="mt-4"
                  variant="info"
                  onClick={handleModalToggler}
                  size="sm"
                  type="button"
                >
                  Przywróć do poprawy <i className="fa fa-chevron-right" />
                </Button>
              )}
            </>
          </Col>
          {(loadUserData || selectedUser) && (
            <Col md={8} sm={12}>
              <HealthcareProviderCard
                dataLoading={loadUserData}
                healthcareProvider={selectedUser}
                statusFor={"opk"}
              />
            </Col>
          )}
        </Row>
      </main>
      <SmalModal
        handleAccept={handleAccept}
        handleClose={handleModalToggler}
        modalBodyText={
          <p className="text-center">
            Czy na pewno chcesz przywrócić do poprawy?
          </p>
        }
        show={showModal}
      />
    </>
  );
};

export default RestoreForImprovment;
