import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";

import { Card, Col, Nav, Row } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { faCog, faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getFKFormFields, getOpkData } from "../../../actions/opkListActions";
import { getOpkUserProfile } from "../../../actions/OpkUserProfileActions";
import { REQUIRED_GENERAL_FIELDS as REQUIRED_FIELDS } from "../../../utils/constants";
import { areFieldsFilled } from "../../../utils/validators";
import { Loading } from "../../../components/Loading/loading";
import CardInfo from "../../../components/Cards/CardInfo";
import SkipLinks from "../../../components/SkipLinks/SkipLinks";
import BreadcrumbContainer from "../../../components/Breadcrumbs/BreadcrumbContainer";
import NotesAndFilesComponent from "../StaffUserViews/NotesAndFilesComponent";
import OpkListComponent from "./OpkListComponent";
import SideBarButtons from "./SideBarButtons";

const OpkListPage = () => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [isToastShown, setIsToastShown] = useState(false);

  const { opkID, procedureID } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();

  const CheckDataloading = useSelector(
    (state) => state.opkCheckDataReducer.loading,
  );
  const form_fields = useSelector(
    (state) => state.opkFormFieldsReducer.form_fields,
  );
  const form_fields_loading = useSelector(
    (state) => state.opkFormFieldsReducer.loading,
  );
  const isSwitchedUser = useSelector(
    (state) => state.switchUserReducer.isSwitchedUser,
  );
  const loading = useSelector((state) => state.opkListReducer.loading);
  const opk_list = useSelector((state) => state.opkListReducer.data.opk_list);
  const proceeding = useSelector(
    (state) => state.opkListReducer.data.proceeding,
  );
  const opk_User = useSelector((state) => state.opkUserProfileReducer.opk_User);
  const controllerData = useSelector(
    (state) => state.opkViewControllerReducer.controllerData,
  );
  const confirmOpkDataLoading = useSelector(
    (state) => state.opkConfirmDataReducer.loading,
  );

  useEffect(() => {
    if (Number(procedureID) !== Number(proceeding?.id)) {
      dispatch(getOpkData(procedureID));
      dispatch(getFKFormFields(procedureID));
      dispatch(getOpkUserProfile());
    }
  }, [dispatch, procedureID]);

  useEffect(() => {
    const profile = opk_User.profile?.[0];

    if (
      profile &&
      !areFieldsFilled(profile, REQUIRED_FIELDS) &&
      !isToastShown
    ) {
      toast.error("Uzupełnij informacje w zakładce dane ogólne!", {
        id: "fill-user-data",
      });
      setIsToastShown(true);
    }
  }, [opk_User]);

  const handleSideBarToggler = () => {
    setShowSidebar((prevState) => !prevState);
  };

  const tabsControler = (currentOpk) => {
    if (form_fields_loading) {
      return <>...</>;
    }

    const medicalTabs = [
      { key: "opk", label: "Opk", field: "opk_table" },
      { key: "tab1", label: "Tab1", field: "tab1" },
      { key: "tab2", label: "Tab2", field: "tab2" },
      { key: "tab3", label: "Tab3", field: "tab3" },
      { key: "analytics", label: "Analityka", field: "analytics" },
    ];

    const nonMedicalTabs = [{ key: "tab4", label: "Tab4", field: "tab4" }];

    const selectedTabs = currentOpk.opk_niemedyczny
      ? nonMedicalTabs
      : medicalTabs;

    const tabName = location.pathname.split("/").pop();
    const activeTabKey = tabName.replace("form", "");

    return (
      <Nav variant="tabs" activeKey={activeTabKey}>
        {selectedTabs.map(
          ({ key, label, field }) =>
            form_fields[field] && (
              <Nav.Item key={key}>
                <Nav.Link
                  as={Link}
                  to={`/fk/${procedureID}/opk/${opkID}/${key}form`}
                  eventKey={key}
                  className={
                    currentOpk.tabs_with_errors?.includes(key) &&
                    "btn-danger-accessible"
                  }
                >
                  {label}
                </Nav.Link>
              </Nav.Item>
            ),
        )}
      </Nav>
    );
  };

  const getSkipLinks = () => {
    const skipLinks = [
      {
        id: "#sidemenu",
        desc: "Przejdź do menu bocznego.",
      },
      {
        id: "#opkmedical",
        desc: "Przejdź do listy OPK medyczne (Pełne dane FK).",
      },
      {
        id: "#main",
        desc: "Przejdź do formularza z danymi.",
      },
    ];

    if (opk_list && opk_list.some((opk) => opk.opk_niemedyczny)) {
      skipLinks.push({
        id: "#opknotmedical",
        desc: "Przejdź do listy OPK nie medyczne (Niepełne dane FK).",
      });
    }
    return skipLinks;
  };

  const getCardInfoTitle = (status) => {
    switch (status) {
      case 0:
        return "Zapraszamy do wypełnienia formularzy.";
      case 1:
        return "Dane zostały przesłane do weryfikacji.";
      case 2:
        return "Dane zostały zatwierdzone.";
      case 3:
        return "Dane zostały odrzucone.";
      default:
        return "Wczytywanie...";
    }
  };

  return (
    <>
      <SkipLinks skipLinks={getSkipLinks()} />
      <div className="fixedPlugin" tabIndex="0" onClick={handleSideBarToggler}>
        <FontAwesomeIcon icon={faCog} size="2x" />
      </div>

      {showSidebar && <SideBarButtons procedureID={procedureID} />}
      <>
        <div className="full-container--lm">
          <BreadcrumbContainer />
        </div>

        {isSwitchedUser && <NotesAndFilesComponent />}
        {confirmOpkDataLoading && (
          <Loading variant="info" fullScreen={true}>
            <span className="m-2">
              <p>Trwa przesyłanie danych. Proszę czekać...</p>
            </span>
          </Loading>
        )}
        {CheckDataloading ? (
          <div className="full-container--lm">
            <h3>
              {proceeding &&
                `Postępowanie nr: ${proceeding.postepowanie_nr}, ${proceeding.postepowanie_rok}`}
            </h3>
            <>{form_fields.announcement}</>
            <>
              {opk_list && opk_list.length !== 0 ? (
                <>
                  <p>Liczba kont OPK: {opk_list.length}</p>
                  <>
                    {!form_fields_loading && form_fields.announcement && (
                      <div className="border-box">
                        <div className="border-box-title text-aotmit">
                          <FontAwesomeIcon icon={faInfo} size="1x" />
                        </div>
                        <div className="border-box-content">
                          {form_fields.announcement}
                        </div>
                      </div>
                    )}
                  </>
                </>
              ) : (
                <div className="border-box">
                  <div className="border-box-title text-warning">
                    <FontAwesomeIcon icon={faInfo} size="1x" />
                  </div>
                  <div className="border-box-content">
                    <h6>Nie dodano jeszcze kont OPK w tym Postępowaniu</h6>
                  </div>
                </div>
              )}
            </>

            <Loading variant="danger">
              <span className="m-2">
                Dane przesłane w tym postępowaniu są weryfikowane. Może to
                potrwać kilka minut
              </span>
            </Loading>
          </div>
        ) : (
          <div className="full-container--lm">
            <div className="">
              <h3>
                {proceeding &&
                  `Postępowanie nr: ${proceeding.postepowanie_nr}, ${proceeding.postepowanie_rok}`}
              </h3>
              <>
                {!loading &&
                  (opk_list && opk_list.length !== 0 ? (
                    <p>Liczba kont OPK: {opk_list.length}</p>
                  ) : (
                    <div className="border-box">
                      <div className="border-box-title text-warning">
                        <FontAwesomeIcon icon={faInfo} size="1x" />
                      </div>
                      <div className="border-box-content">
                        <h6>Nie dodano jeszcze kont OPK w tym Postępowaniu</h6>
                      </div>
                    </div>
                  ))}
              </>
              {!form_fields_loading && form_fields.announcement && (
                <div className="border-box">
                  <div className="border-box-title text-aotmit">
                    <FontAwesomeIcon icon={faInfo} size="1x" />
                  </div>
                  <div className="border-box-content">
                    {form_fields.announcement}
                  </div>
                </div>
              )}
              {loading && !opk_list ? (
                <Loading variant="danger">
                  <span className="m-2">Pobieranie listy OPK...</span>
                </Loading>
              ) : (
                <OpkListComponent
                  opkID={opkID}
                  opklist={opk_list}
                  procedureID={procedureID}
                />
              )}
            </div>
            <main className="my-5" id="main">
              {opkID ? (
                <Card className="mt-5">
                  <Card.Header>
                    <div className="box-info">
                      <Row>
                        <Col xs={3}>
                          <h6>Nr opk:</h6>
                          <h6>Nazwa opk: </h6>
                          <h6>Kod resortowy: </h6>
                        </Col>
                        <Col xs={9}>
                          <div className="mb-1">
                            {opk_list &&
                              opk_list.find((opk) => opk.id === parseInt(opkID))
                                .nr_konta}
                          </div>
                          <div className="mb-1">
                            {opk_list &&
                              opk_list.find((opk) => opk.id === parseInt(opkID))
                                .nazwa_opk}
                          </div>
                          <div className="mb-1">
                            {opk_list &&
                              opk_list.find((opk) => opk.id === parseInt(opkID))
                                .kod_resortowy}
                          </div>
                        </Col>
                      </Row>
                    </div>

                    {opk_list &&
                      tabsControler(
                        opk_list.find((opk) => opk.id === parseInt(opkID)),
                      )}
                  </Card.Header>
                  <Card.Body className="overflow-auto">
                    <Outlet />
                  </Card.Body>
                </Card>
              ) : (
                <CardInfo
                  bodyTitle={getCardInfoTitle(
                    controllerData.verification_status,
                  )}
                  header={<FontAwesomeIcon icon={faInfo} size="2x" />}
                  headerClass="text-warning"
                />
              )}
            </main>
          </div>
        )}
      </>
    </>
  );
};

export default OpkListPage;
