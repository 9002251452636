export const REQUIRED_GENERAL_FIELDS = [
  "kontakt_imie",
  "kontakt_nazwisko",
  "kontakt_email",
  "kontakt_telefon",
  "wielkosc",
  "forma_dzialalnosci",
  "dzialalnosc_dydakt",
  "ilosc_lozek",
];
