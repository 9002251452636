const render_veryfication = (verification) => {
  const options = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  let title;
  if (verification.czy_otwarte === 0) {
    title = (
      <h6 className="mb-0" style={{ color: "#996600" }}>
        odblokowane
      </h6>
    );
  } else if (verification.czy_otwarte === 1) {
    title = <h6 className="mb-0 text-aotmit">przesłane</h6>;
  } else if (verification.czy_otwarte === 2) {
    title = <h6 className="mb-0 text-success">zatwierdzone</h6>;
  } else if (verification.czy_otwarte === 3) {
    title = (
      <h6 className="mb-0" style={{ color: "#b5022c" }}>
        odrzucone
      </h6>
    );
  }

  return (
    <>
      {title}
      <p className="text-muted font-small mb-0">{`${new Date(
        verification.data_wpisu,
      ).toLocaleDateString("pl-PL", options)} `}</p>
      <p className="text-muted font-small mb-0">{verification.zatwierdzil}</p>
    </>
  );
};

export { render_veryfication };
