import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Col, Row } from "react-bootstrap";

import { getUserInfo } from "../../actions/OpkUserProfileActions";

const UserInfo = () => {
  const [showUserInfo, setShowUserInfo] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  const switchedUser = useSelector(
    (state) => state.switchUserReducer.switchedUser,
  );
  const user = useSelector((state) => state.authReducer.user);
  const user_info = useSelector((state) => state.userInfoReducer.user_info);

  const options = {
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    month: "long",
    year: "numeric",
  };

  const displayUser = switchedUser || user;

  useEffect(() => {
    if (switchedUser && !location.pathname.includes("logout")) {
      dispatch(getUserInfo(switchedUser.id));
    }
    setShowUserInfo(
      !!switchedUser || location.pathname.includes("opk_akceptacja"),
    );
  }, [switchedUser, location.pathname]);

  const userProfileName = useMemo(() => {
    return user_info?.profile?.[0]?.swd_nazwa || "nie dodano nazwy swd";
  }, [user_info]);

  return (
    <div className="user-info">
      {displayUser && (
        <>
          <div className="user-info-main">
            <p>
              <strong>Dane kosztowe {process.env.REACT_APP_VERSION}</strong>
            </p>
            <br /> Zalogowany(a) jako: {displayUser.username}
          </div>
          {showUserInfo && (
            <>
              <Row className="ms-0">
                <Col className="px-0" lg={6}>
                  <p className="user-info-sub">{userProfileName}</p>
                </Col>
                <Col className="px-0" lg={3}>
                  <p className="user-info-sub">
                    e-mail świadczeniodawcy: {user_info.email}
                  </p>
                </Col>
                <Col className="px-0" lg={3}>
                  <p className="user-info-sub">
                    ostatnie logowanie:{" "}
                    {user_info.last_login
                      ? new Date(user_info.last_login).toLocaleString(
                          "pl-PL",
                          options,
                        )
                      : "-"}
                  </p>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default UserInfo;
