import {
  ANALYTICS_FORM_DATA_SET_LOADING,
  ANALYTICS_FORM_LOADING_OFF,
  GET_ANALYTICS_FORM_DATA,
  SAVE_ANALYTICS_FORM_DATA,
} from "../../../actions/types.js";

const initialState = {
  AnalyticsData: [],
  loading: false,
  unsavedRows: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ANALYTICS_FORM_DATA_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ANALYTICS_FORM_DATA:
      return {
        ...state,
        AnalyticsData: action.payload,
        loading: false,
      };
    case SAVE_ANALYTICS_FORM_DATA:
      return {
        ...state,
        AnalyticsData: action.payload,
        loading: false,
        unsavedRows: action.payload?.unsavedRows || [],
      };
    case ANALYTICS_FORM_LOADING_OFF:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
