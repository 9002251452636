const defaultTitle = "Dane kosztowe | AOTMiT";

const routes = {
  HOME: "/",
  HOME2: "",
  LOGIN: "/registration/login",
  RESET: "/registration/forgot-password",
  SITEMAP: "/sitemap",
  PROFILE: "/fk/user_profile",
  WCAG: "/wcag",
};

const routeTitles = {
  [routes.HOME]: "Strona główna",
  [routes.HOME2]: "Strona główna",
  [routes.LOGIN]: "Logowanie",
  [routes.RESET]: "Odzyskaj hasło",
  [routes.SITEMAP]: "Mapa strony",
  [routes.PROFILE]: "Profil Użytkownika",
  [routes.WCAG]: "Dostępność cyfrowa",
};

const getRouteTitle = (pathname) => {
  const opkEnds = [
    "opk",
    "opkform",
    "tab1form",
    "tab2form",
    "tab3form",
    "tab4form",
    "analyticsform",
  ];
  if (pathname.startsWith("/instrukcja/FAQ")) return `FAQ | ${defaultTitle}`;
  if (pathname.startsWith("/instrukcja/materialy"))
    return `Materiały | ${defaultTitle}`;
  if (pathname.startsWith("/auth/password_reset_confirm"))
    return `Reset hasła | ${defaultTitle}`;
  if (
    pathname.endsWith("/opk") ||
    opkEnds.includes(pathname.split("/")[pathname.split("/").length - 1])
  )
    return `Postępowanie OPK | ${defaultTitle}`;
  if (pathname.endsWith("/upload_xlsx"))
    return `Wgrywanie danych OPK | ${defaultTitle}`;

  if (pathname.endsWith("/specific_data_form"))
    return `Dane szczegółowe | ${defaultTitle}`;

  return routeTitles[pathname]
    ? `${routeTitles[pathname]} | ${defaultTitle}`
    : defaultTitle;
};

export { defaultTitle, getRouteTitle, routeTitles };
