import {
  GET_PROCEEDINGS,
  LANDING_SET_LOADING,
  LANDING_SET_LOADING_OFF,
  CLEAR_PROCEEDINGS,
} from "../../actions/types.js";

const initialState = {
  loading: false,
  proceedings: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROCEEDINGS:
      return {
        ...state,
        loading: false,
        proceedings: action.payload,
      };
    case LANDING_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case LANDING_SET_LOADING_OFF:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_PROCEEDINGS:
      return {
        ...state,
        proceedings: [],
      };
    default:
      return state;
  }
};

export default reducer;
