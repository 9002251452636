import {
  GET_NOTES,
  GET_NOTES_SET_LOADING,
  NOTES_LOADING_OFF,
  SAVE_NOTES,
} from "../../../actions/types.js";

const initialState = {
  loading: true,
  notes: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTES:
      return {
        ...state,
        loading: false,
        notes: action.payload,
      };
    case GET_NOTES_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SAVE_NOTES:
      return {
        ...state,
        loading: false,
        notes: action.payload.notes,
      };
    case NOTES_LOADING_OFF:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default reducer;

// import { GET_LIST_FOR_VERIFICATION, LIST_FOR_VERIFICATION_SET_LOADING } from '../../actions/types.js'

// const initialState = {
//   proceedings_for_veryfication: {},
//   loading: true
// }

// export default (state = initialState, action) => {
//   switch (action.type) {
//     case GET_LIST_FOR_VERIFICATION:
//       return {
//         ...state,
//         proceedings_for_veryfication: action.payload,
//         loading: false
//       };
//     case LIST_FOR_VERIFICATION_SET_LOADING:
//       return {
//         ...state,
//         loading: true
//       };
//     default:
//       return state
//   }
// }
