import { apiClient } from "api/api";
import {
  CONFIRM_DATA_LOADING_OFF,
  GET_CONTROLLER_DATA,
  GET_ERRORS,
  VIEW_CONTROLLER_SET_LOADING,
} from "./types";

export const getControllerData = (procedureID) => async (dispatch) => {
  try {
    dispatch({ type: VIEW_CONTROLLER_SET_LOADING });

    const response = await apiClient.get(
      `/fk/${procedureID}/procedure_controller_api_view/`,
      { useSwitchUserToken: true },
    );
    dispatch({
      payload: response.data,
      type: GET_CONTROLLER_DATA,
    });
    dispatch({ type: CONFIRM_DATA_LOADING_OFF });
  } catch (err) {
    if (err.response) {
      console.log(err.response.data);
      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };

      dispatch({
        payload: errors,
        type: GET_ERRORS,
      });
    } else {
      console.log({ err });
    }
  }
};
