import Card from "react-bootstrap/Card";

const CardInfo = ({
  aotmitLogo,
  bodyTitle,
  description,
  footerActions,
  footerInfo,
  header,
  headerClass,
  imageHeader,
}) => {
  return (
    <div>
      <>
        <Card className="card-info">
          {imageHeader && (
            <div
              className="card-cover"
              style={{
                backgroundImage: `url(${imageHeader})`,
              }}
            />
          )}

          {aotmitLogo && (
            <div className="card-avatar border-white">
              <img alt="..." src={require("../../assets/img/aotm.png")} />
            </div>
          )}

          {header && (
            <Card.Header className={`text-center ${headerClass}`}>
              <>{header}</>
            </Card.Header>
          )}

          <Card.Body>
            {bodyTitle && (
              <Card.Title className="text-center" tag="h5">
                {bodyTitle}
              </Card.Title>
            )}
            {description && (
              <div className="card-description text-justify">{description}</div>
            )}

            {(footerInfo || footerActions) && (
              <>
                <hr />
                <Card.Footer>
                  {footerInfo && (
                    <div className="footer-info">{footerInfo}</div>
                  )}
                  <div className="actions">
                    {footerActions && footerActions}
                  </div>
                </Card.Footer>
              </>
            )}
          </Card.Body>
        </Card>
      </>
    </div>
  );
};

export default CardInfo;
