import axios from "axios";
import { jwtDecode } from "jwt-decode";

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const isTokenExpired = (token) => {
  const decoded = jwtDecode(token);
  return decoded.exp * 1000 < Date.now();
};

export const refreshToken = async () => {
  const refresh = localStorage.getItem("refresh_token");
  if (!refresh) return false;

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/auth/token/refresh/`,
      {
        refresh,
      },
    );
    if (response.status === 200) {
      localStorage.setItem("auth_token", response.data.access);
      return response.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

// Add a request interceptor to inject the Bearer token
apiClient.interceptors.request.use(
  async (config) => {
    const user_token = localStorage.getItem("auth_token");
    const switch_user_token = localStorage.getItem("switch_user_token");
    const token =
      switch_user_token && config.useSwitchUserToken
        ? switch_user_token
        : user_token;

    if (token && isTokenExpired(token)) {
      const refreshed = await refreshToken();
      if (refreshed) {
        config.headers.Authorization = `Bearer ${refreshed.access}`;
      }
    } else if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (config.contentType) {
      config.headers["Content-Type"] = config.contentType;
    }
    return config;
  },
  (error) => {
    // Handle errors here
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response?.status === 401 &&
      error.config.url.includes("/auth/token/refresh/")
    ) {
      window.location.href = "/registration/login";
      localStorage.clear();
    }
    return Promise.reject(error);
  },
);
