import { Card, Spinner } from "react-bootstrap";

import "./LoadersStyles.scss";

const Loading = ({ children, variant, fullScreen = false }) => {
  return (
    <div className={`${fullScreen && "on-above"}`}>
      <div className="flex-center">
        <Card
          border="secondary"
          className="text-center custom-loading-width shadow-sm"
        >
          <Card.Header>
            <Spinner
              animation="border"
              aria-hidden="true"
              role="status"
              variant={variant}
            />
          </Card.Header>
          {children}
        </Card>
      </div>
    </div>
  );
};

export { Loading };
