import { apiClient } from "api/api";

import {
  GET_ERRORS,
  GET_OPK_FORM_DATA,
  GET_SUCCESS,
  OPK_FORM_DATA_LOADING_OFF,
  OPK_FORM_DATA_SET_LOADING,
  SAVE_OPK_FORM_DATA,
} from "../types";
import { SUCCESS_AND_CHECK_ERRORS_INFO } from "utils/toastMessages";

export const getOpkFormData = (procedureID, opkID) => async (dispatch) => {
  try {
    dispatch({ type: OPK_FORM_DATA_SET_LOADING });

    const response = await apiClient.get(
      `/fk/${procedureID}/opk/${opkID}/opk_edit`,
      { useSwitchUserToken: true },
    );
    dispatch({
      payload: response.data,
      type: GET_OPK_FORM_DATA,
    });
  } catch (err) {
    if (err.response) {
      console.log(err.response.data);
      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };

      dispatch({
        payload: errors,
        type: GET_ERRORS,
      });
    } else {
      console.log({ err });
    }
  }
};

export const saveOpkFormData =
  (procedureID, opkID, submitedFormData) => async (dispatch) => {
    dispatch({ type: OPK_FORM_DATA_SET_LOADING });
    try {
      const response = await apiClient.patch(
        `/fk/${procedureID}/opk/${opkID}/opk_edit/`,
        submitedFormData,
        { useSwitchUserToken: true },
      );
      dispatch({
        payload: {
          msg: SUCCESS_AND_CHECK_ERRORS_INFO,
          status: response.status,
        },
        type: GET_SUCCESS,
      });

      dispatch({
        payload: response.data,
        type: SAVE_OPK_FORM_DATA,
      });
    } catch (err) {
      console.log(err.response, err.response.data);
      dispatch({ type: OPK_FORM_DATA_LOADING_OFF });
      dispatch({
        payload: err.response,
        type: GET_ERRORS,
      });
    }
  };
