import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import BreadcrumbComponent from "./BreadcrumbComponent";

const BREADCRUMBS_LABELS = {
  materialy: "Materiały",
  FAQ: "Najczęściej zadawane pytania",
  proceedingNumber: "Postępowanie nr:",
  proceeding: "Postępowanie",
  opkDetails: "Szczegółowe dane OPK",
  userData: "Dane ogólne",
  newOpk: "Dodawanie OPK",
  sitemap: "Mapa strony",
  passwordRecovery: "Odzyskiwanie hasła",
  passwordReset: "Potwierdzenie resetu hasła",
  opkUpload: "Import Excel",
  wcag: "Dostępność cyfrowa"
};

const BreadcrumbContainer = ({ breadcrumbLabel = "" }) => {
  const { pathname } = useLocation();
  const { isAuthenticated } = useSelector((state) => state.authReducer);

  const handleUserProfilePath = (path) => {
    const proceedingData = JSON.parse(localStorage.getItem("proceedingData") || "{}");

    if (proceedingData?.type === "fk") {
      path.push({
        label: `${BREADCRUMBS_LABELS.proceedingNumber} ${proceedingData.name}`,
        href: `/fk/${proceedingData.id}/opk`,
      });
    } else if (proceedingData?.type === "daneszcz") {
      path.push({
        label: `${BREADCRUMBS_LABELS.proceedingNumber} ${proceedingData.name}`,
        href: `/daneszcz/${proceedingData.id}/specific_data_form`,
      });
    }
    path.push({ label: BREADCRUMBS_LABELS.userData, href: null });
  };

  const handleFkPath = (path, segments) => {
    const postId = segments[1];

    if (segments.includes("opk")) {
      path.push({
        label: BREADCRUMBS_LABELS.proceeding,
        href: `/fk/${postId}/opk`,
      });

      if (segments.length >= 5) {
        path.push({ label: BREADCRUMBS_LABELS.opkDetails, href: null });
      }
    } else if (segments.includes("opk_swd")) {
      path.push({ label: BREADCRUMBS_LABELS.newOpk, href: null });
    } else if (segments.includes("upload_xlsx")) {
      path.push({
        label: BREADCRUMBS_LABELS.proceeding,
        href: `/fk/${postId}/opk`,
      });
      path.push({ label: BREADCRUMBS_LABELS.opkUpload, href: null });
    }
  };

  const handleInstructionPath = (path, segments) => {
    path.push({
      label: BREADCRUMBS_LABELS[segments[1]],
      href: `/${segments[0]}/${segments[1]}`,
    });

    if (segments.length >= 3) {
      path.push({ label: breadcrumbLabel || segments[2], href: null });
    }
  };

  const handleSimplePath = (path, label) => {
    path.push({ label: BREADCRUMBS_LABELS[label], href: null });
  };

  const generateBreadcrumbPath = useMemo(() => {
    const segments = pathname.split("/").filter(Boolean);
    const path = [
      {
        label: isAuthenticated ? "Strona główna" : "Strona logowania",
        href: "/",
      },
    ];

    const rules = [
      {
        condition: () => pathname.endsWith("/user_profile"),
        handler: () => handleUserProfilePath(path),
      },
      {
        condition: () => pathname.startsWith("/fk"),
        handler: () => handleFkPath(path, segments),
      },
      {
        condition: () => pathname.startsWith("/daneszcz"),
        handler: () => handleSimplePath(path, "proceeding"),
      },
      {
        condition: () => pathname.startsWith("/instrukcja"),
        handler: () => handleInstructionPath(path, segments),
      },
      {
        condition: () => pathname.startsWith("/sitemap"),
        handler: () => handleSimplePath(path, "sitemap"),
      },
      {
        condition: () => pathname.startsWith("/wcag"),
        handler: () => handleSimplePath(path, "wcag"),
      },
      {
        condition: () => pathname.endsWith("/forgot-password"),
        handler: () => handleSimplePath(path, "passwordRecovery"),
      },
      {
        condition: () => pathname.includes("password_reset_confirm"),
        handler: () => handleSimplePath(path, "passwordReset"),
      },
    ];

    rules.forEach(({ condition, handler }) => {
      if (condition()) handler();
    });

    return path;
  }, [pathname, isAuthenticated, breadcrumbLabel]);

  return <BreadcrumbComponent path={generateBreadcrumbPath} />;
};

export default BreadcrumbContainer;
