import {
  GET_TAB3_FORM_DATA,
  SAVE_TAB3_FORM_DATA,
  TAB3_FORM_DATA_SET_LOADING,
  TAB3_LOADING_OFF,
} from "../../../actions/types.js";

const initialState = {
  loading: false,
  Tab3Data: [],
  unsavedRows: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TAB3_FORM_DATA:
      return {
        ...state,
        loading: false,
        Tab3Data: action.payload,
      };
    case SAVE_TAB3_FORM_DATA:
      return {
        ...state,
        loading: false,
        Tab3Data: action.payload,
        unsavedRows: action.payload?.unsavedRows || [],
      };
    case TAB3_FORM_DATA_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case TAB3_LOADING_OFF:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
