import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";

import { areFieldsFilled } from "utils/validators";
import { REQUIRED_GENERAL_FIELDS as REQUIRED_FIELDS } from "../../utils/constants";
import { confirmSDData } from "actions/SpecificDataActions/confirmSDDataActions";
import { SmalModal } from "components/Modal/SmalModal";
import { handleSaveProceedingDataToLS } from "utils/localStorageUtils";

const SDSidebarButtons = ({ procedureID }) => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const confirmSpecyficDataStatus = useSelector(
    (state) => state.specificDataConfirmDataReducer.confirmSpecyficDataStatus,
  );
  const proceedingData = useSelector(
    (state) =>
      state.specificDataViewControllerReducer.specificDataControllerData
        .proceeding,
  );
  const opk_User = useSelector((state) => state.opkUserProfileReducer.opk_User);

  useEffect(() => {
    if (confirmSpecyficDataStatus === 200) {
      const redirect = setTimeout(() => {
        navigate(`/daneszcz/${procedureID}`);
      }, 3000);

      return () => clearTimeout(redirect);
    }
  }, [navigate, procedureID, confirmSpecyficDataStatus]);

  const handleModalShow = () => setShowModal((prevState) => !prevState);
  const handleAccept = () => {
    handleModalShow();
    dispatch(confirmSDData(procedureID));
  };

  const checkOpkUserData = () => {
    const profile =
      Array.isArray(opk_User?.profile) && opk_User.profile.length > 0
        ? opk_User.profile[0]
        : null;
    return profile ? areFieldsFilled(profile, REQUIRED_FIELDS) : false;
  };

  return (
    <>
      <div className="dropdownMenu" id="sidemenu">
        <Link to="/fk/user_profile" tabIndex="-1">
          <Button
            className="btn-aotmit w-100"
            size="sm"
            type="button"
            variant={
              checkOpkUserData() ? "outline-secondary" : "outline-danger"
            }
            onClick={handleSaveProceedingDataToLS(
              procedureID,
              proceedingData?.typ_danych,
              proceedingData?.postepowanie_nr,
            )}
          >
            Dane ogólne
          </Button>
        </Link>

        <Button
          className="mt-2 w-100"
          color="success"
          onClick={() => handleModalShow()}
          size="sm"
          type="button"
          variant="outline-success"
        >
          Prześlij do AOTMiT
        </Button>
      </div>
      <SmalModal
        handleAccept={handleAccept}
        handleClose={handleModalShow}
        modalBodyText={
          <p className="text-center">
            Czy na pewno chcesz przesłać dane do AOTMiT?
          </p>
        }
        show={showModal}
      />
    </>
  );
};

export default SDSidebarButtons;
