import { useState } from "react";

import { Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const PasswordFormControl = ({ formik, fieldName, placeholderText }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible((passwordVisible) => !passwordVisible);
  };

  return (
    <Form.Group>
      <div className=" d-flex align-items-center">
        <Form.Control
          aria-label={fieldName}
          className="my-2"
          id={fieldName}
          type={passwordVisible ? "text" : "password"}
          placeholder={placeholderText}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[fieldName]}
          isInvalid={formik.touched[fieldName] && formik.errors[fieldName]}
        />
        <Button
          onClick={togglePasswordVisibility}
          className="m-1"
          variant="light"
        >
          <FontAwesomeIcon
            icon={passwordVisible ? faEye : faEyeSlash}
            id={fieldName}
          />
        </Button>
      </div>
      {formik.touched[fieldName] && (
        <div>
          <Form.Control.Feedback type="invalid" className="d-block">
            {formik.errors[fieldName]}
          </Form.Control.Feedback>
        </div>
      )}
    </Form.Group>
  );
};
export default PasswordFormControl;
