import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Card } from "react-bootstrap";

const SlagSelectedQuestions = () => {
  const { slug } = useParams();
  const faq = useSelector((state) => state.faqReducer.faq || []);

  return (
    <>
      {faq
        .filter((el) => el.kategoria.some((el) => el.slug === slug))
        .map((faq_element, faq_idx) => (
          <Card key={faq_idx}>
            <Card.Header>{faq_element.pytanie}</Card.Header>
            <Card.Body>{faq_element.odpowiedz}</Card.Body>
          </Card>
        ))}
    </>
  );
};

export default SlagSelectedQuestions;
