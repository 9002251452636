import { SET_LOADING_REQUEST_STATUS } from "./types";

export const setRequestStatus = (alreadyLoaded, totalSize, requestType) => {
  return {
    payload: {
      alreadyLoaded: alreadyLoaded,
      requestType: requestType,
      totalSize: totalSize,
    },
    type: SET_LOADING_REQUEST_STATUS,
  };
};
