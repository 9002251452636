import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Table } from "react-bootstrap";

import { getProceedings } from "../../actions/landingPageActions";
import { getOpkUserProfile } from "actions/OpkUserProfileActions";
import { Loading } from "../../components/Loading/loading";
import { render_veryfication } from "../../utils/utils";
import SkipLinks from "../../components/SkipLinks/SkipLinks";
import InfoModalWithLogo from "components/Modal/InfoModalWithLogo";

const LandingPageContent = ({ isSwitchedUser, loading, proceedings }) => {
  const [proceedingInfo, setProceedingInfo] = useState({
    open: false,
    data: {},
  });
  const navigate = useNavigate();

  const handleDisplayInfoOrOpen = (proceeding) => {
    const openProceeding = () => {
      return navigate(
        `${proceeding.postepowanie.typ_danych}/${proceeding.postepowanie.id}/`,
      );
    };
    if (proceeding.verifications === null) {
      return openProceeding();
    }
    switch (proceeding.verifications?.czy_otwarte) {
      case 0:
        openProceeding();
        return;
      case 1:
        setProceedingInfo({
          open: true,
          data: {
            title: `Dane postępowania ${proceeding.postepowanie.postepowanie_nr} zostały przesłane do weryfikacji.`,
            description:
              "Czasowo możliwość przeglądania strony została wyłączona, zostaną Państwo poinformowani drogą mailową o zmianie statusu weryfikacji.",
          },
        });
        return;
      case 2:
        setProceedingInfo({
          open: true,
          data: {
            title: `Dane postępowania ${proceeding.postepowanie.postepowanie_nr} zostały zatwierdzone.`,
            description:
              "Nie mają Państwo uprawnień do przeglądania tej strony, możliwość edycji została wyłączona.",
          },
        });
        return;
      case 3:
        setProceedingInfo({
          open: true,
          data: {
            title: `Dane postępowania ${proceeding.postepowanie.postepowanie_nr} zostały odrzucone.`,
            description:
              "Nie mają Państwo uprawnień do przeglądania tej strony, możliwość edycji została wyłączona.",
          },
        });
        return;
      default:
        setProceedingInfo({
          open: true,
          data: {
            title: "Wystąpił błąd podczas otwierania danych postępowania.",
            description: "Proszę odświeżyć stronę i spróbować ponownie.",
          },
        });
    }
  };

  const handleProceedingClick = (proceeding) => {
    if (isSwitchedUser) {
      navigate(
        `${proceeding.postepowanie.typ_danych}/${proceeding.postepowanie.id}/`,
      );
    } else {
      handleDisplayInfoOrOpen(proceeding);
    }
  };
  if (loading === true) {
    return (
      <Loading variant="info">
        <span className="m-2">
          Informacja o postępowaniach jest pobierana z serwera...
        </span>
      </Loading>
    );
  } else if (proceedings.length === 0) {
    return <>Nie ma w tej chwili postępowań, w których jesteś uczestnikiem.</>;
  } else {
    return (
      <>
        <div className="table-responsive">
          <Table borderless hover>
            <thead>
              <tr>
                <th>Postępowanie nr:</th>
                <th>Typ danych:</th>
                <th>Rok:</th>
                {isSwitchedUser && <th>Postępowanie nr:</th>}
              </tr>
            </thead>
            <tbody>
              {proceedings.map((proceeding) => (
                <tr
                  tabIndex="0"
                  key={proceeding.postepowanie.id}
                  onClick={() => handleProceedingClick(proceeding)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleProceedingClick(proceeding);
                    }
                  }}
                >
                  <td>{proceeding.postepowanie.postepowanie_nr}</td>
                  <td>{proceeding.postepowanie.typ_danych}</td>
                  <td>{proceeding.postepowanie.postepowanie_rok}</td>
                  {isSwitchedUser && (
                    <td>
                      {proceeding.verifications &&
                        render_veryfication(proceeding.verifications)}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <InfoModalWithLogo
          show={proceedingInfo.open}
          handleClose={() => setProceedingInfo({ open: false, data: {} })}
          modalTitle={proceedingInfo.data?.title}
          modalBodyText={proceedingInfo.data?.description}
        />
      </>
    );
  }
};

const LandingPage = () => {
  const dispatch = useDispatch();
  const isSwitchedUser = useSelector(
    (state) => state.switchUserReducer.isSwitchedUser,
  );
  const loading = useSelector((state) => state.landingPageReducer.loading);
  const proceedings = useSelector(
    (state) => state.landingPageReducer.proceedings,
  );

  useEffect(() => {
    if (!proceedings.length) {
      dispatch(getProceedings());
    } else {
      dispatch(getOpkUserProfile());
    }
  }, [dispatch, proceedings.length, isSwitchedUser]);

  const skipLinks = [
    {
      id: "#listaPostepowan",
      desc: "Przejdź do sekcji głównej - postępowania.",
    },
  ];

  return (
    <>
      <SkipLinks skipLinks={skipLinks} />
      <div className="full-container my-5">
        <div className="my-5">
          <h3>Witamy w aplikacji Dane-kosztowe</h3>
          <p>
            Poniżej znajduje się lista postępowań do których otrzymali Państwo
            dostęp.
          </p>
        </div>

        <main id="listaPostepowan">
          <LandingPageContent
            isSwitchedUser={isSwitchedUser}
            loading={loading}
            proceedings={proceedings}
          />
        </main>
      </div>
    </>
  );
};

export default LandingPage;
