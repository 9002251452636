import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Spinner } from "react-bootstrap";

import { getExcelStatistics } from "../../../actions/StaffUserViewsActions/getExcelStatisticsActions";
import { getSwitchedUserInfo } from "../../../actions/StaffUserViewsActions/switchedUserInfoActions";
import { render_veryfication } from "../../../utils/utils";
import { Loading } from "../../../components/Loading/loading";

const SwitchUserMenu = ({ procedureID }) => {
  const dispatch = useDispatch();

  const [excelStatisticsLoading, setExcelStatisticsLoading] = useState(false);

  const loading = useSelector(
    (state) => state.switchedUserInfoReducer.switchedUserInfoLoading,
  );
  const switchedUserInfo = useSelector(
    (state) => state.switchedUserInfoReducer.switchedUserInfo,
  );

  useEffect(() => {
    dispatch(getSwitchedUserInfo(procedureID));
  }, [dispatch, procedureID]);

  const handleDownload = async () => {
    setExcelStatisticsLoading(true);
    try {
      await dispatch(getExcelStatistics(procedureID));
    } finally {
      setExcelStatisticsLoading(false);
    }
  };

  return (
    <div className="switchUserMenu">
      <Button
        className="btn-aotmit w-100"
        onClick={handleDownload}
        size="sm"
        type="button"
        variant="outline-secondary"
      >
        Generuj Excel ze statystykami
      </Button>
      <div className="swd-info">
        {!switchedUserInfo && loading ? (
          <Spinner
            animation="border"
            aria-hidden="true"
            role="status"
            variant="info"
          />
        ) : (
          <>
            {switchedUserInfo.verifications && (
              <>
                <span>Startus weryfikacji:</span>
                {render_veryfication(switchedUserInfo.verifications)}
              </>
            )}
            <span>Numer ankiety:</span>

            {switchedUserInfo.survey_number ? (
              <p className="text-muted font-small mb-0">
                {switchedUserInfo.survey_number}
              </p>
            ) : (
              <p className="text-muted font-small mb-0">Nie dodano</p>
            )}
          </>
        )}
      </div>
      {excelStatisticsLoading && (
        <Loading variant="success" fullScreen={true}>
          <span className="m-2">
            <p>Trwa generowanie pliku. Proszę czekać...</p>
          </span>
        </Loading>
      )}
    </div>
  );
};

export default SwitchUserMenu;
