import { useCallback, useState } from "react";

const initialContentData = {
  title: "",
  message: "",
  itemsList: [],
  children: null,
};

const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState(initialContentData);

  const openModal = useCallback(
    ({ title = "", message = "", itemsList = [], children = null }) => {
      setModalContent({ title, message, itemsList, children });
      setIsOpen(true);
    },
    [],
  );

  const closeModal = useCallback(() => {
    setIsOpen(false);
    setModalContent(initialContentData);
  }, []);

  return { isOpen, modalContent, openModal, closeModal };
};

export default useModal;
