import { useSelector } from "react-redux";

import TabForm from "./TabForm";
import {
  getAnalyticsFormData,
  saveAnalyticsFormData,
} from "../../../actions/OpkFormsActions/AnalyticsFormActions";
import { AnalyticsFormFields } from "../OpkForms/FormSettings";
import { analyticsValidationSchema } from "../../../utils/validationSchemas";

const AnalyticsForm = () => {
  const loading = useSelector((state) => state.analyticsFormReducer.loading);
  const { analytics_objects, unsavedRows } = useSelector(
    (state) => state.analyticsFormReducer.AnalyticsData,
  );

  return (
    <TabForm
      getFormData={getAnalyticsFormData}
      saveFormData={saveAnalyticsFormData}
      formFields={AnalyticsFormFields}
      loading={loading}
      formData={analytics_objects}
      unsavedRows={unsavedRows}
      validationSchema={analyticsValidationSchema}
    />
  );
};

export default AnalyticsForm;
