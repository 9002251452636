import { apiClient } from "api/api";
import {
  AUTH_ERROR,
  GET_ERRORS,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  SWITCH_USER_LOGOUT_SUCCESS,
  USER_LOADED,
  CLEAR_PROCEEDINGS,
  AUTH_LOADING,
} from "../actions/types";

const getCsrfToken = () => {
  return document.cookie
    .split("; ")
    .find((row) => row.startsWith("csrftoken="))
    ?.split("=")[1];
};

export const loginUser = (formData) => async (dispatch) => {
  dispatch({ type: AUTH_LOADING });

  const config = {
    data: formData,
    method: "post",
    url: `/auth/login/`,
    headers: {
      "X-CSRFToken": getCsrfToken(),
    },
  };

  try {
    const response = await apiClient(config);
    localStorage.setItem("auth_token", response.data.access);
    localStorage.setItem("refresh_token", response.data.refresh);
    dispatch({
      payload: response.data,
      type: LOGIN_SUCCESS,
    });

    dispatch(loadUser());
  } catch (err) {
    const errors = {
      msg: err.response.data,
      status: err.response.status,
    };
    dispatch({
      payload: errors,
      type: GET_ERRORS,
    });

    dispatch({
      type: LOGIN_FAIL,
    });
    dispatch({
      type: SWITCH_USER_LOGOUT_SUCCESS,
    });
  }
};

export const loadUser = () => async (dispatch) => {
  dispatch({ type: AUTH_LOADING });
  try {
    const response = await apiClient.get(`/auth/users/me/`);
    dispatch({
      payload: response.data,
      type: USER_LOADED,
    });
  } catch (err) {
    if (err.response) {
      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };
      dispatch({
        payload: errors,
        type: GET_ERRORS,
      });

      dispatch({
        type: AUTH_ERROR,
      });
    }
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    const refresh = localStorage.getItem("refresh_token");
    await apiClient.post("/auth/logout/", { refresh });
    dispatch({
      type: LOGOUT_SUCCESS,
    });
    dispatch({
      type: CLEAR_PROCEEDINGS,
    });
  } finally {
    localStorage.clear();
    dispatch({ type: "RESET_STORE" });
  }
};

export const resetUserPassword = (formData) => async () => {
  const config = {
    data: formData,
    method: "post",
    url: `/auth/password_reset/`,
  };
  try {
    const response = await apiClient(config);
    return response.data;
  } catch (err) {
    throw (
      err.response?.data ||
      new Error("Unexpected error with resetUserPassword.")
    );
  }
};

export const setNewPassword = (formData, url) => async () => {
  const config = {
    data: formData,
    method: "post",
    url,
  };
  try {
    const response = await apiClient(config);
    return response.data;
  } catch (err) {
    throw (
      err.response?.data || new Error("Unexpected error with setNewPassword.")
    );
  }
};
