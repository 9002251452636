import { Button, Modal } from "react-bootstrap";

import logo from "../../assets/img/aotm_50px.png";

const InfoModalWithLogo = ({
  show,
  handleClose,
  modalTitle,
  modalBodyText,
  size = "md",
}) => {
  return (
    <>
      <Modal
        centered
        onHide={handleClose}
        show={show}
        size={size}
        animation={false}
      >
        <Modal.Header closeButton>
          <div className="d-flex align-items-center">
            <img
              alt="AOTMiT logo"
              className="d-inline-block align-top"
              height="50"
              src={logo}
              width="50"
            />
            <h4 className="m-1">Szanowni Państwo</h4>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center mb-2">
            <strong>{modalTitle}</strong>
          </div>
          <div className="text-center">{modalBodyText}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleClose}>
            Zamknij
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InfoModalWithLogo;
