import React, { useEffect } from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";

const DashboardMainPage = () => {
  const location = useLocation();
  const mainPanel = React.useRef();
  const sidebar = React.useRef();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
  }, [location]);

  return (
    <div className="wrapper">
      <div className="sidebar">
        <div className="sidebar-wrapper" ref={sidebar}>
          <ul className="nav">
            <li>
              <NavLink to="/panel/przywracanie_do_poprawy">
                <span className="sidebar-normal">
                  Przywracanie listy OPK do poprawy
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/panel/nowe_konta">
                <span className="sidebar-normal">Nowe konta</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/panel/weryfikacja_uczestnictw">
                <span className="sidebar-normal">Weryfikacje</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/panel/raport_postepowania">
                <span className="sidebar-normal">Raport postepowania</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="main-panel" ref={mainPanel}>
        <Outlet />
      </div>
    </div>
  );
};

export default DashboardMainPage;
