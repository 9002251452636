import { apiClient } from "api/api";
import {
  GET_ERRORS,
  GET_PROCEEDINGS,
  LANDING_SET_LOADING,
  LANDING_SET_LOADING_OFF,
} from "./types";

let controller;

export const getProceedings = () => async (dispatch) => {
  if (controller) {
    controller.abort();
  }

  controller = new AbortController();
  const signal = controller.signal;

  try {
    dispatch({
      type: LANDING_SET_LOADING,
    });

    const response = await apiClient.get(`/user_proceedings/`, {
      useSwitchUserToken: true,
      signal,
    });

    dispatch({
      payload: response.data,
      type: GET_PROCEEDINGS,
    });
  } catch (err) {
    if (err.code === "ERR_CANCELED") return;

    const errors = {
      msg: err.response?.data || "Request failed",
      status: err.response?.status || 500,
    };

    dispatch({
      payload: errors,
      type: GET_ERRORS,
    });
    dispatch({
      type: LANDING_SET_LOADING_OFF,
    });
  }
};
