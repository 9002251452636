import {
  GET_ERRORS,
  GET_SPECIFIC_DATA_VIEW_CONTROLLER_DATA,
  SPECIFIC_DATA_VIEW_CONTROLLER_SET_LOADING,
} from "actions/types";
import { apiClient } from "api/api";

export const getSpecificDataViewControllerData =
  (procedureID) => async (dispatch) => {
    try {
      dispatch({ type: SPECIFIC_DATA_VIEW_CONTROLLER_SET_LOADING });

      const response = await apiClient.get(
        `/daneszcz/${procedureID}/specific_data_procedure_controller_api_view/`,
        { useSwitchUserToken: true },
      );
      dispatch({
        payload: response.data,
        type: GET_SPECIFIC_DATA_VIEW_CONTROLLER_DATA,
      });
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
        const errors = {
          msg: err.response.data,
          status: err.response.status,
        };

        dispatch({
          payload: errors,
          type: GET_ERRORS,
        });
      } else {
        console.log({ err });
      }
    }
  };
