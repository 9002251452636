import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Form, Accordion } from "react-bootstrap";
import {
  faCog,
  faExclamation,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { uploadXlsx } from "../../../actions/OpkFormsActions/opkUploadXLSXActions";
import { getOpkData } from "../../../actions/opkListActions";
import { Loading } from "../../../components/Loading/loading";
import BreadcrumbContainer from "components/Breadcrumbs/BreadcrumbContainer";
import CustomModal from "components/Modal/CustomModal";
import SwitchUserMenu from "../StaffUserViews/switchUserMenu";
import useModal from "hooks/useModal";

const OpkImportXLSX = () => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [uploadedFile, setUploadedFile] = useState(null);

  const { isOpen, modalContent, openModal, closeModal } = useModal();

  const { procedureID } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isSwitchedUser = useSelector(
    (state) => state.switchUserReducer.isSwitchedUser,
  );
  const loading_xlsx = useSelector(
    (state) => state.opkUploadXLSXReducer.loading,
  );
  const opk_list = useSelector((state) => state.opkListReducer.data.opk_list);
  const proceeding = useSelector(
    (state) => state.opkListReducer.data.proceeding,
  );

  const handleSideBarToggler = () => {
    setShowSidebar((prevState) => !prevState);
  };

  const handleFileInput = (file) => {
    setUploadedFile(file);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData();
    data.append("xls_upload", uploadedFile);

    dispatch(uploadXlsx(procedureID, data)).then((response) => {
      if (response) {
        openModal({
          title: response?.title,
          message: response?.message,
        });
      }
    });
  };

  useEffect(() => {
    dispatch(getOpkData(procedureID));
  }, [dispatch, procedureID]);

  return (
    <>
      <div className="fixedPlugin" tabIndex="0" onClick={handleSideBarToggler}>
        <FontAwesomeIcon icon={faCog} size="2x" />
      </div>

      {showSidebar && (
        <div className="dropdownMenu">
          <Button
            className="btn-aotmit w-100"
            onClick={() => navigate(-1)}
            size="sm"
            type="button"
            variant="outline-success"
          >
            Powrót
          </Button>
          {isSwitchedUser && <SwitchUserMenu procedureID={procedureID} />}
        </div>
      )}
      <div className="full-container--lm">
        <BreadcrumbContainer />
      </div>
      <div className="full-container--lm">
        <div>
          <h3>
            {proceeding &&
              `Postepowanie nr: ${proceeding.postepowanie_nr}, ${proceeding.postepowanie_rok}`}
          </h3>

          {opk_list && opk_list.length !== 0 ? (
            <p>Liczba kont OPK: {opk_list.length}</p>
          ) : (
            <div className="border-box">
              <div className="border-box-title text-warning">
                <FontAwesomeIcon icon={faInfo} size="1x" />
              </div>
              <div className="border-box-content">
                <h6>Nie dodano jeszcze kont OPK w tym Postępowaniu</h6>
              </div>
            </div>
          )}
        </div>
        <main>
          <div className="border-box">
            <div className="border-box-title text-warning">
              UWAGA <FontAwesomeIcon icon={faExclamation} size="1x" />
            </div>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Instrukcja wgrywania plików FK
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>
                      Wgrywany plik FK powinien być stworzony na szablonie
                      pobranym po wybraniu przycisku{" "}
                      <strong>POBIERZ WZÓR FK.</strong>
                    </li>
                    <li>
                      W nazwie pliku FK nie wolno stosować polskich znaków.
                    </li>
                    <li>
                      Plik FK można wgrywać wielokrotnie, pamiętając, że dane
                      się nadpisują, tj. poprzednio wgrane dane zostaną
                      usunięte.
                    </li>
                    <li>
                      Jeśli plik FK uzupełniają pracownicy różnych komórek,
                      niezbędny jest koordynator, który wgra całość jako jeden
                      plik za pomocą <strong>IMPORT EXCEL.</strong>
                    </li>
                    <li>
                      Po zaimportowaniu pliku prosimy sprawdzić, czy dane
                      wczytały się prawidłowo. W tym celu należy wybrać{" "}
                      <strong>POWRÓT</strong> i upewnić się, czy OPK zawierają
                      wymagane dane.
                    </li>
                    <li>
                      Jeśli dane z pliku FK zaczytały się prawidłowo, należy
                      przesłać je do weryfikacji wybierając{" "}
                      <strong>PRZEŚLIJ DO AOTMiT.</strong> Dostęp do strony
                      zostanie na czas weryfikacji tymczasowo zamknięty.
                    </li>
                    <li>
                      Jeżeli w trakcie importu lub po wgraniu pliku wyświetli
                      się błąd, zanim skontaktujecie się Państwo z AOTMiT,
                      prosimy wybrać <strong>POWRÓT</strong> i sprawdzić czy
                      dane się wgrały, pomimo komunikatu z błędem.
                    </li>
                    <li>
                      Jeśli plik FK się nie wgrał, prosimy o kontakt na adres{" "}
                      <u>dane-kosztowe@aotm.gov.pl</u>
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          {loading_xlsx ? (
            <Loading variant="success" fullScreen={true}>
              <span className="m-2">
                <p>Trwa przesyłanie pliku. Proszę czekać...</p>
              </span>
            </Loading>
          ) : (
            <Form
              onSubmit={(e) => handleSubmit(e)}
              className="my-3 d-flex gap-3"
            >
              <Form.Group controlId="inputGroupFile01" className="w-100">
                <Form.Control
                  type="file"
                  onChange={(e) => handleFileInput(e.target.files[0])}
                />
              </Form.Group>
              <Button type="submit" variant="outline-success">
                Zapisz
              </Button>
            </Form>
          )}
        </main>
      </div>
      <CustomModal
        show={isOpen}
        onHide={closeModal}
        modalContent={modalContent}
      />
    </>
  );
};

export default OpkImportXLSX;
