import { apiClient } from "api/api";

import {
  ANALYTICS_FORM_DATA_SET_LOADING,
  ANALYTICS_FORM_LOADING_OFF,
  GET_ANALYTICS_FORM_DATA,
  GET_ERRORS,
  GET_SUCCESS,
  SAVE_ANALYTICS_FORM_DATA,
} from "../types";
import {
  SUCCESS_AND_CHECK_ERRORS_INFO,
  PARTIAL_CONTETNT_INFO,
} from "../../utils/toastMessages";
import {
  handleErrorsForModalContent,
  handleFormErrors,
  isPartialContent,
  updateFormStateFromResponse,
} from "../../utils/helpers";

export const getAnalyticsFormData =
  (procedureID, opkID) => async (dispatch) => {
    try {
      dispatch({ type: ANALYTICS_FORM_DATA_SET_LOADING });

      const response = await apiClient.get(
        `/fk/${procedureID}/opk/${opkID}/analyticsedit/`,
        { useSwitchUserToken: true },
      );
      dispatch({
        payload: response.data,
        type: GET_ANALYTICS_FORM_DATA,
      });
    } catch (err) {
      if (err.response) {
        console.log("Analytics", err.response.data);
        const errors = {
          msg: err.response.data,
          status: err.response.status,
        };

        dispatch({
          payload: errors,
          type: GET_ERRORS,
        });
      } else {
        console.log({ err });
      }
    }
  };

export const saveAnalyticsFormData =
  (procedureID, opkID, submitedFormData) => async (dispatch) => {
    try {
      dispatch({ type: ANALYTICS_FORM_DATA_SET_LOADING });

      const response = await apiClient.patch(
        `/fk/${procedureID}/opk/${opkID}/analyticsedit/`,
        submitedFormData,
        { useSwitchUserToken: true },
      );
      const partialContent = isPartialContent(response.status);

      dispatch({
        payload: {
          msg: partialContent
            ? PARTIAL_CONTETNT_INFO
            : SUCCESS_AND_CHECK_ERRORS_INFO,
          status: response.status,
        },
        type: GET_SUCCESS,
      });

      updateFormStateFromResponse(
        dispatch,
        response.data,
        "analytics_objects",
        SAVE_ANALYTICS_FORM_DATA,
      );

      if (partialContent) {
        return handleErrorsForModalContent(response.data);
      }
    } catch (err) {
      dispatch({ type: ANALYTICS_FORM_LOADING_OFF });
      const errorData = err.response.data;
      handleFormErrors(
        dispatch,
        errorData,
        "analytics_objects",
        SAVE_ANALYTICS_FORM_DATA,
      );
      return handleErrorsForModalContent(errorData);
    }
  };
