import {
  GET_LIST_FOR_VERIFICATION,
  LIST_FOR_VERIFICATION_SET_LOADING,
} from "../../../actions/types.js";

const initialState = {
  loading: true,
  proceedings_for_veryfication: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_FOR_VERIFICATION:
      return {
        ...state,
        loading: false,
        proceedings_for_veryfication: action.payload,
      };
    case LIST_FOR_VERIFICATION_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
export default reducer;
