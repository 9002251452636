import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  getTab1FormData,
  saveTab1FormData,
} from "../../../actions/OpkFormsActions/tab1FormActions";
import { Loading } from "../../../components/Loading/loading";
import { Tab1FormFields } from "../OpkForms/FormSettings";
import OpkColumnForm from "../OpkForms/OpkColumnForm";

const Tab1Form = () => {
  const { opkID, procedureID } = useParams();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.tab1FormReducer.loading);
  const tab1_object = useSelector(
    (state) => state.tab1FormReducer.Tab1Data.tab1_object,
  );

  useEffect(() => {
    dispatch(getTab1FormData(procedureID, opkID));
  }, [dispatch, procedureID, opkID]);

  const saveData = async (submitedFormData) => {
    dispatch(saveTab1FormData(procedureID, opkID, submitedFormData));
  };

  return (
    <>
      {loading ? (
        <Loading variant="danger">
          <span className="m-2">Wczytywanie danych. Proszę czekać...</span>
        </Loading>
      ) : (
        <OpkColumnForm
          dataSubmit={saveData}
          formFields={Tab1FormFields}
          opk_object={tab1_object}
        />
      )}
    </>
  );
};

export default Tab1Form;
