import {
  getAnalyticsData,
  saveAnalyticsFormData,
} from "actions/AnalyticsActions/AnalyticsDataActions";
import { postExcelFilesAnalytics } from "actions/AnalyticsActions/AnalyticsUplaoadExcel";
import { SmalModal } from "components/Modal/SmalModal";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";

import GeneratedForm from "../../components/GeneratedForm/GeneratedForm";
import { AnalyticsFormFields } from "../../utils/formFields";

const AnalyticsPrecedureForm = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");

  const { procedureID } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();

  const analytics_objects = useSelector(
    (state) => state.analyticsDataReducer.AnalyticsData.analytics_objects,
  );

  const mainPanel = React.useRef();
  const sidebar = React.useRef();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
  }, [location]);

  useEffect(() => {
    dispatch(getAnalyticsData(procedureID));
  }, [dispatch, procedureID]);

  const handleModalShow = () => setShowModal((prevState) => !prevState);
  const handleAccept = () => {
    handleModalShow();
  };

  const sendExcelWithAnalytics = (file, procedureID) => {
    dispatch(postExcelFilesAnalytics(file, procedureID));
  };

  const saveData = async (submitedFormData) => {
    dispatch(saveAnalyticsFormData(procedureID, submitedFormData));
  };

  return (
    <div className="wrapper">
      <div className="sidebar">
        <div className="sidebar-wrapper px-4 mx-auto" ref={sidebar}>
          <Button
            className="mt-2 w-100"
            onClick={() => handleModalShow()}
            size="sm"
            type="button"
            variant="outline-success"
          >
            Prześlij do AOTMiT
          </Button>
          <SmalModal
            handleAccept={handleAccept}
            handleClose={handleModalShow}
            modalBodyText={
              <p className="text-center">
                Czy na pewno chcesz przesłać dane do AOTMiT?
              </p>
            }
            show={showModal}
          />
        </div>
      </div>
      <div className="main-panel" ref={mainPanel}>
        <>
          <div className="head-xl-container">
            <h3>Dane analityczne</h3>
            <p>Wprowadzanie danych analitycznych</p>
          </div>

          <main className="main-xl-container">
            <Form className="ml-2">
              <Row>
                <Col>
                  <Form.Control
                    className="mx-auto"
                    id="analyticsFile"
                    label={
                      selectedFile && selectedFile.name ? selectedFile.name : ""
                    }
                    name="file"
                    onChange={(e) => setSelectedFile(e.target.files[0])}
                    type="file"
                  />
                </Col>
                <Col>
                  <Button
                    className="btn-aotmit"
                    onClick={() => {
                      sendExcelWithAnalytics(selectedFile, procedureID);
                    }}
                    type="button"
                    variant="outline-secondary"
                  >
                    <i className="fa upload" /> Prześlij plik
                  </Button>
                </Col>
              </Row>
            </Form>

            <GeneratedForm
              dataSubmit={saveData}
              formFields={AnalyticsFormFields}
              opkData={analytics_objects}
            />
          </main>
        </>
      </div>
    </div>
  );
};

export default AnalyticsPrecedureForm;
