import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Form } from "react-bootstrap";
import { useFormik } from "formik";

import { initialValues, validationSchema } from "./ForgotPasswordFormData";
import { resetUserPassword } from "../../actions/auth";
import RegistrationBox from "./RegistrationBox";
import InfoModal from "components/Modal/InfoModal";
import SkipLinks from "components/SkipLinks/SkipLinks";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);

      try {
        const result = await dispatch(resetUserPassword(values));
        resetForm();
        setModalContent(result.message);
      } catch (error) {
        setModalContent("Wystąpił błąd. Spróbuj ponownie później");
      } finally {
        setLoading(false);
        setShowModal(true);
      }
    },
    validationSchema,
  });

  const handleCloseModal = () => {
    setModalContent("");
    setShowModal(false);
    if (!formik.dirty) {
      navigate("/registration/login");
    }
  };

  const skipLinks = [
    {
      id: "#email",
      desc: "Przejdź do formularza odzyskiwania hasła.",
    },
  ];

  return (
    <>
    <SkipLinks skipLinks={skipLinks} />
      <RegistrationBox
        formik={formik}
        loading={loading}
        title="Wpisz adres e-mail konta, do którego chcesz odzyskać dostęp"
      >
        <Form.Group>
          <Form.Control
            aria-label="email"
            className="my-3"
            id="email"
            placeholder="twoj@email.pl"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            isInvalid={formik.touched.email && formik.errors.email}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.email}
          </Form.Control.Feedback>
        </Form.Group>
      </RegistrationBox>

      <InfoModal
        handleClose={handleCloseModal}
        modalBtnText="Powrót"
        modalBodyText={modalContent}
        modalTitle="Reset hasła"
        show={showModal}
        size="md"
      />
    </>
  );
};

export default ForgotPassword;
