import { CLEAR_SPECIFIC_DATA_VIEW_CONTROLLER_DATA } from "actions/types";
import {
  GET_SPECIFIC_DATA_VIEW_CONTROLLER_DATA,
  SPECIFIC_DATA_VIEW_CONTROLLER_SET_LOADING,
} from "actions/types.js";

const initialState = {
  specificDataControllerData: {},
  specificDataControllerLoading: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SPECIFIC_DATA_VIEW_CONTROLLER_DATA:
      return {
        ...state,
        specificDataControllerData: action.payload,
        specificDataControllerLoading: false,
      };
    case SPECIFIC_DATA_VIEW_CONTROLLER_SET_LOADING:
      return {
        ...state,
        specificDataControllerLoading: true,
      };
    case CLEAR_SPECIFIC_DATA_VIEW_CONTROLLER_DATA:
      return {
        ...state,
        specificDataControllerData: {},
      };
    default:
      return state;
  }
};

export default reducer;
