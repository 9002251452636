import React from "react";

const ParticipationRaport = () => {
  return (
    <>
      <div className="head-container">
        <h3>Raport Postępowania</h3>
        <p>
          Strona generowania raportu postepowania oraz oświadczeń dla
          świadczeniodawców.
        </p>
      </div>

      <main className="main-container">
        <p className="text-muted"> tu bedzie raport</p>
      </main>
    </>
  );
};

export default ParticipationRaport;
