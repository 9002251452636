
import { useEffect } from "react";

const SkipLinks = ({ skipLinks }) => {
  useEffect(() => {
    const container = document.createElement("div");
    container.className = "skip-links-container";
    container.setAttribute("aria-label", "Skip navigation");

    skipLinks.forEach((link) => {
      const a = document.createElement("a");
      a.href = link.id;
      a.className = "skip-link";
      a.innerText = link.desc;
      container.appendChild(a);
    });

    // Insert the container at the very top of the <body>
    document.body.prepend(container);

    // Cleanup on component unmount
    return () => {
      document.body.removeChild(container);
    };
  }, [skipLinks]);

  return null;
};

export default SkipLinks;