import {
  GET_TAB4_FORM_DATA,
  SAVE_TAB4_FORM_DATA,
  TAB4_FORM_DATA_SET_LOADING,
  TAB4_LOADING_OFF,
} from "../../../actions/types.js";

const initialState = {
  loading: true,
  Tab4Data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TAB4_FORM_DATA:
      return {
        ...state,
        loading: false,
        Tab4Data: action.payload,
      };
    case SAVE_TAB4_FORM_DATA:
      return {
        ...state,
        loading: false,
        Tab4Data: action.payload,
      };
    case TAB4_FORM_DATA_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case TAB4_LOADING_OFF:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
