import { SmalModal } from "components/Modal/SmalModal";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";

import GeneratedForm from "../../components/GeneratedForm/GeneratedForm";
import { CostCalculationFormFields } from "../../utils/formFields";

const CostCalculationForm = () => {
  const location = useLocation();

  const mainPanel = React.useRef();
  const sidebar = React.useRef();

  const analyitics_objects = {};

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
  }, [location]);

  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");

  const handleModalShow = () => setShowModal((prevState) => !prevState);
  const handleAccept = () => {
    handleModalShow();
  };

  const saveData = () => {
    console.log();
  };

  return (
    <div className="wrapper">
      <div className="sidebar">
        <div className="sidebar-wrapper px-4 mx-auto" ref={sidebar}>
          <Button
            className="mt-2 w-100"
            onClick={() => handleModalShow()}
            size="sm"
            type="button"
            variant="outline-success"
          >
            Prześlij do AOTMiT
          </Button>
          <SmalModal
            handleAccept={handleAccept}
            handleClose={handleModalShow}
            modalBodyText={
              <p className="text-center">
                Czy na pewno chcesz przesłać dane do AOTMiT?
              </p>
            }
            show={showModal}
          />
        </div>
      </div>
      <div className="main-panel" ref={mainPanel}>
        <>
          <div className="head-xl-container">
            <h3>Własna kalkulacja kosztów</h3>
            <p>Wprowadzanie danych kliniczno – kosztowych</p>
          </div>

          <main className="main-xl-container">
            <Form className="ml-2">
              <Row>
                <Col>
                  <Form.Control
                    className="mx-auto"
                    id="exampleFile"
                    label={
                      selectedFile && selectedFile.name ? selectedFile.name : ""
                    }
                    name="file"
                    onChange={(e) => setSelectedFile(e.target.files[0])}
                    type="file"
                  />
                </Col>
                <Col>
                  <Button
                    className="btn-aotmit"
                    type="button"
                    variant="outline-secondary"
                    // onClick={() => {
                    //   sendExcelWithUsers(
                    //     selectedFile,
                    //     selectedProceeding.value
                    //   );
                    // }}
                  >
                    <i className="fa upload" /> Prześlij plik
                  </Button>
                </Col>
              </Row>
            </Form>

            <GeneratedForm
              dataSubmit={saveData}
              formFields={CostCalculationFormFields}
              opkData={analyitics_objects}
            />
          </main>
        </>
      </div>
    </div>
  );
};

export default CostCalculationForm;
