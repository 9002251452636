import { Navigate, Route, Routes } from "react-router-dom";

import AnalyticsPrecedureForm from "../containers/Analytics/AnalyticsPrecedureForm";
import CostCalculationForm from "../containers/CostCalculation/CostCalculationForm";
import DashboardMainPage from "../containers/Dashboard/DashboardMainPage.js";
import ParticipationRaport from "../containers/Dashboard/ParticipationRaport";
import ParticipationRegister from "../containers/Dashboard/ParticipationRegister";
import RestoreForImprovment from "../containers/Dashboard/RestoreForImprovment";
import VerificationOfParticipation from "../containers/Dashboard/VerificationOfParticipation";
import ProceedingFAQ from "../containers/Instructions/ProceedingFAQ";
import ProceedingMaterials from "../containers/Instructions/ProceedingMaterials";
import SlagAllQuestions from "../containers/Instructions/SlagAllQuestions";
import SlagSelectedFiles from "../containers/Instructions/SlagSelectedFiles";
import LandingPage from "../containers/Main/LandingPage.js";
import AnalyticsForm from "../containers/Opk/OpkList/AnalyticsForm";
import OpkForm from "../containers/Opk/OpkList/OpkForm";
import OpkImportXLSX from "../containers/Opk/OpkList/OpkImportXLSX";
import OpkListPage from "../containers/Opk/OpkList/OpkListPage";
import Tab1Form from "../containers/Opk/OpkList/Tab1Form";
import Tab2Form from "../containers/Opk/OpkList/Tab2Form";
import Tab3Form from "../containers/Opk/OpkList/Tab3Form";
import Tab4Form from "../containers/Opk/OpkList/Tab4Form";
import OpkNewList from "../containers/Opk/OpkNewList";
import OpkUserProfile from "../containers/Opk/OpkUserProfile";
import OpkViewController from "../containers/Opk/OpkViewController";
import OpkAkceptacja from "../containers/Opk/StaffUserViews/OpkAkceptacja";
import OpkListForVerification from "../containers/Opk/StaffUserViews/OpkListForVerification";
import ForgotPassword from "../containers/Registration/ForgotPassword";
import Login from "../containers/Registration/Login";
import Logout from "../containers/Registration/Logout";
import ResetPassword from "../containers/Registration/ResetPassword";
import SwitchUser from "../containers/Registration/SwitchUser";
import Sitemap from "../containers/Sitemap/Sitemap";
import SpecificDataForm from "../containers/SpecificData/SpecificDataForm";
import SpecificDataViewController from "../containers/SpecificData/SpecificDataViewController";
import InstructionsMainPage from "./../containers/Instructions/InstructionsMainPage.js";
import SlagSelectedQuestions from "./../containers/Instructions/SlagSelectedQuestions";
import WcagPage from "containers/Wcag/WcagPage";

import ProtectedRoute from "./ProtectedRoute";

const AppRoutes = ({ isAuthenticated, loading }) => (
  <Routes>
    {/* PRIVATE ROUTES ---------------------------------------------- */}
    <Route
      element={
        <ProtectedRoute isAllowed={isAuthenticated} isLoading={loading} />
      }
    >
      <Route element={<LandingPage />} path="/" />
      <Route element={<SwitchUser />} path="/registration/switchUser" />
      <Route element={<Logout />} path="/registration/logout" />
      <Route
        element={<AnalyticsPrecedureForm />}
        path="/analytics/:procedureID"
      />
      <Route
        element={<CostCalculationForm />}
        path="/wkk/:procedureID/cost_calculation_form"
      />
      {/* FK -------------------------------------------------------- */}
      <Route path="/fk">
        <Route element={<OpkUserProfile />} path="user_profile" />
        <Route
          element={<OpkListForVerification />}
          path="lista_do_weryfikacji"
        />
        <Route element={<OpkViewController />} path=":procedureID" />
        <Route element={<OpkImportXLSX />} path=":procedureID/upload_xlsx" />
        <Route element={<OpkNewList />} path=":procedureID/opk_swd" />
        <Route
          element={<OpkAkceptacja />}
          path=":procedureID/:userID/opk_akceptacja"
        />
        <Route element={<OpkListPage />} path=":procedureID/opk" />
        <Route element={<OpkListPage />} path=":procedureID/opk/:opkID/*">
          <Route element={<OpkForm />} path="opkform" />
          <Route element={<Tab1Form />} path="tab1form" />
          <Route element={<Tab2Form />} path="tab2form" />
          <Route element={<Tab3Form />} path="tab3form" />
          <Route element={<Tab4Form />} path="tab4form" />
          <Route element={<AnalyticsForm />} path="analyticsform" />
        </Route>
      </Route>
      {/* DANESZCZ -------------------------------------------------- */}
      <Route path="/daneszcz">
        <Route element={<SpecificDataViewController />} path=":procedureID" />
        <Route
          element={<SpecificDataForm />}
          path=":procedureID/specific_data_form"
        />
      </Route>
      {/* PANEL ----------------------------------------------------- */}
      <Route element={<DashboardMainPage />} path="/panel">
        <Route
          element={<RestoreForImprovment />}
          path="przywracanie_do_poprawy"
        />
        <Route element={<ParticipationRegister />} path="nowe_konta" />
        <Route
          element={<VerificationOfParticipation />}
          path="weryfikacja_uczestnictw"
        />
        <Route element={<ParticipationRaport />} path="raport_postepowania" />
      </Route>
    </Route>
    {/* NOT PRIVATE ROUTES ------------------------------------------ */}
    <Route element={<Login />} path="/registration/login" />
    <Route element={<Sitemap />} path="/sitemap" />
    <Route element={<WcagPage />} path="/wcag" />
    <Route element={<ForgotPassword />} path="/registration/forgot-password" />
    <Route
      element={<ResetPassword />}
      path="/auth/password_reset_confirm/:uidb64/:token"
    />
    {/* INSTRUKCJA -------------------------------------------------- */}
    <Route element={<InstructionsMainPage />} path="/instrukcja">
      <Route element={<ProceedingMaterials />} path="materialy">
        <Route element={<SlagSelectedFiles />} path=":slug" />
      </Route>
      <Route element={<ProceedingFAQ />} path="faq">
        <Route element={<SlagAllQuestions />} index />
        <Route element={<SlagSelectedQuestions />} path=":slug" />
      </Route>
    </Route>
    <Route element={<Navigate replace to="/registration/login" />} path="*" />
  </Routes>
);

export default AppRoutes;
