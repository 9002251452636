import { apiClient } from "api/api";
import {
  FK_FORM_FIELDS_LOADING,
  GET_ERRORS,
  GET_FK_FORM_FIELDS,
  GET_OPK_LIST_DATA,
  OPK_LIST_DATA_SET_LOADING,
} from "./types";

export const getFKFormFields = (procedureID) => async (dispatch) => {
  try {
    dispatch({ type: FK_FORM_FIELDS_LOADING });

    const response = await apiClient.get(`/fk/${procedureID}/get_fields`, {
      useSwitchUserToken: true,
    });

    dispatch({
      payload: response.data,
      type: GET_FK_FORM_FIELDS,
    });
  } catch (err) {
    if (err.response) {
      console.log("SD_Error", err.response.data);
      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };

      dispatch({
        payload: errors,
        type: GET_ERRORS,
      });
    } else {
      console.log({ err });
    }
  }
};

export const getOpkData = (procedureID) => async (dispatch) => {
  try {
    dispatch({ type: OPK_LIST_DATA_SET_LOADING });

    const response = await apiClient.get(`/fk/${procedureID}/opk_list`, {
      useSwitchUserToken: true,
    });
    dispatch({
      payload: response.data,
      type: GET_OPK_LIST_DATA,
    });
  } catch (err) {
    if (err.response) {
      console.log(err.response.data);
      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };

      dispatch({
        payload: errors,
        type: GET_ERRORS,
      });
    } else {
      console.log({ err });
    }
  }
};
