import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { BaseLayout } from "./containers/BaseLayout";
import useHandleAlerts from "./hooks/useHandleAlerts";
import useInitializeUser from "./hooks/useInitializeUser";
import AppRoutes from "./routes/AppRoutes";
import { getRouteTitle } from "./routes/routes";

import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  const location = useLocation();
  const { isAuthenticated, loading } = useSelector(
    (state) => state.authReducer,
  );

  useInitializeUser();
  useHandleAlerts();

  useEffect(() => {
    const path = location.pathname;
    const title = getRouteTitle(path);
    document.title = title;

    if (!path.includes("fk")) {
      localStorage.removeItem("proceedingData");
    }
  }, [location.pathname]);

  return (
    <BaseLayout>
      <AppRoutes isAuthenticated={isAuthenticated} loading={loading} />
    </BaseLayout>
  );
};

export default App;
