import { useEffect, useState } from "react";

import { ProgressBar } from "react-bootstrap";

const evaluatePasswordStrength = (password) => {
  if (!password) return 0;
  let strength = 1;

  if (password.length >= 8) {
    strength += 1;
    if (/[A-Z]/.test(password)) strength += 1; // uppercase letter
    if (/[0-9]/.test(password)) strength += 1; // digit
    if (/[^A-Za-z0-9]/.test(password)) strength += 1; // special character
  }
  return strength;
};

const PasswordStrengthBar = ({ formik }) => {
  const [passwordStrength, setPasswordStrength] = useState(0);

  useEffect(() => {
    if (formik.values?.newPassword) {
      const strength = evaluatePasswordStrength(formik.values.newPassword);
      setPasswordStrength(strength);
    }
  }, [formik.values?.newPassword]);

  const labels = [
    "",
    "Za krótkie",
    "Słabe",
    "Średnie",
    "Silne",
    "Bardzo silne",
  ];
  const variants = ["danger", "danger", "danger", "warning", "info", "success"];

  return (
    <div className="mt-1 mb-4">
      <ProgressBar
        now={(Math.min(passwordStrength, 5) / 5) * 100}
        label={labels[Math.min(passwordStrength, 5)] || ""}
        variant={variants[Math.min(passwordStrength, 5)] || "danger"}
      />
    </div>
  );
};
export default PasswordStrengthBar;
