import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import logo from "../../assets/img/aotm.png";

const SmalModal = ({
  handleAccept,
  handleClose,
  modalBodyText,
  show,
  size = "sm",
}) => {
  return (
    <>
      <Modal
        backdrop="static"
        centered
        keyboard={false}
        onHide={handleClose}
        show={show}
        size={size}
      >
        <Modal.Header closeButton>
          <img alt="AOTMiT logo" className="modalLogo" src={logo} />
        </Modal.Header>
        <Modal.Body>{modalBodyText}</Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button onClick={handleClose} variant="danger">
            Anuluj
          </Button>
          <Button onClick={handleAccept} variant="success">
            Zatwierdź
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export { SmalModal };
