import { useSelector } from "react-redux";

import Select, { components } from "react-select";

const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span className="custom-css-class">Brak uczestnictw</span>
    </components.NoOptionsMessage>
  );
};

const HealthcareProviderSelect = ({
  selectValue,
  onChangeFn,
  isDisabled = false,
}) => {
  const healthcareProviders = useSelector(
    (state) => state.restoreForImprovmentReducer.proceeding_users,
  );
  const loadingData = useSelector(
    (state) => state.restoreForImprovmentReducer.loading,
  );

  return (
    <Select
      className="react-select mt-2"
      isDisabled={isDisabled || loadingData}
      onChange={(value) => onChangeFn(value)}
      options={healthcareProviders}
      placeholder={
        loadingData
          ? "Trwa ładowanie listy, proszę czekać..."
          : "Wybierz Świadczeniodawcę"
      }
      value={
        selectValue?.user
          ? {
              label: selectValue.user.username,
              value: selectValue.user.id,
            }
          : null
      }
      components={{ NoOptionsMessage }}
    />
  );
};

export default HealthcareProviderSelect;
