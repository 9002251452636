import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import { Button, Card, Col, Row } from "react-bootstrap";

import { getMaterialCategory } from "actions/PanelInstructionsActions/MaterialsActions";
import BreadcrumbContainer from "components/Breadcrumbs/BreadcrumbContainer";

const ProceedingMaterials = () => {
  const dispatch = useDispatch();
  const breadcrumbLabelRef = useRef("");

  const material_categories = useSelector(
    (state) => state.materialsReducer.material_categories,
  );

  useEffect(() => {
    dispatch(getMaterialCategory());
  }, [dispatch]);

  return (
    <>
      <BreadcrumbContainer breadcrumbLabel={breadcrumbLabelRef.current} />
      {material_categories.length !== 0 && (
        <Card>
          <Card.Body className="m-1 p-2">
            <Row>
              {material_categories.map((category, category_idx) => (
                <Col key={category_idx} lg={2} md={4} sm={12}>
                  <Link
                    className="nav-link p-0"
                    to={`/instrukcja/materialy/${category.slug}`}
                    onClick={() => {
                      breadcrumbLabelRef.current = category.nazwa;
                    }}
                    tabIndex="-1"
                  >
                    <Button
                      className="mt-1 w-100"
                      type="button"
                      variant="outline-secondary"
                    >
                      <h6>{category.nazwa}</h6>
                    </Button>
                  </Link>
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Card>
      )}
      <main>
        <Outlet />
      </main>
    </>
  );
};

export default ProceedingMaterials;
