import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";

import { getListForVerification } from "../../../actions/StaffUserViewsActions/OpkListForVerificationActions";
import { Loading } from "../../../components/Loading/loading";

const ListForVerificationContent = ({ loading, proceedings }) => {
  const navigate = useNavigate();

  if (loading === true) {
    return (
      <>
        <Loading variant={"info"}>
          <span className="m-2">
            Informacja o postępowaniach jest pobierana z serwera...
          </span>
        </Loading>
      </>
    );
  } else if (proceedings.length === 0) {
    return (
      <div className={"container-items-centered"}>
        <Card className="text-center box-shadow" style={{ width: "30rem" }}>
          <Card.Header className={"text-warning"}>
            <FontAwesomeIcon icon={faInfo} size="2x" />
          </Card.Header>
          <Card.Body>
            <Card.Title>
              Nie ma w tej chwili listy Kont OPK do akceptacji.
            </Card.Title>
          </Card.Body>
        </Card>
      </div>
    );
  } else {
    return (
      <>
        <ListGroup variant="flush">
          <ListGroup.Item className="list-item" disabled variant="light">
            <span className="participation-item">Postępowanie nr:</span>
            <span className="year-item">Rok:</span>
            <span className="swd-id">Regon:</span>
            <span className="swd-name">Świadczeniodawca:</span>
          </ListGroup.Item>

          {proceedings.map((proceeding) => {
            return (
              <ListGroup.Item
                key={proceeding.id}
                onClick={() =>
                  navigate(
                    `/fk/${proceeding.postepowanie.id}/${proceeding.user.id}/opk_akceptacja`,
                  )
                }
                action
                className="list-item font-small"
                variant="light"
              >
                <span className="participation-item">
                  {proceeding.postepowanie.postepowanie_nr}
                </span>
                <span className="year-item">
                  {proceeding.postepowanie.postepowanie_rok}
                </span>
                <span className="swd-id">{proceeding.user.username}</span>
                <span className="swd-name">
                  {proceeding.user?.profile[0]?.swd_nazwa ||
                    "Nie podano nazwy SWD"}
                </span>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </>
    );
  }
};

const OpkListForVerification = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.opkListForVerification.loading);
  const proceedings_for_veryfication = useSelector(
    (state) => state.opkListForVerification.proceedings_for_veryfication,
  );

  useEffect(() => {
    dispatch(getListForVerification());
  }, [dispatch]);
  return (
    <>
      <Card className="head-container">
        <Card.Body>
          <Card.Title>
            Akceptacja Przesłanych przez świadczeniodawcę kont OPK
          </Card.Title>

          <div>
            <ul>
              <li>Znak akceptacji - rekord został przejrzany.</li>
              <li>Przekreślenie - lista OPK zaakceptowana</li>
              <li>
                Do poprawy - po zaakceptowaniu świadczeniodawca będzie mógł
                edytować TYLKO ten OPK
              </li>
            </ul>
          </div>
        </Card.Body>
      </Card>

      <main className="main-container">
        <ListForVerificationContent
          loading={loading}
          proceedings={proceedings_for_veryfication.objects_to_accept}
        />
      </main>
    </>
  );
};

export default OpkListForVerification;
