import { GET_ERRORS } from "actions/types";

export const isPartialContent = (status) => {
  return status === 206;
};

export const handleErrorsForModalContent = (data) => {
  const errorMessages = data.errors
    .map((e) => {
      if (e.errors && typeof e.errors === "object") {
        return Object.values(e.errors).flat();
      }
      return e.error || e.errors;
    })
    .flat();

  return {
    title: data.message,
    message: new Set(errorMessages),
  };
};

export const updateFormStateFromResponse = (
  dispatch,
  responseData,
  tabKey,
  saveType,
) => {
  if (responseData.errors?.length) {
    const newResponseData = responseData;
    const newResponseDataObjects = newResponseData[tabKey];
    const unsavedItems = responseData.errors.map((error) => error.item);

    unsavedItems.forEach((unsaved) => {
      if (unsaved.id !== null) {
        const index = newResponseDataObjects.findIndex(
          (item) => item.id === unsaved.id,
        );

        if (index !== -1) {
          newResponseDataObjects[index] = unsaved;
        }
      } else {
        newResponseDataObjects.push(unsaved);
      }
    });
    newResponseData["unsavedRows"] = unsavedItems.map((item) => item.id);
    dispatch({
      payload: newResponseData,
      type: saveType,
    });
  } else {
    dispatch({
      payload: responseData,
      type: saveType,
    });
  }
};

export const handleFormErrors = (dispatch, errorData, tabKey, saveType) => {
  dispatch({
    payload: errorData,
    type: GET_ERRORS,
  });
  const unsavedData = errorData;
  const unsavedDataObjects = unsavedData.errors.map(
    (objWithError) => objWithError.item,
  );
  unsavedData[tabKey] = unsavedDataObjects;
  unsavedData["unsavedRows"] = unsavedDataObjects.map((item) => item.id);
  dispatch({
    payload: unsavedData,
    type: saveType,
  });
};
