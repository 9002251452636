import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import logo from "../../assets/img/aotm_50px.png";
import LinksControler from "./LinksControler";
import UserInfo from "./userInfo";

const Header = () => {
  return (
    <Navbar bg="light" expand="lg" sticky="top" className="navbar__container">
      <Navbar.Brand href="/">
        <img
          alt="AOTMiT logo"
          className="d-inline-block align-top"
          height="50"
          src={logo}
          width="50"
        />
      </Navbar.Brand>
      <UserInfo />
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="align-items-center ms-auto">
          <LinksControler />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
