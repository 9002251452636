import { apiClient } from "api/api";
import { saveAs } from "file-saver";

import { GET_ERRORS, GET_SUCCESS } from "../types";
import {
  NO_FILES_TO_DOWNLOAD_INFO,
  DOWNLOAD_FK_FILE_SUCCESS_INFO,
  DOWNLOAD_FILE_ERROR_INFO,
} from "../../utils/toastMessages";

export const getpersonalizedexcel = (procedureID) => async (dispatch) => {
  try {
    const response = await apiClient.get(
      `/fk/${procedureID}/get_personalized_excel`,
      {
        contentType:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        responseType: "arraybuffer",
        useSwitchUserToken: true,
      },
    );
    let blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "Wzor_FK.xlsx");

    dispatch({
      payload: {
        msg: DOWNLOAD_FK_FILE_SUCCESS_INFO,
        status: response.status,
      },
      type: GET_SUCCESS,
    });
  } catch (err) {
    const errors = {
      msg:
        err.response.data instanceof ArrayBuffer
          ? DOWNLOAD_FILE_ERROR_INFO
          : err.response.data,
      status: err.response.status,
    };
    dispatch({
      payload: errors,
      type: GET_ERRORS,
    });
  }
};

export const downloadLastUploadedFkFile = (procedureID) => async (dispatch) => {
  try {
    const response = await apiClient.get(
      `/fk/${procedureID}/get_files_history`,
      { useSwitchUserToken: true },
    );
    const files = response.data;

    if (!files || files.length === 0) {
      dispatch({
        payload: { msg: NO_FILES_TO_DOWNLOAD_INFO, status: response.status },
        type: GET_ERRORS,
      });
      return;
    }

    const latestFile = files.reduce((latest, current) =>
      new Date(current.data) > new Date(latest.data) ? current : latest,
    );

    const anchor = document.createElement("a");
    anchor.href = latestFile.plik;
    anchor.download = latestFile.plik.split("/").pop() || "file";
    anchor.click();
    anchor.remove();
    dispatch({
      payload: { msg: DOWNLOAD_FK_FILE_SUCCESS_INFO, status: response.status },
      type: GET_SUCCESS,
    });
  } catch (error) {
    dispatch({
      payload: {
        msg: DOWNLOAD_FILE_ERROR_INFO,
        status: error.response.status,
      },
      type: GET_ERRORS,
    });
    console.error("Error downloading file:", error);
  }
};
