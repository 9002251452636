import { apiClient } from "api/api";
import { saveAs } from "file-saver";

import { GET_ERRORS, GET_SUCCESS } from "../types";

// get personalized excel
export const getExcelStatistics =
  (procedureID) => async (dispatch, getState) => {
    try {
      const switchedUserName =
        getState().switchUserReducer.switchedUser?.username || "";

      const response = await apiClient.get(
        `/fk/${procedureID}/get_excel_statistics`,
        {
          contentType:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          responseType: "arraybuffer",
          useSwitchUserToken: true,
        },
      );
      let blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `${switchedUserName}_FK.xlsx`);

      dispatch({
        payload: {
          msg: "Plik FK został poprawnie pobrany.",
          status: response.status,
        },
        type: GET_SUCCESS,
      });
    } catch (err) {
      console.log(err.response, err.response.data);

      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };
      dispatch({
        payload: errors,
        type: GET_ERRORS,
      });
    }
  };
