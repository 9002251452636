import {
  GET_ALL_EXCEL_FILES_ANALYTICS,
  GET_ALL_EXCEL_FILES_ANALYTICS_SET_LOADING,
  GET_ERRORS,
  POST_EXCEL_FILES_ANALYTICS,
  POST_EXCEL_FILES_ANALYTICS_SET_LOADING,
} from "actions/types";
import { apiClient } from "api/api";

export const getAllExcelFilesAnalytics = (procedureID) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_EXCEL_FILES_ANALYTICS_SET_LOADING,
    });

    const response = await apiClient.get(
      `/analytics/analytics_xlsx_files/?postepowanie_id=${procedureID}`,
    );

    dispatch({
      payload: response.data,
      type: GET_ALL_EXCEL_FILES_ANALYTICS,
    });
  } catch (err) {
    if (err.response.data) {
      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };
      dispatch({
        payload: errors,
        type: GET_ERRORS,
      });
    } else {
      console.log({ authErr: err });
    }
  }
};

export const postExcelFilesAnalytics =
  (xlsxFile, procedureID) => async (dispatch) => {
    console.log("ExcelFilesAnalytics");
    dispatch({ type: POST_EXCEL_FILES_ANALYTICS_SET_LOADING });
    // const json = JSON.stringify(xlsxFile);
    // const fileBlob = new Blob([json], {
    //   type: "application/json",
    // });
    const data = new FormData();
    data.append("file", xlsxFile);
    data.append("postepowanie_id", procedureID);
    try {
      const response = await apiClient.post(
        `/analytics/analytics_xlsx_files/`,
        data,
        { useSwitchUserToken: true },
      );
      dispatch({
        payload: response.data,
        type: POST_EXCEL_FILES_ANALYTICS,
      });
    } catch (err) {
      if (err.response) {
        const errors = {
          msg: err.response.data,
          status: "",
        };

        dispatch({
          payload: errors,
          type: GET_ERRORS,
        });
      } else {
        console.log({ err });
      }
    }
  };
