import { Button, Modal } from "react-bootstrap";

const InfoModal = ({
  handleClose,
  modalBtnText,
  modalBodyText,
  modalTitle,
  show,
  size = "sm",
}) => {
  return (
    <>
      <Modal
        centered
        onHide={handleClose}
        show={show}
        size={size}
        animation={false}
      >
        <Modal.Header closeButton>
          <h5>{modalTitle}</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">{modalBodyText}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>{modalBtnText}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InfoModal;
