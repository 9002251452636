import { useSelector } from "react-redux";

import { Card } from "react-bootstrap";

const SlagAllQuestions = () => {
  const faq = useSelector((state) => state.faqReducer.faq || []);

  return (
    <>
      {faq.map((faq_element, faq_idx) => (
        <Card key={faq_idx}>
          <Card.Header>{faq_element.pytanie}</Card.Header>
          <Card.Body>{faq_element.odpowiedz}</Card.Body>
        </Card>
      ))}
    </>
  );
};

export default SlagAllQuestions;
