import { apiClient } from "api/api";
import {
  GET_ERRORS,
  GET_LIST_FOR_VERIFICATION,
  LIST_FOR_VERIFICATION_SET_LOADING,
} from "../types";

export const getListForVerification = () => async (dispatch) => {
  try {
    dispatch({ type: LIST_FOR_VERIFICATION_SET_LOADING });

    const response = await apiClient.get(`/fk/lista_do_weryfikacji`, {
      useSwitchUserToken: true,
    });
    dispatch({
      payload: response.data,
      type: GET_LIST_FOR_VERIFICATION,
    });
  } catch (err) {
    console.log(err.response, err.response.data);

    const errors = {
      msg: err.response.data,
      status: err.response.status,
    };
    dispatch({
      payload: errors,
      type: GET_ERRORS,
    });
  }
};
