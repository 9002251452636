import React, { useEffect, useRef } from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import SkipLinks from "../../components/SkipLinks/SkipLinks";

const InstructionsMainPage = () => {
  const location = useLocation();
  const mainPanel = useRef();
  const sidebar = useRef();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
  }, [location]);

  const skipLinks = [
    {
      id: "#nav",
      desc: "Nawigacja",
    },
    {
      id: "#main",
      desc: "Sekcja główna",
    },
  ];

  return (
    <>
      <SkipLinks skipLinks={skipLinks} />

      <div className="instructions mb-5">
        <div className="sidebarI">
          <div className="sidebar-wrapper" ref={sidebar} id="nav">
            <ul className="nav">
              <li>
                <NavLink to="/instrukcja/materialy">
                  <span className="sidebar-normal">Materiały</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/instrukcja/FAQ">
                  <span className="sidebar-normal">
                    Najcześciej zadawane pytania
                  </span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="full-container simple-margin" ref={mainPanel} id="main">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default InstructionsMainPage;
