import * as Yup from "yup";

export const initialValues = {
  newPassword: "",
  repeatPassword: "",
  uidb64: "",
  token: "",
};

export const validationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, "Minimalna długość hasła to 8 znaków!")
    .required("Pole jest wymagane!"),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref("newPassword")], "Hasła muszą być identyczne!")
    .required("Pole jest wymagane!"),
  uidb64: Yup.string(),
  token: Yup.string(),
});
