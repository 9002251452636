import { GET_FK_FORM_FIELDS } from "../../../actions/types.js";

const initialState = {
  form_fields: {},
  loading: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FK_FORM_FIELDS:
      return {
        form_fields: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};
export default reducer;
