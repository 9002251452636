import { useNavigate } from "react-router-dom";

import Breadcrumb from "react-bootstrap/Breadcrumb";

import "../../assets/scss/aotm/_breadcrumbs.scss";

const BreadcrumbComponent = ({ path }) => {
  const navigate = useNavigate();

  return (
    <Breadcrumb>
      {path.map((item, index) => (
        <Breadcrumb.Item
          key={index}
          onClick={() => item.href && navigate(item.href)}
          active={index === path.length - 1}
        >
          {item.label}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default BreadcrumbComponent;
