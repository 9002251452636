import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  getTab4FormData,
  saveTab4FormData,
} from "../../../actions/OpkFormsActions/tab4FormActions";
import { Loading } from "../../../components/Loading/loading";
import { Tab4FormFields } from "../OpkForms/FormSettings";
import OpkColumnForm from "../OpkForms/OpkColumnForm";

const Tab4Form = () => {
  const { opkID, procedureID } = useParams();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.tab4FormReducer.loading);
  const tab4_opks_object = useSelector(
    (state) => state.tab4FormReducer.Tab4Data.tab4_opks_object,
  );

  useEffect(() => {
    dispatch(getTab4FormData(procedureID, opkID));
  }, [dispatch, procedureID, opkID]);

  const saveData = async (submitedFormData) => {
    dispatch(saveTab4FormData(procedureID, opkID, submitedFormData));
  };

  return (
    <>
      {loading ? (
        <Loading variant="danger">
          <span className="m-2">Wczytywanie danych. Proszę czekać...</span>
        </Loading>
      ) : (
        <OpkColumnForm
          dataSubmit={saveData}
          formFields={Tab4FormFields}
          opk_object={tab4_opks_object}
        />
      )}
    </>
  );
};

export default Tab4Form;
