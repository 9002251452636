import BreadcrumbContainer from "components/Breadcrumbs/BreadcrumbContainer";

import Architecture from "./WcagSections/Architecture";
import Complaints from './WcagSections/Complaints';
import ComplianceWithAct from './WcagSections/ComplianceWithAct';
import Contact from './WcagSections/Contact';
import Declaration from "./WcagSections/Declaration";

const WcagPage = () => {
  return (
    <div className="full-container my-5">
      <BreadcrumbContainer />
      <div className='m-5'>
        <Declaration />
        <ComplianceWithAct />
        <Contact />
        <Complaints />
        <Architecture />
      </div>
    </div>
  )
}

export default WcagPage