import React from "react";
import { Button, Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { logoutUser } from "../../actions/auth";

const LinksControler = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated,
  );
  const isSwitchedUser = useSelector(
    (state) => state.switchUserReducer.isSwitchedUser,
  );
  const switchedUser = useSelector(
    (state) => state.switchUserReducer.switchedUser,
  );
  const user = useSelector((state) => state.authReducer.user);

  const handleLogout = () => {
    if (isSwitchedUser === true) {
      navigate("/registration/logout");
    } else {
      dispatch(logoutUser());
    }
  };

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const staffAuthUser = (loggedUser) => (
    <>
      <Link className="nav-link" to="/">
        Strona główna
      </Link>

      {(loggedUser.is_da_user || loggedUser.is_dpd_user) && (
        <Link className="nav-link" to="/fk/lista_do_weryfikacji">
          Akceptacja OPK
        </Link>
      )}

      <Link className="nav-link" to="/panel/przywracanie_do_poprawy">
        Panel Pomocniczy
      </Link>
      <Link to="/instrukcja/materialy" className="nav-link">
        Instrukcje
      </Link>

      {loggedUser.can_switch_user && !isSwitchedUser && (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 0, hide: 0 }}
          overlay={
            <Tooltip id="switch-user-tooltip">Przełącz użytkownika</Tooltip>
          }
        >
          <Link
            aria-label="Przełącz użytkownika"
            className="nav-link"
            to="/registration/switchUser"
            tabIndex="-1"
          >
            <Button
              aria-label="Przełącz użytkownika"
              variant="outline-info"
              id="switch-user-btn"
              size="sm"
              type="button"
            >
              <i className="fas fa-exchange-alt"></i>
            </Button>
          </Link>
        </OverlayTrigger>
      )}

      <OverlayTrigger
        placement="bottom"
        delay={{ show: 0, hide: 0 }}
        overlay={<Tooltip id="logout-btn">Wyloguj</Tooltip>}
      >
        <Nav.Link className="nav-link" aria-label="Wyloguj" tabIndex="-1">
          <Button
            aria-label="Wyloguj"
            variant="outline-danger"
            id="logout-btn"
            onClick={handleLogout}
            size="sm"
            type="button"
          >
            <i className="fas fa-sign-out-alt"></i>
          </Button>
        </Nav.Link>
      </OverlayTrigger>

      <OverlayTrigger
        placement="bottom"
        delay={{ show: 0, hide: 0 }}
        overlay={<Tooltip id="admin-btn">Panel Administratora</Tooltip>}
      >
        <Nav.Link
          aria-label="Panel Administratora"
          href={`${apiBaseUrl}/limited-admin/`}
          target="_blank"
          tabIndex="-1"
        >
          <Button
            aria-label="Panel Administratora"
            variant="outline-secondary"
            id="admin-btn"
            size="sm"
            type="button"
          >
            <i className="far fa-user"></i>
          </Button>
        </Nav.Link>
      </OverlayTrigger>
    </>
  );

  const notStaffAuthUser = (
    <>
      <Link className="nav-link" to="/">
        Strona główna
      </Link>
      <Link to="/instrukcja/materialy" className="nav-link">
        Instrukcje
      </Link>
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 0, hide: 0 }}
        overlay={<Tooltip id="logout-btn">Wyloguj</Tooltip>}
      >
        <Nav.Link className="nav-link" aria-label="Wyloguj" tabIndex="-1">
          <Button
            aria-label="Wyloguj"
            variant="outline-danger"
            id="logout-btn"
            onClick={handleLogout}
            size="sm"
            type="button"
          >
            <i className="fas fa-sign-out-alt"></i>
          </Button>
        </Nav.Link>
      </OverlayTrigger>
    </>
  );

  const nonAuthLinks = (
    <>
      <Link to="/instrukcja/materialy" className="nav-link">
        Instrukcje
      </Link>
      <OverlayTrigger
        delay={{ show: 0, hide: 0 }}
        overlay={<Tooltip id="login-btn">Zaloguj</Tooltip>}
        placement="bottom"
      >
        <Link
          aria-label="Zaloguj"
          className="nav-link"
          to="/registration/login"
          tabIndex="-1"
        >
          <Button
            aria-label="Zaloguj"
            variant="outline-success"
            id="login-btn"
            size="sm"
            type="button"
          >
            <i className="fas fa-sign-in-alt"></i>
          </Button>
        </Link>
      </OverlayTrigger>
    </>
  );

  if (isAuthenticated) {
    let loggedUser = { ...user };

    if (isSwitchedUser) {
      loggedUser = { ...switchedUser };
    }

    if (loggedUser.is_staff) {
      return staffAuthUser(loggedUser);
    } else if (!loggedUser.is_staff) {
      return notStaffAuthUser;
    } else {
      return nonAuthLinks;
    }
  } else {
    return nonAuthLinks;
  }
};

export default LinksControler;
