import { apiClient } from "api/api";

import {
  CONFIRM_DATA_LOADING_OFF,
  CONFIRM_DATA_SET_LOADING,
  CONFIRM_OPK_DATA,
  GET_ERRORS,
  GET_SUCCESS,
} from "../types";
import { getControllerData } from "actions/opkViewsControllerActions";

export const confirmDataSwd = (procedureID) => async (dispatch) => {
  try {
    dispatch({ type: CONFIRM_DATA_SET_LOADING });

    const response = await apiClient.get(`/fk/${procedureID}/confirm_data`, {
      useSwitchUserToken: true,
    });
    dispatch({
      payload: response.status,
      type: CONFIRM_OPK_DATA,
    });

    dispatch({
      payload: {
        msg: "Numery kont OPK zostały przesłane do AOTMiT",
        status: response.status,
      },
      type: GET_SUCCESS,
    });

    dispatch(getControllerData(procedureID));
  } catch (err) {
    dispatch({ type: CONFIRM_DATA_LOADING_OFF });
    console.log(err.response, err.response.data);

    const errors = {
      msg: err.response.data,
      status: err.response.status,
    };
    dispatch({
      payload: errors,
      type: GET_ERRORS,
    });
  }
};
