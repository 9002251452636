import {
  GET_ERRORS,
  GET_MATERIAL_CATEGORIES,
  GET_MATERIALS,
  GET_MATERIALS_SET_LOADING,
} from "actions/types.js";
import { apiClient } from "api/api";

export const getMaterialCategory = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_MATERIALS_SET_LOADING,
    });

    const response = await apiClient.get(`/instrukcja/materials/`);
    dispatch({
      payload: response.data,
      type: GET_MATERIAL_CATEGORIES,
    });
  } catch (err) {
    if (err.response.data) {
      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };
      dispatch({
        payload: errors,
        type: GET_ERRORS,
      });
    }
  }
};

export const getMaterials = (categorySlug) => async (dispatch) => {
  try {
    dispatch({
      type: GET_MATERIALS_SET_LOADING,
    });

    const response = await apiClient.get(
      `/instrukcja/materials/${categorySlug}`,
    );

    dispatch({
      payload: response.data,
      type: GET_MATERIALS,
    });
  } catch (err) {
    if (err.response.data) {
      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };
      dispatch({
        payload: errors,
        type: GET_ERRORS,
      });
    }
  }
};
