import {
  GET_TAB2_FORM_DATA,
  SAVE_TAB2_FORM_DATA,
  TAB2_FORM_DATA_SET_LOADING,
  TAB2_LOADING_OFF,
} from "../../../actions/types.js";

const initialState = {
  loading: false,
  Tab2Data: [],
  unsavedRows: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TAB2_FORM_DATA:
      return {
        ...state,
        loading: false,
        Tab2Data: action.payload,
      };
    case SAVE_TAB2_FORM_DATA:
      return {
        ...state,
        loading: false,
        Tab2Data: action.payload,
        unsavedRows: action.payload?.unsavedRows || [],
      };
    case TAB2_FORM_DATA_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case TAB2_LOADING_OFF:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export default reducer;
