const Architecture = () => {
  return (
    <div className="pb-5">
      <h2 id="a11y-architektura">Dostępność architektoniczna</h2>
      <ul>
        <li>Do budynku prowadzą 2 wejścia od ulicy Przeskok oznaczone literami A i B. Oba wejścia są na poziomie gruntu. Nie ma przeszkód, by wjechać na wózku. W wejściu A znajdują się drzwi obrotowe, oraz szerokie drzwi otwierane automatycznie przez pracownika Recepcji. W wejściu B znajdują się drzwi automatyczne.</li>
        <li>Recepcja znajduje się na wprost od wejścia A oraz B. Dalsze przejście zabezpieczone jest bramkami, przez które może przejechać osoba na wózku.</li>
        <li>Toalety dla osób z niepełnosprawnościami znajdują się na piętrze 6 oraz 7 oraz przy recepcji B na parterze.</li>
        <li>Miejsca parkingowe dla osób z niepełnosprawnościami są przy ul. Zgody i ul. Brokla są to miejsca parkingowe miejskie, położone najbliżej wejścia do budynku.</li>
        <li>Do budynku i wszystkich jego pomieszczeń można wejść z psem asystującym i psem przewodnikiem.</li>
        <li>W budynku nie ma pętli indukcyjnych.</li>
        <li>W recepcji oraz w żadnym innym miejscu nie można skorzystać z tłumacza polskiego języka migowego.</li>
      </ul>
    </div>
  )
}

export default Architecture