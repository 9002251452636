import { combineReducers } from "redux";

import alertsReducer from "./alertsReducer";
import AnalyticsDataReducer from "./AnalyticsReducers/AnalyticsDataReducer";
import authReducer from "./authReducer";
// Instructions Reducers
import FAQReducer from "./InstructionsReducer/FAQReducer";
import MaterialsReducer from "./InstructionsReducer/MaterialsReducer";
// containres Reducers
import LandingPageReducer from "./MainReducers/LandingPageReducer";
import AnalyticsFormReducer from "./OpkReducers/OpkListReducers/AnalyticsFormReducer";
import OpkCheckDataReducer from "./OpkReducers/OpkListReducers/OpkCheckDataReducer";
import OpkConfirmDataReducer from "./OpkReducers/OpkListReducers/OpkConfirmDataReducer";
import OpkFormFieldsReducer from "./OpkReducers/OpkListReducers/OpkFormFieldsReducer";
import OpkFormReducer from "./OpkReducers/OpkListReducers/OpkFormReducer";
import OpkListReducer from "./OpkReducers/OpkListReducers/OpkListReducer";
import OpkUploadXLSXReducer from "./OpkReducers/OpkListReducers/OpkUploadXLSXReducer";
import Tab1FormReducer from "./OpkReducers/OpkListReducers/Tab1FormReducer";
import Tab2FormReducer from "./OpkReducers/OpkListReducers/Tab2FormReducer";
import Tab3FormReducer from "./OpkReducers/OpkListReducers/Tab3FormReducer";
import Tab4FormReducer from "./OpkReducers/OpkListReducers/Tab4FormReducer";
import OpkNewListReducer from "./OpkReducers/OpkNewListReducer";
import OpkUserProfileReducer from "./OpkReducers/OpkUserProfileReducer";
import OpkViewControllerReducer from "./OpkReducers/OpkViewControllerReducer";
import FilesReducer from "./OpkReducers/StaffUserViewsReducers/FilesReducer";
import NotesReducer from "./OpkReducers/StaffUserViewsReducers/NotesReducer";
import OpkAcceptanceReducer from "./OpkReducers/StaffUserViewsReducers/OpkAcceptanceReducer";
// OPK Reducers
import OpkListForVerification from "./OpkReducers/StaffUserViewsReducers/OpkListForVerificationReducer";
import SwitchedUserInfoReducer from "./OpkReducers/StaffUserViewsReducers/SwitchedUserInfoReducer";
import UserInfoReducer from "./OpkReducers/UserInfoReducer";
// Panel Reducers
import ParticipationRegisterReducer from "./PanelDashboardReducers/ParticipationRegisterReducer";
import RestoreForImprovmentReducer from "./PanelDashboardReducers/RestoreForImprovmentReducer";
import SD_FormReducer from "./SpecificDataReducers/SD_FormReducer";
// Specyfic Data Reducers
import SpecificDataConfirmDataReducer from "./SpecificDataReducers/SpecificDataConfirmDataReducer";
import SpecificDataViewControllerReducer from "./SpecificDataReducers/SpecificDataViewControllerReducer";
import switchUserReducer from "./switchUserReducer";
import utilityReducer from "./utilityReducer";

const appReducer = combineReducers({
  alertsReducer: alertsReducer,
  // Analytics app reducers
  analyticsDataReducer: AnalyticsDataReducer,
  analyticsFormReducer: AnalyticsFormReducer,
  authReducer: authReducer,

  // instructions
  faqReducer: FAQReducer,
  filesReducer: FilesReducer,
  landingPageReducer: LandingPageReducer,

  materialsReducer: MaterialsReducer,
  notesReducer: NotesReducer,

  opkAcceptance: OpkAcceptanceReducer,
  opkCheckDataReducer: OpkCheckDataReducer,
  opkConfirmDataReducer: OpkConfirmDataReducer,
  opkFormFieldsReducer: OpkFormFieldsReducer,
  opkFormReducer: OpkFormReducer,
  opkListForVerification: OpkListForVerification,
  opkListReducer: OpkListReducer,
  opkNewList: OpkNewListReducer,
  opkUploadXLSXReducer: OpkUploadXLSXReducer,

  opkUserProfileReducer: OpkUserProfileReducer,
  opkViewControllerReducer: OpkViewControllerReducer,
  // panel Dashboard
  participationRegisterReducer: ParticipationRegisterReducer,
  restoreForImprovmentReducer: RestoreForImprovmentReducer,
  sd_FormReducer: SD_FormReducer,
  // Specific Data
  specificDataConfirmDataReducer: SpecificDataConfirmDataReducer,
  specificDataViewControllerReducer: SpecificDataViewControllerReducer,

  switchedUserInfoReducer: SwitchedUserInfoReducer,
  switchUserReducer: switchUserReducer,
  tab1FormReducer: Tab1FormReducer,

  tab2FormReducer: Tab2FormReducer,
  tab3FormReducer: Tab3FormReducer,
  tab4FormReducer: Tab4FormReducer,
  userInfoReducer: UserInfoReducer,
  utilityReducer: utilityReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_STORE") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
