import { apiClient } from "api/api";

import {
  GET_ERRORS,
  GET_SUCCESS,
  GET_TAB3_FORM_DATA,
  SAVE_TAB3_FORM_DATA,
  TAB3_FORM_DATA_SET_LOADING,
  TAB3_LOADING_OFF,
} from "../types";
import {
  PARTIAL_CONTETNT_INFO,
  SUCCESS_AND_CHECK_ERRORS_INFO,
} from "../../utils/toastMessages";
import {
  handleErrorsForModalContent,
  handleFormErrors,
  isPartialContent,
  updateFormStateFromResponse,
} from "../../utils/helpers";

export const getTab3FormData = (procedureID, opkID) => async (dispatch) => {
  try {
    dispatch({ type: TAB3_FORM_DATA_SET_LOADING });

    const response = await apiClient.get(
      `/fk/${procedureID}/opk/${opkID}/tab3edit/`,
      { useSwitchUserToken: true },
    );
    dispatch({
      payload: response.data,
      type: GET_TAB3_FORM_DATA,
    });
  } catch (err) {
    dispatch({ type: TAB3_LOADING_OFF });
    if (err.response) {
      console.log("tab3", err.response.data);
      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };

      dispatch({
        payload: errors,
        type: GET_ERRORS,
      });
    } else {
      console.log({ err });
    }
  }
};

export const saveTab3FormData =
  (procedureID, opkID, submitedFormData) => async (dispatch) => {
    try {
      dispatch({ type: TAB3_FORM_DATA_SET_LOADING });

      const response = await apiClient.patch(
        `/fk/${procedureID}/opk/${opkID}/tab3edit/`,
        submitedFormData,
        { useSwitchUserToken: true },
      );
      const partialContent = isPartialContent(response.status);

      dispatch({
        payload: {
          msg: partialContent
            ? PARTIAL_CONTETNT_INFO
            : SUCCESS_AND_CHECK_ERRORS_INFO,
          status: response.status,
        },
        type: GET_SUCCESS,
      });

      updateFormStateFromResponse(
        dispatch,
        response.data,
        "tab3_objects",
        SAVE_TAB3_FORM_DATA,
      );

      if (partialContent) {
        return handleErrorsForModalContent(response.data);
      }
    } catch (err) {
      dispatch({ type: TAB3_LOADING_OFF });
      const errorData = err.response.data;
      handleFormErrors(
        dispatch,
        errorData,
        "tab3_objects",
        SAVE_TAB3_FORM_DATA,
      );
      return handleErrorsForModalContent(errorData);
    }
  };
