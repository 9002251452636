import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="py-2 bg-secondary text-white text-center position-absolute bottom-0 w-100">
      <div className="py-2 d-flex justify-content-center">
        <Link className="nav-link px-2" to="sitemap">
          Mapa strony
        </Link>
        <Link className="nav-link px-2" to="wcag">
          Dostępność cyfrowa
        </Link>
      </div>
      <div>
        Copyright &copy;
        <span className="year"> 2020</span> Agencja Oceny Technologii Medycznych
        i Taryfikacji
      </div>
    </footer>
  );
};

export { Footer };
