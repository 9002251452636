import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import GeneratedForm from "../../../components/GeneratedForm/GeneratedForm";
import CustomModal from "../../../components/Modal/CustomModal";
import { Loading } from "../../../components/Loading/loading";
import useModal from "../../../hooks/useModal";

const TabForm = ({
  getFormData,
  saveFormData,
  formFields,
  loading,
  formData,
  unsavedRows,
  validationSchema,
}) => {
  const { opkID, procedureID } = useParams();
  const { isOpen, modalContent, openModal, closeModal } = useModal();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFormData(procedureID, opkID));
  }, [dispatch, procedureID, opkID, getFormData]);

  const saveData = async (submitedFormData) => {
    dispatch(saveFormData(procedureID, opkID, submitedFormData)).then(
      (response) => {
        if (response) {
          openModal({
            title: response?.title,
            message: response?.message,
          });
        }
      },
    );
  };

  return (
    <>
      {loading ? (
        <Loading variant="danger">
          <span className="m-2">Wczytywanie danych. Proszę czekać...</span>
        </Loading>
      ) : (
        <GeneratedForm
          dataSubmit={saveData}
          formFields={formFields}
          opkData={formData}
          unsavedRows={unsavedRows}
          validationSchema={validationSchema}
        />
      )}
      <CustomModal
        show={isOpen}
        onHide={closeModal}
        modalContent={modalContent}
      />
    </>
  );
};

export default TabForm;
