import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { logoutSwichedUser } from "../../actions/switchUserActions";
import { Loading } from "../../components/Loading/loading";

const Logout = () => {
  const dispatch = useDispatch();
  const isSwitchedUser = useSelector(
    (state) => state.switchUserReducer.isSwitchedUser,
  );
  const switchedUser = useSelector(
    (state) => state.switchUserReducer.switchedUser,
  );

  useEffect(() => {
    const redirectTimer = setTimeout(() => {
      dispatch(logoutSwichedUser());
    }, 3000);
    return () => {
      clearTimeout(redirectTimer);
    };
  }, [dispatch]);

  if (isSwitchedUser) {
    return (
      <>
        <Loading variant="info">
          <span className="mx-auto my-2">
            <p>Konczysz pracę jako {switchedUser.username}.</p>
            <p>Za chwilę zostniesz przekierowany(a)</p>
            <p>na stronę główną...</p>
          </span>
        </Loading>
      </>
    );
  } else {
    return <Navigate to="/" />;
  }
};

export default Logout;
