import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Navigate } from "react-router-dom";

import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Row, Spinner } from "react-bootstrap";

import { getUserInfo } from "../../../actions/OpkUserProfileActions";
import {
  addOpks,
  confirmOpk,
  getExcelOpkData,
  getOpks,
} from "../../../actions/StaffUserViewsActions/opkAcceptanceActions";
import GeneratedForm from "../../../components/GeneratedForm/GeneratedForm";
import { Loading } from "../../../components/Loading/loading";
import { SmalModal } from "../../../components/Modal/SmalModal";
import CustomModal from "../../../components/Modal/CustomModal";
import { OpkAkceptacjaFormFields } from "../OpkForms/FormSettings";
import { opkValidationSchema } from "../../../utils/validationSchemas";
import useModal from "../../../hooks/useModal";

const OpkAkceptacja = () => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const { procedureID, userID } = useParams();
  const { isOpen, modalContent, openModal, closeModal } = useModal();
  const dispatch = useDispatch();

  const dataOPK = useSelector((state) => state.opkAcceptance.opks);
  const loading = useSelector((state) => state.opkAcceptance.loading);
  const opk_accepted = useSelector((state) => state.opkAcceptance.opk_accepted);
  const user_info = useSelector((state) => state.userInfoReducer.user_info);
  const user_info_loading = useSelector(
    (state) => state.userInfoReducer.loading,
  );

  const handleSideBarToggler = () => {
    setShowSidebar((prevState) => !prevState);
  };
  const handleModalToggler = () => setShowModal((prevState) => !prevState);
  const handleAccept = () => {
    dispatch(confirmOpk(procedureID, userID));
    setShowModal(false);
  };

  // GET_OPEKS
  useEffect(() => {
    dispatch(getOpks(procedureID, userID));
  }, [dispatch, userID, procedureID]);

  useEffect(() => {
    dispatch(getUserInfo(userID));
  }, [dispatch, userID]);

  // ADD_OPEKS
  const saveData = (submitedFormData) => {
    dispatch(addOpks(procedureID, userID, submitedFormData)).then(
      (response) => {
        if (response) {
          openModal({
            title: response?.title,
            message: response?.message,
          });
        }
      },
    );
  };

  if (opk_accepted) return <Navigate to="/fk/lista_do_weryfikacji" />;

  const downloadFile = () => {
    dispatch(getExcelOpkData(procedureID, userID));
  };

  return (
    <>
      <div className="fixedPlugin" tabIndex="0" onClick={handleSideBarToggler}>
        <FontAwesomeIcon icon={faCog} size="2x" />
      </div>

      <Row>
        {showSidebar && (
          <Col className="p-0" xl={1} xs={2}>
            <div className="dropdownMenu">
              <Button
                onClick={handleModalToggler}
                type="button"
                variant="outline-success"
                className="w-100"
              >
                Zatwierdź zmiany
              </Button>

              <Button
                onClick={downloadFile}
                type="button"
                variant="outline-success"
                className="mt-2 w-100"
              >
                Pobierz plik
              </Button>

              <div className="switchUserMenu swd-info mt-3">
                {!user_info.profile && user_info_loading ? (
                  <Spinner
                    animation="border"
                    aria-hidden="true"
                    role="status"
                    variant="info"
                  />
                ) : (
                  user_info.profile &&
                  user_info.profile[0] && (
                    <>
                      <span>Nazwa: </span>
                      <p>{user_info.profile[0].swd_nazwa}</p>
                      <span>Regon: </span>
                      <p>{user_info.username}</p>
                    </>
                  )
                )}
              </div>
            </div>
          </Col>
        )}
        <Col>
          <div className="head-container">
            <h2>Dane finansowo-księgowe</h2>
            <p>
              Żeby zaakceptować świadczeniodawcę, te same OPK powinny być
              zaznaczone/odznaczone przez DA/DPD jako zaakceptowane
            </p>
          </div>
          <main className="main-container">
            {loading ? (
              <Loading variant={"danger"}>
                <span className="m-2">
                  Wczytywanie danych. Proszę czekać...
                </span>
              </Loading>
            ) : (
              <GeneratedForm
                dataSubmit={saveData}
                formFields={OpkAkceptacjaFormFields}
                opkData={dataOPK}
                validationSchema={opkValidationSchema}
              />
            )}
          </main>
        </Col>
      </Row>
      <SmalModal
        handleAccept={handleAccept}
        handleClose={handleModalToggler}
        modalBodyText={
          <div>
            {dataOPK && dataOPK.some((opk) => opk.bledy) && (
              <div className="text-center">
                <h4>Uwaga!</h4>
                <span>Niektóre OPK zawierają błędy.</span>
              </div>
            )}

            <p className="text-center">Czy na pewno chcesz zatwierdzić dane?</p>
          </div>
        }
        show={showModal}
      />
      <CustomModal
        show={isOpen}
        onHide={closeModal}
        modalContent={modalContent}
      />
    </>
  );
};

export default OpkAkceptacja;
