import { GET_ERRORS, GET_SUCCESS } from "actions/types.js";
import { apiClient } from "api/api";

const handleParticipationRequest = async (
  endpoint,
  procedureID,
  userID,
  want_mail,
  dispatch,
) => {
  try {
    const response = await apiClient.post(
      `/restore-for-improvement/${procedureID}/${endpoint}/`,
      { userID, want_mail },
    );

    dispatch({
      type: GET_SUCCESS,
      payload: { msg: response.data.detail, status: response.status },
    });
  } catch (error) {
    const errors = {
      msg: error.response?.data || "Wystąpił błąd. Proszę spróbować ponownie.",
      status: error.response?.status || 500,
    };

    dispatch({
      type: GET_ERRORS,
      payload: errors,
    });

    console.error("API Error:", errors);
  }
};

export const acceptParticipation =
  (procedureID, userID, want_mail) => async (dispatch) =>
    handleParticipationRequest(
      "verification_akcept",
      procedureID,
      userID,
      want_mail,
      dispatch,
    );

export const rejectParticipation =
  (procedureID, userID, want_mail) => async (dispatch) =>
    handleParticipationRequest(
      "verification_reject",
      procedureID,
      userID,
      want_mail,
      dispatch,
    );

export const restoreParticipation =
  (procedureID, userID, want_mail) => async (dispatch) =>
    handleParticipationRequest(
      "verification_back",
      procedureID,
      userID,
      want_mail,
      dispatch,
    );
