import { apiClient } from "api/api";
import {
  ADD_OPKS_NEW_LIST,
  CONFIRM_OPKS_SWD,
  GET_ERRORS,
  GET_OPKS_NEW_LIST,
  GET_SUCCESS,
  OPKS_NEW_LIST_SET_LOADING,
  OPKS_NEW_LIST_LOADING_OFF,
} from "./types";
import { SUCCESS_INFO } from "utils/toastMessages";

export const getOpksNewList = (procedureID) => async (dispatch) => {
  try {
    dispatch({ type: OPKS_NEW_LIST_SET_LOADING });

    const response = await apiClient.get(`/fk/${procedureID}/opk_swd`, {
      useSwitchUserToken: true,
    });

    dispatch({
      payload: response.data,
      type: GET_OPKS_NEW_LIST,
    });
  } catch (err) {
    const errors = {
      msg: err.response.data,
      status: err.response.status,
    };
    dispatch({
      payload: errors,
      type: GET_ERRORS,
    });
  }
};

export const addOpksNewList =
  (procedureID, submitedFormData) => async (dispatch) => {
    try {
      dispatch({ type: OPKS_NEW_LIST_SET_LOADING });
      const response = await apiClient.post(
        `/fk/${procedureID}/opk_swd`,
        submitedFormData,
        { useSwitchUserToken: true },
      );

      dispatch({
        payload: {
          msg: SUCCESS_INFO,
          status: response.status,
        },
        type: GET_SUCCESS,
      });

      dispatch({
        payload: response.data,
        type: ADD_OPKS_NEW_LIST,
      });
      return response;
    } catch (err) {
      dispatch({ type: OPKS_NEW_LIST_LOADING_OFF });
      const errorData = err.response?.data || {
        error: "Nieznany błąd.",
        duplicates: [],
      };

      const errorMsg =
        Array.isArray(errorData.duplicates) && errorData.duplicates.length > 0
          ? `${errorData.error} Duplikaty: ${errorData.duplicates.join(", ")}`
          : errorData.error || Object.values(errorData.errors).join(" ") || "";

      const errors = {
        msg: errorMsg,
        status: err.response.status,
      };
      dispatch({
        payload: errors,
        type: GET_ERRORS,
      });
    }
  };

export const confirmOpkSwd = (procedureID) => async (dispatch) => {
  try {
    dispatch({ type: OPKS_NEW_LIST_SET_LOADING });
    const response = await apiClient.get(
      `/fk/${procedureID}/confirm_opks_swd`,
      { useSwitchUserToken: true },
    );
    dispatch({
      payload: response.status,
      type: CONFIRM_OPKS_SWD,
    });

    dispatch({
      payload: {
        msg: "Numery kont OPK zostały przesłane do AOTMiT",
        status: response.status,
      },
      type: GET_SUCCESS,
    });
  } catch (err) {
    const errors = {
      msg: err.response.data,
      status: err.response.status,
    };
    dispatch({
      payload: errors,
      type: GET_ERRORS,
    });
  }
};
