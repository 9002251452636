import { apiClient } from "api/api";
import {
  GET_ERRORS,
  GET_SWITCHED_USER_INFO,
  SWITCHED_USER_INFO_SET_LOADING,
} from "../types";

export const getSwitchedUserInfo = (procedureID) => async (dispatch) => {
  try {
    dispatch({ type: SWITCHED_USER_INFO_SET_LOADING });

    const response = await apiClient.get(
      `/fk/switched_user_info/${procedureID}`,
      { useSwitchUserToken: true },
    );

    dispatch({
      payload: response.data,
      type: GET_SWITCHED_USER_INFO,
    });
  } catch (err) {
    const errors = {
      msg: err.response.data,
      status: err.response.status,
    };
    dispatch({
      payload: errors,
      type: GET_ERRORS,
    });
  }
};
