import { Button, Card, Container, Form, Spinner } from "react-bootstrap";

import BreadcrumbContainer from "components/Breadcrumbs/BreadcrumbContainer";
import logo from "../../assets/img/aotm.png";

const RegistrationBox = ({ formik, loading, title, children }) => {
  return (
    <>
      <div className="full-container">
        <BreadcrumbContainer />
      </div>
      <h4 className="text-center fw-bold mt-5">Dane kosztowe wersja {process.env.REACT_APP_VERSION}</h4>
      {loading && (
        <div
          className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 1050 }}
        >
          <Spinner
            animation="border"
            aria-hidden="true"
            role="status"
            variant="info"
            aria-live="assertive"
          />
        </div>
      )}
      <div className="flex-center">
        <Form onSubmit={formik.handleSubmit}>
          <Card className="login-card">
            <Card.Body className="p-2">
              <Container>
                <img alt="AOTMiT logo" className="login-img" src={logo} />
                <div className="mt-5 text-center">{title}</div>
                {children}
              </Container>
            </Card.Body>
            <Card.Footer>
              <Button className="w-100" type="submit" disabled={loading}>
                Wyślij
              </Button>
            </Card.Footer>
          </Card>
        </Form>
      </div>
    </>
  );
};

export default RegistrationBox;
