import { apiClient } from "api/api";

import {
  GET_ERRORS,
  GET_SUCCESS,
  UPLOAD_XLSX,
  UPLOAD_XLSX_FAIL,
  UPLOAD_XLSX_SET_LOADING,
} from "../types.js";
import { SUCCESS_INFO } from "utils/toastMessages.js";

export const uploadXlsx = (procedureID, xlsxFile) => async (dispatch) => {
  dispatch({ type: UPLOAD_XLSX_SET_LOADING });
  try {
    const response = await apiClient.post(
      `/fk/${procedureID}/upload_excel`,
      xlsxFile,
      { useSwitchUserToken: true },
    );
    dispatch({
      payload: {
        msg: SUCCESS_INFO,
        status: response.status,
      },
      type: GET_SUCCESS,
    });

    dispatch({
      payload: response.data,
      type: UPLOAD_XLSX,
    });
  } catch (err) {
    dispatch({ type: UPLOAD_XLSX_FAIL });

    const errors = {
      msg: "Błąd wgrywania danych - skontaktuj sie z AOTMiT opd adresem dane-kosztowe@aotm.gov.pl",
      status: err.response?.status || 500,
    };

    dispatch({
      payload: errors,
      type: GET_ERRORS,
    });
    console.log(err?.response);
    if (err.response) {
      if (err.response.data?.errors?.errors && err.response.data?.message) {
        return {
          title: err.response.data.message,
          message: err.response.data.errors.errors,
        };
      } else if (err.response.data?.error) {
        return {
          title: "Dane nie zostały poprawnie zapisane.",
          message: err.response.data.error,
        };
      }
    }
  }
};
